import Drawer from '@mui/material/Drawer';
import { appSyringe } from 'app/syringe';
import { juxVoid } from 'fnd/juxtapose';
import { dump } from 'js-yaml';
import { SussProvider, useSuss } from 'packs/libs/Suss';
import { apiReq } from 'packs/libs/api';
import { memo } from 'react';

export const openAuditHistory = (stack: string[]) => {
  const request = (query, context) => {
    return apiReq({
      action: 'audit.search',
      body: { ...query, ...context, stack },
    });
  };
  appSyringe(function AuditHistory({ onClose }) {
    return (
      <Drawer anchor="right" open={true} onClose={onClose}>
        <SussProvider request={request}>
          <List />
        </SussProvider>
      </Drawer>
    );
  });
};

const List = (): JsxElement => {
  const [{ data }] = useSuss();

  return (
    <div className="w300s std-paper">
      {data.size
        ? data.map((item) => {
            return <Card key={item._id} data={item} />;
          })
        : 'no data'}
    </div>
  );
};

type CardProps = {
  data;
};

const Card = memo(function Card({ data }: CardProps): JsxElement {
  return <pre>{dump(data)}</pre>;
}, juxVoid);
