import { infError } from 'fnd/inf/Inf';
import { StdAutoTextArea, StdButtonSwitch, StdKindButton } from 'packs/std';
import { useEffect } from 'react';
import { useSwissState } from 'support/react/swiss';

type Value = { enabled: boolean; bits: string[] };
type CensorProps = {
  value: Value;
  onChange(value: Value);
};

const SEP = '\n';
const listToSheet = (list: string[]) => list.join(SEP);

type State = CensorProps['value'] & { sheet: string };

export const Censor = ({ value, onChange }: CensorProps): JSX.Element => {
  const [{ sheet, enabled }, op] = useSwissState<State>(() => {
    const list = value.bits;
    const sheet = listToSheet(list);
    return { bits: list, sheet, enabled: value.enabled };
  });

  useEffect(() => {
    op.updState({ bits: value.bits, sheet: listToSheet(value.bits) });
  }, [value.bits]);

  useEffect(() => {
    if (value.enabled !== enabled) op.updState({ enabled: value.enabled });
  }, [value.enabled]);

  const setSheet = (sheet: string) => {
    op.updState({ sheet, bits: sheet.split(SEP).filter((p) => !/^\s*$/g.test(p)) });
  };

  return (
    <div className="w-max-200s">
      <div className="tg-body p1sa tg-caption">
        strings or{' '}
        <a href="https://regex101.com/r/kj1Qit/3" target="_blank" className="underline">
          regular expressions
        </a>{' '}
        allowed
      </div>
      <div className="p2sy flex">
        <StdButtonSwitch
          size="xs"
          truly={'enabled'}
          falsy={'disabled'}
          value={enabled}
          onChange={(enabled) => {
            onChange({ enabled, bits: value.bits });
          }}
        />
      </div>
      <div className="relative flex flex-col gap-2s">
        <div>
          <StdAutoTextArea
            className="border border-black border-solid"
            value={sheet}
            onChange={(e) => {
              setSheet(e.target.value);
            }}
          />
        </div>

        <div className="sticky bottom-0 std-paper">
          <StdKindButton
            children={'submit'}
            onClick={async () => {
              const { bits: list, enabled } = op.getState();

              const validate = () => {
                try {
                  for (const bit of list) {
                    new RegExp(bit);
                  }
                } catch (e) {
                  infError(
                    <div>
                      <div>Some line is not valid as a regular expression:</div>
                      <div>{e.message}</div>
                    </div>
                  );
                  throw e;
                }
              };

              validate();
              onChange({ bits: list, enabled });
            }}
          />
          <StdKindButton
            className="m2sl"
            kind={'ah'}
            children={'sort'}
            onClick={() => {
              setSheet(
                sheet
                  .split(SEP)
                  .filter((p) => !/^\s*$/g.test(p))
                  .sort()
                  .join(SEP)
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
