import { QuestChapterPeriod } from 'domain/quest/quest.defs';
import { useMemo, useState } from 'react';
import { StdInput, StdKindButton } from 'packs/std';
import { apiReq } from 'packs/libs/api';
import { css } from '@emotion/css';

type Item = {
  code: string
  period: QuestChapterPeriod
  appeared: number
  completed: number
}

export function QuestDashOneDump() {
  const [data, setData] = useState<undefined | Item[]>();
  const [gt, setGt] = useState<string>();
  const [lt, setLt] = useState<string>();

  const table = useMemo(() => {
    if (data === undefined) return null;

    const types = new Map([
      [QuestChapterPeriod.day, 'daily'],
      [QuestChapterPeriod.week, 'weekly'],
      [QuestChapterPeriod.month, 'monthly'],
    ]);

    return (
      <table className={tableClass}>
        <thead>
        <th>code</th>
        <th>type</th>
        <th>appeared</th>
        <th>completed</th>
        </thead>
        <tbody>
        {data.map(({
          code, period, appeared, completed,
        }) => {
          const key = `${code}-${period}`;
          return (
            <tr key={key}>
              <td>{code}</td>
              <td>{types.get(period)}</td>
              <td>{appeared}</td>
              <td>{completed}</td>
            </tr>
          );
        })}
        </tbody>
      </table>
    );
  }, [data]);

  const filled = gt !== undefined && lt !== undefined;

  return (
    <div className="std-paper w190s p4sa flex flex-col gap-y-2s">
      <div className="tg-headline">
        Stats #1
      </div>
      <div className="flex">
        <a href="https://www.npmjs.com/package/parse-duration" target="_blank">duration syntax</a>
      </div>
      <div className="flex gap-2s">
        <StdInput
          value={gt}
          type={'datetime-local'}
          placeholder={'from date'}
          onChange={e => {
            setGt(e.target.value);
          }}
        />
        <StdInput
          value={lt}
          type={'datetime-local'}
          placeholder={'to date'}
          onChange={e => {
            setLt(e.target.value);
          }}
        />
        <StdKindButton
          children={'Search'}
          disabled={!filled}
          onClick={async () => {
            setData(
              await apiReq({
                action: 'quest.stats.one-dump',
                                body: { lt, gt },
              }),
            );
          }}
        />
      </div>
      {table}
    </div>
  );
}

const tableClass = css`
  td {
    text-align: center;
    vertical-align: middle;
    padding: 4px;
  }
`;
