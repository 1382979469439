import { useState } from 'react';
import { BlankDynInput } from 'packs/single/blank-dyn-input';
import { css, cx } from '@emotion/css';

type EmployeeRoleListItemProps = {
  role,
  update(id: string, role: any): void
  active: boolean
  setActive(id: string): void
}

export function EmployeeRoleListItem({
  role: {
    id, name,
  },
  update,
  active,
  setActive,
}: EmployeeRoleListItemProps) {
  const [edit, setEdit] = useState(false);

  return (
    <div
      className={cx(mainClass, active && activeClass)}
      onDoubleClick={() => {
        setEdit(true);
      }}
      onClick={() => {
        setActive(id);
      }}
    >
      <div className="tg-subheader">
        {edit ? (
          <BlankDynInput
            value={name}
            onCancel={() => {
              setEdit(false);
            }}
            onUpdate={(name) => {
              update(id, { name });
              setEdit(false);
            }}
          />
        ) : (
          <span>
            {name}
          </span>
        )}
      </div>
    </div>
  );
}

const mainClass = css`
  padding: 12px;
  cursor: pointer;
`;

const activeClass = css`
  background: var(--pale-blue-300)
`;
