import { memoize } from 'lodash';

const hierarchy = new Map<any, string>([
  ['read', 'write'],
  ['write', 'admin'],
]);

export function makeAclChecker(acl: string[] | Set<string>) {
  const set = new Set(acl);
  const check = memoize(function doCheck(access: string): boolean {
    if (set.has(access)) return true;

    const parts = access.split('.');
    const acs = parts.pop();
    const next = hierarchy.get(acs);
    if (next && check([...parts, next].join('.'))) return true;

    if (parts.pop() === undefined) return false;

    return check([...parts, acs].join('.'));
  });
  return check;
}
