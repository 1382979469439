import { css } from '@emotion/css';
import { appSyringe } from 'app/syringe';
import { PlayerRole } from 'domain/player/definitions';
import { PlayerImage } from 'domain/player/image';
import { enumOp } from 'fnd/support/enum-operator';
import { apiReq } from 'packs/libs/api';
import { SassyDialog } from 'packs/single/sassy-dialog';
import { StdTime } from 'packs/std/time/format';

type PlayerData = {
  id: string;
  name: string;
  image: string;
  role: string;
};

export type PlayerSearchQuery = {
  roles?: PlayerRole[];
};

type Opts = {
  submit(player: PlayerData): void;
  query?: PlayerSearchQuery;
};

export const openPlayerSearchDialog = (props: Opts) => {
  appSyringe(function PlayerSearchDialog({ onClose }) {
    return (
      <SassyDialog
        placeholder="Player's id or name"
        request={(query, context) => {
          return apiReq({
            action: 'player.search',
            body: { ...props.query, ...query, ...context },
          });
        }}
        // request={(query, context) =>
        //   apiReq({ action: 'player.search', query: { ...query, ...context },  })
        // }
        onClose={onClose}
      >
        {(results) => (
          <div className={listClass}>
            {results.map((data) => (
              <button
                key={data.id}
                className={cardClass}
                tabIndex={1}
                onClick={() => {
                  props.submit(data);
                  onClose();
                }}
              >
                <div className={avatarBoxClass}>
                  <PlayerImage className="h20s" code={data.image} />
                </div>
                <div>
                  <div className="tg-caption1">{data.id}</div>
                  <div className="tg-label1">{data.name}</div>
                  <div>{enumOp(PlayerRole).valKeyMap.get(data.role)}</div>
                  <div className="tg-caption2">
                    last login:{' '}
                    <span className="tg-overline">{StdTime.ms.datetime(data.lastLoginAt)}</span>
                  </div>
                </div>
              </button>
            ))}
          </div>
        )}
      </SassyDialog>
    );
  });
};

const cardClass = css`
  text-align: start;
  width: 360px;
  display: flex;
  gap: 16px;
  border-radius: 3px;
  &:hover {
    background: var(--grey-800);
  }
`;

const listClass = css`
  display: grid;
  grid-template-columns: repeat(2, 360px);
  gap: 16px;
  overflow: auto;
  max-height: 70vh;
`;

const avatarBoxClass = css`
  border-radius: 6px;
`;
