import { useSwissState } from 'packs/state/swiss-store';
import { css } from '@emotion/css';
import { StdKindButton, StdInput } from 'packs/std';
import { AuthTfaQRCode } from 'app/public/auth/auth.tfa.qr-code';

type AuthStepTfaProps = {
  email: string
  secret?: string
  submit(code: string): void
}

export function AuthStepTfa(props: AuthStepTfaProps) {
  const { secret, email, submit } = props;
  const [value, op] = useSwissState<string>('');

  return (
    <div className={mainClass}>
      <div className="std-paper p4sa w100s flex flex-col gap-4s">
        <div className="tg-headline">
          Two Factor Code
        </div>
        <StdInput
          autoFocus
          value={value}
          onChange={e => op.setState(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter')
              submit(value);
          }}
        />
        <div className={'flex justify-center'}>
          <StdKindButton
            children={'submit'}
            onClick={() => submit(value)}
          />
        </div>
      </div>

      {secret && <AuthTfaQRCode secret={secret} email={email}/>}
    </div>
  );
}

const mainClass = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;
