import { QuartzPagination } from 'packs/blocks/quartz/QuartzPagination';
import { QuartzPageSizer } from 'packs/blocks/quartz/units/page-sizer';
import { useSuss } from 'packs/libs/Suss';
import styled from 'styled-components';

type QuartzFooterProps = {
  className?: string;
};

export function QuartzFooter(props: QuartzFooterProps) {
  const [{ data, page, size, meta, hasMore }, op] = useSuss();

  return (
    <div {...props}>
      <MainDiv className={'std-paper'}>
        <QuartzPageSizer value={size} onChange={op.setSize} />
        <div className={'flex justify-center items-center'}>
          <PlainButton disabled={!hasMore} onClick={op.loadMore}>
            load more
          </PlainButton>
        </div>
        <QuartzPagination
          size={size}
          count={data.size}
          page={page}
          total={meta.total}
          onChange={op.turn}
        />
      </MainDiv>
    </div>
  );
}

const MainDiv = styled.div`
  background: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 3px;
  padding: 12px 24px;
`;

const PlainButton = styled.button`
  border: 0;
  outline: none;
  background: transparent;
  font-size: 14px;
  color: var(--text-20);
  cursor: pointer;
  font-weight: 500;

  :disabled {
    cursor: inherit;
    color: var(--text-40);
    font-weight: 400;
  }

  :hover & :not(:disabled) {
    text-decoration: underline;
  }
`;
