import {useMemo} from 'react';
import {dump} from 'js-yaml';
import {cc} from 'fnd/bridge/react';
import { css } from '@emotion/css';

type DumpProps = {
  data: any
  className?: string
}

export function Dump({
  data, className,
}: DumpProps) {
  return useMemo(() => {
    function format() {
      const type = typeof data;
      if (type === 'object' && data !== null)
        return dump(JSON.parse(JSON.stringify(data)));

      return String(data);
    }

    return (
      <pre className={cc(preCss, className)}>
        {format()}
      </pre>
    );
  }, [data]);
}

const preCss = css`
  word-break: break-all;
`;

