import { useEffect } from 'react';
import { css } from '@emotion/css';
import { useSwissState } from 'packs/state/swiss-store';
import { DateTime } from 'luxon';
import { apiReq } from 'packs/libs/api';

type State = {
  from: string;
  to: string;
  data?: any;
};

export function QuestsDashEarnedSum() {
  const [{ from, to, data }, { updState }] = useSwissState<State>(() => ({
    from: DateTime.now().minus({ days: 29 }).toISODate(),
    to: DateTime.now().toISODate(),
  }));

  useEffect(() => {
    apiReq({
      action: 'quest.stats.earned',
      body: { from, to },
    }).then((data) => updState({ data }));
  }, [from, to]);

  const composeDateInput = ({ label, value, onChange }) => {
    return (
      <div className="flex justify-end gap-1s">
        <div>{label}</div>
        <div>
          <input
            type={'date'}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="std-paper p4sa inline-flex flex-col gap-4s">
      <div className="tg-subheader">Players earnings</div>
      <div className="flex flex-col gap-1s">
        {composeDateInput({
          label: 'from',
          value: from,
          onChange: (val) => {
            updState({ from: val });
          },
        })}
        {composeDateInput({
          label: 'to',
          value: to,
          onChange: (val) => {
            updState({ to: val });
          },
        })}
      </div>
      <div className="flex flex-col gap-2s">
        {data &&
          data.map(([label, sum]) => {
            return (
              <div className={rowClass}>
                <div className="flex justify-end">{label}</div>
                <div>${sum}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

const rowClass = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  font-size: 16px;
  font-weight: 500;
`;
