import React, { useEffect, useState } from 'react';
import { LinkProps, NavLink } from 'react-router-dom';
import { chronicle } from 'fnd/bridge/router';
import { el } from 'fnd/bridge/react';
import { StdTab, StdTabType } from 'packs/std/navigation/StdTab';
import { matchPath } from 'react-router';
import { createLocation } from 'history';

type StdNavTabProps = LinkProps & {
  type: StdTabType;
};

export function StdNavTab({ to, type, children, ...props }: StdNavTabProps) {
  return (
    <NavLink to={to} {...props}>
      <StdTab type={type} active={usePathMatch(to)}>
        {children}
      </StdTab>
    </NavLink>
  );
}

type StdTabNavSwitchProps = {
  url?: string;
  tabs: [string, React.ReactNode][];
  type?: StdTabType;
  replace?: boolean;
  className?: string;
};

export function StdNavTabSwitch({ url, tabs, type, replace, className }: StdTabNavSwitchProps) {
  url ??= '';
  return (
    <div className={className}>
      {tabs.map(([key, node], i) => {
        return el(
          StdNavTab,
          {
            replace,
            key: key ?? i,
            type,
            to: `${url}${key}`,
          },
          node
        );
      })}
    </div>
  );
}

const doMatch = (to: string) => {
  const currentLocation = chronicle.location;
  const toLocation = normalizeToLocation(resolveToLocation(to, currentLocation), currentLocation);
  const { pathname: path } = toLocation;
  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

  const match = escapedPath
    ? matchPath(currentLocation.pathname, {
        path: escapedPath,
        // exact,
        // sensitive,
        // strict
      })
    : null;

  return !!match;
};

function usePathMatch(to: any) {
  const [state, setState] = useState<boolean>(() => doMatch(to));
  useEffect(() => {
    return chronicle.listen(() => {
      setState(doMatch(to));
    });
  }, []);
  return state;
}

export const resolveToLocation = (to, currentLocation) =>
  typeof to === 'function' ? to(currentLocation) : to;

export const normalizeToLocation = (to, currentLocation) => {
  return typeof to === 'string' ? createLocation(to, null, null, currentLocation) : to;
};
