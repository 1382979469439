import { css, cx } from '@emotion/css';
import { StdButton } from 'packs/std/inputs/std-button';
import { ButtonHTMLAttributes, createElement } from 'react';

export type StdKindButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  kind?: keyof typeof KINDS; // default 'a'
  size?: keyof typeof SIZES; // default 'md'
  type?: 'submit';
  active?: boolean;
};

export const StdKindButton = ({ className, kind, size, active, ...props }: StdKindButtonProps) =>
  createElement(StdButton, {
    ...props,
    className: cx(mainClass, KIND_MAP.get(kind), SIZE_MAP.get(size), active && 'active', className),
  });

const mainClass = css`
  border-style: solid;
  background: transparent;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  white-space: nowrap;
`;

const SIZES = {
  xs: css`
    border-width: 1px;
    border-radius: 3px;
    padding: 2px 4px;
    font-size: 12px;
  `,
  sm: css`
    border-width: 2px;
    border-radius: 3px;
    padding: 2px 4px;
    font-size: 14px;
    font-weight: 500;
  `,
  md: css`
    border-width: 2px;
    border-radius: 3px;
    padding: 2px 6px;
    font-size: 16px;
  `,
  lg: css`
    border-width: 2px;
    border-radius: 3px;
    padding: 4px 10px;
    font-size: 18px;
  `,
};

const KINDS = {
  a: css`
    border-color: var(--dim-blue-300);
    background-color: var(--dim-blue-300);
    color: var(--text-white-10);

    &:hover {
      border-color: var(--dim-blue-400);
      background-color: var(--dim-blue-400);
    }

    &:active,
    &.active {
      border-color: var(--dim-blue-500);
      background-color: var(--dim-blue-500);
    }

    &:disabled {
      color: var(--text-white-20);
      border-color: var(--dim-blue-gray-300);
      background-color: var(--dim-blue-gray-300);
    }
  `,
  ah: css`
    border-color: var(--dim-blue-300);
    color: var(--dim-blue-300);

    &:hover {
      border-color: var(--dim-blue-400);
      color: var(--dim-blue-400);
    }

    &:active,
    &.active {
      border-color: var(--dim-blue-500);
      color: var(--dim-blue-500);
    }

    &:disabled {
      color: var(--dim-blue-gray-300);
      border-color: var(--dim-blue-gray-300);
    }
  `,
  eh: css`
    border-color: var(--red-700);
    color: var(--red-700);

    &:hover {
      border-color: var(--red-800);
      color: var(--red-800);
    }

    &:active,
    &.active {
      border-color: var(--red-900);
      color: var(--red-900);
    }

    &:disabled {
      color: var(--dim-blue-gray-300);
      border-color: var(--dim-blue-gray-300);
    }
  `,
  'danger-h': css`
    border-color: var(--red-700);
    color: var(--red-700);

    &:hover {
      border-color: var(--red-800);
      color: var(--red-800);
    }

    &:active,
    &.active {
      border-color: var(--red-900);
      color: var(--red-900);
    }

    &:disabled {
      color: var(--dim-blue-gray-300);
      border-color: var(--dim-blue-gray-300);
    }
  `,
  e: css`
    border-color: var(--red-700);
    background-color: var(--red-700);
    color: white;

    &:hover {
      border-color: var(--red-800);
      background-color: var(--red-800);
    }

    &:active,
    &.active {
      border-color: var(--red-900);
      background-color: var(--red-900);
    }

    &:disabled {
      background-color: var(--dim-blue-gray-300);
      border-color: var(--dim-blue-gray-300);
    }
  `,
  danger: css`
    border-color: var(--red-700);
    background-color: var(--red-700);
    color: white;

    &:hover {
      border-color: var(--red-800);
      background-color: var(--red-800);
    }

    &:active,
    &.active {
      border-color: var(--red-900);
      background-color: var(--red-900);
    }

    &:disabled {
      background-color: var(--dim-blue-gray-300);
      border-color: var(--dim-blue-gray-300);
    }
  `,
};

const KIND_MAP = new Map(Object.entries(KINDS));
KIND_MAP.set(undefined, KIND_MAP.get('a'));

const SIZE_MAP = new Map(Object.entries(SIZES));
SIZE_MAP.set(undefined, SIZE_MAP.get('md'));
