import { css } from '@emotion/css';
import rangeMap from 'support/collection/rangeMap';

const SPACE = 4;

function compile() {
  function compilePaddingsMargins() {
    return rangeMap(0, 2, (a) => {
      const ax = a * 10;
      return rangeMap(0, 9, (b) => {
        const i = ax + b;
        const space = i * SPACE;
        return [
          ['p', 'padding'],
          ['m', 'margin'],
        ].flatMap(([prefix, clause]) => {
          return css`
            .${prefix}${i}sa {
              ${clause}: ${space}px;
            }

            .${prefix}${i}sy {
              ${clause}-top: ${space}px;
              ${clause}-bottom: ${space}px;
            }

            .${prefix}${i}sx {
              ${clause}-right: ${space}px;
              ${clause}-left: ${space}px;
            }

            .${prefix}${i}st {
              ${clause}-top: ${space}px;
            }

            .${prefix}${i}sr {
              ${clause}-right: ${space}px;
            }

            .${prefix}${i}sb {
              ${clause}-bottom: ${space}px;
            }

            .${prefix}${i}sl {
              ${clause}-left: ${space}px;
            }
          `;
        });
      });
    });
  }

  function compileWidthsHeights() {
    return rangeMap(0, 4, (x2) => {
      return rangeMap(0, 9, (x1) => {
        const base = x2 * 100 + x1 * 10;
        const distance = SPACE * base;

        const base0 = x2 * 10 + x1;
        const distance0 = SPACE * base0;

        return css`
          .h${base0}s {
            height: ${distance0}px;
          }

          .w${base0}s {
            width: ${distance0}px;
          }

          .h${base}s {
            height: ${distance}px;
          }

          .w${base}s {
            width: ${distance}px;
          }

          .h-min-${base0}s {
            min-height: ${distance0}px;
          }

          .w-min-${base0}s {
            min-width: ${distance0}px;
          }

          .h-min-${base}s {
            min-height: ${distance}px;
          }

          .w-min-${base}s {
            min-width: ${distance}px;
          }

          .h-max-${base0}s {
            max-height: ${distance0}px;
          }

          .w-max-${base0}s {
            max-width: ${distance0}px;
          }

          .h-max-${base}s {
            max-height: ${distance}px;
          }

          .w-max-${base}s {
            max-width: ${distance}px;
          }
        `;
      });
    });
  }

  return [compilePaddingsMargins(), compileWidthsHeights()];
}

export default compile();
