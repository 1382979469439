import './global.scss';

import { App } from 'app/app';
import { PublicApp } from 'app/public/PublicApp';
import { ActorProvider, useActor } from 'domain/actor';
import { chronicle } from 'fnd/bridge/router';
import { ProgressProvider } from 'packs/libs/Progress';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import StyledToastContainer from './app/public/layout/StyledToastContainer';
import Bsod from './packs/single/Bsod';
import StdProvider from './packs/std/StdProvider';
import reportWebVitals from './reportWebVitals';

export default function AuthJunction() {
  return useActor().anon ? <PublicApp /> : <App />;
}

ReactDOM.render(
  <Bsod>
    <StrictMode>
      <StdProvider>
        <ProgressProvider>
          <Router history={chronicle}>
            <StyledToastContainer />
            <ActorProvider>
              <AuthJunction />
            </ActorProvider>
          </Router>
        </ProgressProvider>
      </StdProvider>
    </StrictMode>
  </Bsod>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.group();
console.log(ENV_CODE);
console.log(ENV_SANDBOX);
console.log(ENV_API_URL);
console.groupEnd();

import 'packs/std/atom';
