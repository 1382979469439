import { adminAdditionalRoutes, adminRoutes } from 'app/AdminNavigation';
import { AdminHeader } from 'app/layout/header/AdminHeader';
import { useActor } from 'domain/actor';
import { cc } from 'fnd/bridge/react';
import { SimpleOutpost } from 'packs/single/simple-outpost';
import { StdPage } from 'packs/std';
import { StdJunction } from 'packs/std/navigation/std.junction';
import React, { ReactNode, createElement, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export type AdminNavigationConfig = Node[];

type Node = {
  title: string;
  access?: string | boolean;
  path: string;
  to?: string;
  plain?: boolean;
  component: React.ComponentType;
};

let setMenuClosed;
export const toggleMenu = () => setMenuClosed((closed) => !closed);

export default function AdminMenu() {
  const actor = useActor();
  const [closed, setClosed] = useState();
  setMenuClosed = setClosed;
  return useMemo(() => {
    const routes: [string, ReactNode][] = [];
    const blocks: ReactNode[] = [];

    const cannot = (access?: string | boolean) =>
      access !== undefined && (typeof access === 'string' ? !actor.can(access) : !access);

    for (const block of adminRoutes) {
      if (cannot(block.access)) continue;

      const { to, path, component, title, plain } = block;

      blocks.push(
        <StyledLink to={to ?? '/' + path} key={path}>
          {title}
        </StyledLink>
      );

      const page = <div className="p4sa">{createElement(component)}</div>;

      routes.push([to ?? path, <SimpleOutpost>{page}</SimpleOutpost>]);
    }

    for (const [path, Component] of adminAdditionalRoutes) {
      routes.push([
        path,
        <SimpleOutpost>
          <Component />
        </SimpleOutpost>,
      ]);
    }

    return (
      <MainDiv>
        <MenuDiv className={cc({ closed })}>
          <div className="flex items-center p2sy p6sx w-full">
            <span className="tg-caption1 p2sr">environment:</span>
            <span className="text-white">{ENV_CODE}</span>
          </div>
          {blocks}
        </MenuDiv>
        <BodyDiv>
          <AdminHeader />
          <ContentDiv>
            <StdJunction routes={routes} />
          </ContentDiv>
        </BodyDiv>
      </MainDiv>
    );
  }, [actor, closed]);
}

const MainDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BodyDiv = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentDiv = styled.div`
  flex: 1;
  overflow: auto;
`;

const MenuDiv = styled.div`
  background: var(--dark-blue-100);
  min-width: 200px;
  height: 100vh;

  &.closed {
    display: none;
  }

  position: sticky;
  top: 0;
`;

const BlockDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const BlockTitleDiv = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--text-white-40);
  padding: 10px 20px;
`;

const StyledLink = styled(NavLink)`
  display: block;
  padding: 10px 30px;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  width: 100%;
  color: var(--text-white-30);

  &:hover {
    color: var(--text-white-20);
    background: rgba(255, 255, 255, 0.15);
  }

  &.active {
    color: var(--text-white-10);
    background: rgba(255, 255, 255, 0.15);
  }
`;
