import { apiReq } from 'packs/libs/api';
import { useState } from 'react';
import { stashGet } from 'support/memory/stash';
import { useLaunch } from 'support/react/use-launch';

export class VendorsOp {
  constructor(private readonly raw_data: any[]) {}

  get(id: string) {
    return this.vendorMap.get(id);
  }

  @stashGet
  get vendorMap() {
    return new Map(this.data.map((item) => [item.name, item]));
  }

  @stashGet
  get data() {
    const result = [];
    for (const { name, methods } of Object.values(this.raw_data)) {
      result.push({
        name,
        methods: Object.values(methods).map((data: any) => {
          const item = { name: data.name, id: data.id };
          return item;
        }),
      });
    }
    return result;
  }

  get list() {
    return this.data;
  }
}

export const usePaymentVendors = () => {
  const [data, setData] = useState<VendorsOp>();

  useLaunch(async () => {
    const { data } = await apiReq({
      action: 'payment.vendor.list',
    });
    console.log(data);
    setData(new VendorsOp(data));
  }, []);

  return data;
};
