import {useField} from 'formik';
import {StdAutoTextArea, StdAutoTextAreaProps} from 'packs/std';
import React from 'react';
import {FmkError, FmkStretch} from './FmkStyling';

type AutoTextAreaProps = StdAutoTextAreaProps & {
  name: string
}
export const FmkAutoTextArea = ({
  name, ...props
}: AutoTextAreaProps) => {
  const [field, meta] = useField(name);

  const error = meta.touched && meta.error;

  return (
    <FmkStretch>
      <StdAutoTextArea
        {...props}
        {...field}
        name={name}
      />
      {error && (
        <FmkError>{error}</FmkError>
      )}
    </FmkStretch>
  );
};
