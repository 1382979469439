import { appSyringe } from 'app/syringe';
import { StdDialog, StdKindButton } from 'packs/std';
import { ReactNode } from 'react';

type FancyConfirmationOpts = {
  onOk();
  about?: ReactNode;
};

export function fancyConfirmation({ onOk, about }: FancyConfirmationOpts) {
  appSyringe(function FancyConfirmationDialog({ onClose }) {
    return (
      <StdDialog onClose={onClose} className="p8sa">
        <div className="p10sb">
          <div className="tg-title flex justify-center">Are You Sure About That?</div>
          {about && <div className="tg-subhead flex justify-center">{about}</div>}
        </div>
        <div className="flex justify-around">
          <StdKindButton size="lg" kind="ah" children="Nope" onClick={onClose} />
          <StdKindButton
            tabIndex={1}
            size="lg"
            autoFocus
            kind="danger"
            children="Yeah"
            onClick={() => {
              onClose();
              onOk();
            }}
          />
        </div>
      </StdDialog>
    );
  });
}
