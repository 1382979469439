import { PaymentRegionJunction } from 'app/payment/region/junction';
import { TransactionSearchPage } from 'app/payment/transaction/search-page';
import { StdTabJunction } from 'packs/std';

export const PaymentJunction = (): JSX.Element => {
  return (
    <StdTabJunction
      routes={[
        // ['transaction', <TransactionSearchPage />],
        ['region', <PaymentRegionJunction />],
      ]}
    />
  );
};
