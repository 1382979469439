import { WithdrawalStage } from 'domain/withdrawal';
import { enumOp } from 'fnd/support/enum-operator';
import { debounce } from 'lodash';
import { useSuss } from 'packs/libs/Suss';
import { useSingleton } from 'packs/state/use-singleton';
import { StdTagSelect } from 'packs/std/inputs/tag-select';
import { useMemo } from 'react';

export const WithdrawalSearchFilters = (): JSX.Element => {
  return (
    <div className="std-paper p2sa">
      <StageFilter />
    </div>
  );
};

const StageFilter = (): JSX.Element => {
  const [state, op] = useSuss();
  const value = state.query.stages;

  const onChange = useSingleton(() => {
    return debounce((values: string[]) => {
      op.query.updState({ stages: values });
    });
  });

  return useMemo(() => {
    return (
      <StdTagSelect
        label="Stages"
        value={value}
        onChange={onChange}
        options={enumOp(WithdrawalStage).values}
      />
    );
  }, [value]);
};
