import { format } from 'date-fns';
import { juxVoid } from 'fnd/juxtapose';
import { QuartzFooter } from 'packs/blocks/quartz';
import { Stable, StableSortHead } from 'packs/blocks/suss-table';
import { apiReq } from 'packs/libs/api';
import { SussProvider } from 'packs/libs/Suss';
import { CopyIcon } from 'packs/single/copy-icon';
import { StdLink } from 'packs/std';
import { memo } from 'react';

const request = (query, context) =>
  apiReq({ action: 'case.battle.history.search', body: { ...query, ...context } });

export const CaseBattleHistorySearchPage = (): JSX.Element => {
  return (
    <div className="flex flex-col w260s gap-4s">
      <SussProvider
        pk="_id"
        request={request}
        query={{
          sort: [['createdAt', 'desc']],
        }}
      >
        <Stable Row={BattleRow}>
          <th>Id</th>
          <StableSortHead name="createdAt">Created</StableSortHead>
          <StableSortHead name="startedAt">Resolved</StableSortHead>
          <th>Cost</th>
          <th>Cases</th>
          <th>Members</th>
          <th>Players</th>
          <th>Bots</th>
        </Stable>
        <QuartzFooter />
      </SussProvider>
    </div>
  );
};

enum ResolvedBattleStatus {
  success = 'success',
  cancelled = 'cancelled',
  failed = 'failed',
}

type ResolvedBattleData = {
  _id: string;
  status: ResolvedBattleStatus;
  createdAt: number;
  // resolvedAt: number;
  startedAt: number;
  cost: number;
  cases: any[];
  members: any[];
};

type BattleRowProps = {
  data: ResolvedBattleData;
};

const BattleRow = memo(function BattleRow({ data }: BattleRowProps): JSX.Element {
  const { startedAt, createdAt, cases, cost, members, _id: id } = data;
  const botsCount = members.filter((m) => m.player.bot).length;

  return (
    <tr>
      <td>
        <CopyIcon copy={id} />
        <StdLink className="tg-mono" to={`/cases/battles/${id}`}>
          {id}
        </StdLink>
      </td>
      <td>{format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss')}</td>
      <td>{startedAt && format(new Date(startedAt), 'HH:mm:ss')}</td>
      <td>{cost}</td>
      <td>{cases.length}</td>
      <td>{members.length}</td>
      <td>{members.length - botsCount}</td>
      <td>{botsCount}</td>
    </tr>
  );
}, juxVoid);
