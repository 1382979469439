export type PipeMiddleware<T, R> = (ctx: T, next: (ctx: T) => R) => R

const nil: any = function () {
};
export default function pipeStrict<T = unknown, R = unknown>(middlewares: PipeMiddleware<T, R>[]) {
  return middlewares.reduceRight((next, current) => {
    return function pipe(ctx: T) {
      return current(ctx, next);
    };
  }, nil);
}
