import { css } from '@emotion/css';
import { ReactNode } from 'react';

type StdPageProps = {
  title: ReactNode;
  children: ReactNode;
  className?: string;
};

export function StdPage({ title, children, className }: StdPageProps) {
  return (
    <div>
      <div className="tg-h3">{title}</div>
      <div className={mainClass}>
        <div className={className}>{children}</div>
      </div>
    </div>
  );
}

const mainClass = css`
  padding: 20px;
`;
