import React from 'react';
import styled from 'styled-components';
import { cc, el } from 'fnd/bridge/react';

export type StdTabType = 'a' | 'b' | 'c';

type StdTabSwitchProps<T extends string | number> = {
  value: T;
  onChange(value: T);
  tabs: [T, React.ReactNode][];
  type?: StdTabType;
  className?: string;
};

export function StdTabSwitch<T extends string | number>({
  className,
  value,
  onChange,
  tabs,
  type = 'a',
}: StdTabSwitchProps<T>) {
  return (
    <div className={cc(className, type)}>
      {tabs.map(([key, node], i) => {
        return el(
          StdTab,
          {
            key: key ?? i,
            type,
            active: key === value,
            onClick() {
              onChange(key);
            },
          },
          node
        );
      })}
    </div>
  );
}

export type StdTabProps = SpanProps & {
  active: boolean;
  type?: StdTabType;
};

export function StdTab({ active, className, type, children, ...props }: StdTabProps) {
  return (
    <Tab {...props} className={cc(className, type, { active })}>
      <div className="tab-content">{children}</div>
      <div className="tab-underline" />
    </Tab>
  );
}

const Tab = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-right: 30px;
  display: inline-flex;
  flex-direction: column;

  &.active {
    .tab-content {
      font-weight: 600;
    }

    .tab-underline {
      height: 2px;
      background: var(--dark-blue-500);
    }
  }

  &.b {
    .tab-content {
      font-size: 14px;
    }
  }
`;
