import findOneAndUpdate from 'fnd/support/findOneAndUpdate';
import { useSuss } from 'packs/libs/Suss';
import React, { useMemo } from 'react';

function undefinedIfEmptyArrayCrutchBecauseOfGateJoiLibrary(array: any[]): any[] | undefined {
  return array.length === 0 ? undefined : array;
}

type StableSortProps = {
  name: string;
  children: React.ReactNode;
};

export function StableSortHead({ name, children }: StableSortProps) {
  const [{ query }, op] = useSuss();

  return useMemo(() => {
    const [order, course] = get(name, query.sort);

    return (
      <th>
        <div className="flex gap-2s">
          {children}
          <OrderIcon
            order={order}
            course={course}
            onClick={() => {
              op.query.prop<[string, string][]>('sort').apply((columns = []) => {
                switch (order) {
                  case OrderState.notDefined:
                    return [...columns, [name, OrderState.ascending]];
                  case OrderState.ascending:
                    return findOneAndUpdate(
                      (item) => item[0] === name,
                      (item) => [item[0], OrderState.descending],
                      columns
                    );
                  case OrderState.descending:
                    // return columns.filter((item) => item[0] !== name)
                    return undefinedIfEmptyArrayCrutchBecauseOfGateJoiLibrary(
                      columns.filter((item) => item[0] !== name)
                    );
                }
              });
            }}
          />
        </div>
      </th>
    );
  }, [query.sort, children]);
}

const OrderState = {
  notDefined: undefined,
  ascending: 'asc',
  descending: 'desc',
};

function get(name: string, list) {
  let course = 1;
  for (const [column, order] of list ?? []) {
    if (column === name) return [order, course];
    ++course;
  }
  return [OrderState.notDefined, null];
}

const ARROWS = {
  [OrderState.notDefined]: '↑↓',
  [OrderState.ascending]: '↑',
  [OrderState.descending]: '↓',
};

type OrderIconProps = {
  order;
  course: number | null;
  onClick(): void;
};

function OrderIcon({ order, course, onClick }: OrderIconProps) {
  return (
    <div className={'cp'} onClick={onClick}>
      {course}
      {ARROWS[order]}
    </div>
  );
}
