import { css } from '@emotion/css';
import AdminMenu from 'app/layout/menu/AdminMenu';
import { AppSyringeInjector } from 'app/syringe';
import { voidMemo } from 'fnd/bridge/react';

import { ConsoleConfigProvider } from 'domain/etc/console-config';
import './global-shortcuts';

export const App = voidMemo(function AdminApp() {
  return (
    <div className={mainClass}>
      <ConsoleConfigProvider>
        <AppSyringeInjector />
        <AdminMenu />
      </ConsoleConfigProvider>
    </div>
  );
});

const mainClass = css`
  position: relative;
  width: 100vw;
  height: 100vh;
`;
