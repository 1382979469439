import { PaymentCountryRegionBoundBlock } from 'app/payment/country/region-bound';
import { PaymentMethodJunction } from 'app/payment/method/junction';
import { PaymentMethodSearchBlock } from 'app/payment/method/search-block';
import { PaymentRegionEditBlock } from 'app/payment/region/form';
import { useParams } from 'react-router-dom';

export const PaymentRegionDetailsPage = (): JSX.Element => {
  const { regionId } = useParams<{ regionId: string }>();
  const id = parseInt(regionId);
  return (
    <div className="flex flex-wrap gap-4s">
      <PaymentRegionEditBlock id={id} />
      <PaymentCountryRegionBoundBlock regionId={id} />
      <PaymentMethodJunction regionId={id} />
    </div>
  );
};
