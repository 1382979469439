import { WithdrawalAccountPage } from 'app/withdrawal/account/page';
import { WithdrawalSearchPage } from 'app/withdrawal/search/page';
import { StdTabJunction } from 'packs/std';

export const WithdrawalJunction = (): JSX.Element => {
  return (
    <StdTabJunction
      routes={[
        ['search', <WithdrawalSearchPage />],
        ['accounts', <WithdrawalAccountPage />],
      ]}
    />
  );
};
