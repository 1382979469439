import { CribSimpleForm, CribSubmitButton } from 'packs/crib';
import { CribDurationField } from 'packs/crib/fields/duration-field';
import { apiReq } from 'packs/libs/api';
import { StdFieldBox } from 'packs/std';
import { useState } from 'react';
import { useLaunch } from 'support/react/use-launch';

export const CaseBattleConfigPage = (): JSX.Element => {
  const [data, setData] = useState<any>(null);

  useLaunch(async () => {
    const { timeout } = await apiReq({ action: 'case.battle.config.get',  });
    setData({ timeout });
  }, []);

  const composeContent = () => {
    if (data === null) return <>loading...</>;

    const submit = async (data) => {
      await apiReq({ action: 'case.battle.config.set', progress: true, success: true,body: data });
    };

    return (
      <div className="std-paper p4sa w100s">
        <CribSimpleForm initialData={data} submit={submit}>
          <StdFieldBox title="Timeout">
            <CribDurationField name="timeout" required />
          </StdFieldBox>
          <CribSubmitButton />
        </CribSimpleForm>
      </div>
    );
  };

  return (
    <div>
      <div className="tg-title">Case Battle Config</div>
      {composeContent()}
    </div>
  );
};
