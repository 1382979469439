import {RefObject, useEffect, useRef} from 'react';

export function listenOuterClick(ref: RefObject<HTMLElement>, onClick: () => void) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      onClick();
    }
  }

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}

export function useOuterClick(onClick: () => void, deps: any[] = []) {
  const ref = useRef(null);

  useEffect(() => listenOuterClick(ref, onClick), deps.concat(ref.current));

  return ref;
}
