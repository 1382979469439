import { openPlayerSearchDialog } from 'domain/player/player-search';
import { apiReq } from 'packs/libs/api';
import { Censor } from 'packs/single/censor';
import { useSingleton } from 'packs/state/use-singleton';
import { StdKindButton } from 'packs/std';
import { StdButtonBool } from 'packs/std/inputs/std.button-bool';
import { Swiss, useSwissState } from 'support/react/swiss';
import { useLaunch } from 'support/react/use-launch';

const useChatConfig = () => {
  const [state, op] = useSwissState(null);
  useLaunch(async () => {
    op.setState(await apiReq({ action: 'chat.config.get' }));
  }, []);
  const outOp = useSingleton(
    () =>
      new Swiss((state) => {
        apiReq({
          progress: true,
          action: 'chat.config.set',
          body: state,
        }).then(() => {
          op.setState(state);
        });
      }, op.getState)
  );

  return [state, outOp] as const;
};

export function ChatConfigPage() {
  const [config, op] = useChatConfig();
  if (config == null) return null;

  return (
    <div className="flex flex-col gap-4s">
      <div className="flex gap-4s">
        <div className="std-paper p4sa w-min">
          <StdButtonBool
            value={config.locked}
            truly={'locked'}
            falsy={'unlocked'}
            onChange={(enabled) => {
              op.updState({ enabled });
            }}
          />
          <div className="tg-caption">lock to prevent any messages from players</div>
        </div>

        <div className="std-paper p4sa">
          <StdKindButton
            kind="ah"
            children="Unmute Player"
            onClick={() => {
              openPlayerSearchDialog({
                submit: async (player) => {
                  await apiReq({
                    action: 'chat.unmute',
                    body: player.id,
                    success: true,
                    progress: true,
                  });
                },
              });
            }}
          />
        </div>
      </div>

      <div className="std-paper p4sa w-max-200s">
        <div className="tg-h5">Censor</div>
        <Censor
          value={config.censor}
          onChange={(data) => {
            op.updState({ censor: data });
          }}
        />
      </div>
    </div>
  );
}
