import { css } from '@emotion/css';
import { appSyringe } from 'app/syringe';
import { CribOperator, useCribContext } from 'packs/crib/store';
import { ExpandArrowIcon } from 'packs/iconic/ExpandArrowIcon';
import { StdPopover } from 'packs/std/layouts/popover';
import { ReactNode } from 'react';
import { execIfFn } from 'support/etc/exec-if-fn';

type Option = [ReactNode, any];

type CribPresetsProps = {
  options: OrFactory<Option[]>;
  onSelect: (value: any, crib: CribOperator) => any;
};

export const CribPresets = (props: CribPresetsProps): JsxElement => {
  const crib = useCribContext();

  return (
    <div
      className="cursor-pointer flex items-center"
      onClick={(e) => {
        let options = execIfFn(props.options);
        appSyringe(function OptionsPop({ onClose }) {
          return (
            <StdPopover
              open
              onClose={onClose}
              anchorEl={e.target as Element}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div>
                {options.map(([label, value]) => {
                  return (
                    <div
                      className={itemClass}
                      onClick={() => {
                        props.onSelect(value, crib);
                        onClose();
                      }}
                    >
                      {label}
                    </div>
                  );
                })}
              </div>
            </StdPopover>
          );
        });
      }}
    >
      <div className="tg-caption2">PRESETS</div>
      <ExpandArrowIcon />
    </div>
  );
};

const itemClass = css`
  padding: 4px 8px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
