import execIfFn from 'support/execIfFn';
import useForceRefresh from 'packs/state/useForceRefresh';
import { useDebugValue, useMemo } from 'react';

/**
 * Basic Store represents fundamental use of states
 * it consists of [State, Operator]
 * where State - immutable data that triggers rerender
 * Operator - some operations with state.
 * Operator never changes from its creation
 */

type OperatorFactory<State, Operator> = (set: (state: State) => void, get: () => State) => Operator;

export function useBasicStore<State, Operator>(init: () => [State, OperatorFactory<State, Operator>]) {
  const refresh = useForceRefresh();
  type Store = [State, Operator]
  const state = useMemo((): () => Store => {
    const get = () => store[0];
    const set = (state) => {
      if (state !== get()) {
        store = [state, operator];
        refresh();
      }
    };

    const [state, factory] = init() as any;
    const operator = factory(set, get);

    let store: Store = [state, operator];

    return () => store;
  }, [])();

  useDebugValue(state);

  return state;
}