import { apiReq } from 'packs/libs/api';
import { StdKindButton } from 'packs/std';

export const PlayerPeriodicQuestsBlock = ({ playerId }): JSX.Element => {
  return (
    <div className="std-paper p4sa flex flex-col gap-4s">
      <div className="tg-title">Periodic Quests</div>
      <StdKindButton
        className="w-min"
        children="advance all"
        onClick={() => {
          apiReq({
            action: 'quest.hack.periodic.advance-all',
            body: { playerId },
            
          });
        }}
      />
    </div>
  );
};
