import { cx } from '@emotion/css';
import { StdKindButton } from 'packs/std/inputs/std.kind-button';
import { ReactNode } from 'react';

type StdButtonBoolProps = {
  falsy?: ReactNode;
  truly?: ReactNode;
  onChange(value: boolean): void;
  value?: boolean;
  className?: string;
};

export const StdButtonBool = ({
  className,
  falsy,
  truly,
  onChange,
  value,
}: StdButtonBoolProps): JSX.Element => {
  const [trueKind, falseKind] = ['a', 'ah'] as any;
  return (
    <div className={cx('flex', className)}>
      <StdKindButton
        children={truly ?? 'Enabled'}
        onClick={() => onChange(true)}
        kind={value ? trueKind : falseKind}
      />
      <StdKindButton
        children={falsy ?? 'Disabled'}
        onClick={() => onChange(false)}
        kind={value ? falseKind : trueKind}
      />
    </div>
  );
};
