import { useRef } from 'react';
import { useJustEffect } from 'fnd/bridge/react';
import { infException } from 'fnd/inf/Inf';
import { css } from '@emotion/css';

function loadScript(src: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => resolve();
    script.onabort = reject;
    document.head.appendChild(script);
  });
}

type QRCodeProps = {
  email;
  secret;
};

export function AuthTfaQRCode({ email, secret }: QRCodeProps) {
  const ref = useRef<HTMLCanvasElement>();

  useJustEffect(async () => {
    const el = ref.current;
    if (!el) return;

    const getServiceName = () => {
      let env = ENV_CODE;
      if (env === 'prod') env = '';
      else env = ` (${env})`;
      return `csgocock console${env}`;
    };

    const service = getServiceName();

    const qrcode = await import('qrcode');
    await loadScript('https://unpkg.com/@otplib/preset-browser@^12.0.0/buffer.js');
    await loadScript('https://unpkg.com/@otplib/preset-browser@^12.0.0/index.js');

    // @ts-ignore
    const authenticator = window.otplib.authenticator;

    const uri = authenticator.keyuri(email, service, secret);

    qrcode.toCanvas(el, uri).catch(infException);
  }, [ref]);

  return (
    <div className="flex justify-center">
      <canvas className={canvasClass} ref={ref} />
    </div>
  );
}

const canvasClass = css`
  width: 100px;
  height: 100px;
`;
