import { useSwissState } from 'packs/state/swiss-store';
import { css } from '@emotion/css';
import AuthStepPwd from 'app/public/auth/auth.step.pwd';
import { AuthStepTfa } from 'app/public/auth/auth.step.tfa';
import { apiReq } from 'packs/libs/api';
import { getActor } from 'domain/actor';

enum AuthStep {
  pwd,
  tfa
}

type State = {
  step: AuthStep
  email?: string
  pwd?: string
  tfaSecret?: string
}

export function AuthMain() {
  const [state, { updState: updState }] = useSwissState<State>(() => ({
    step: AuthStep.pwd,
  }));

  const getContent = () => {
    switch (state.step) {
      case AuthStep.pwd:
        return (
          <AuthStepPwd
            submit={async (data) => {
              const result = await apiReq({
                action: 'employee.sign-in.pwd.verify',
                body: data,
              });

              updState({
                step: AuthStep.tfa,
                ...data,
                tfaSecret: result?.tfaSecret,
              });
            }}
          />
        );
      case AuthStep.tfa:
        const { email, pwd } = state;
        return <AuthStepTfa
          email={state.email}
          secret={state.tfaSecret}
          submit={async (code) => {
            const result = await apiReq({
              action: 'employee.sign-in.tfa.verify',
              body: { email, pwd, code },
                          });

            getActor().login(result);
          }}
        />;
    }
  };

  return (
    <div className={mainClass}>
      {getContent()}
    </div>
  );
}

const mainClass = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -100px;
  align-items: center;
  height: 100vh;
`;