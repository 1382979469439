import { useEffect } from 'react';

export const launch = (action: () => Promise<any>): void => {
  action().catch(console.error);
};

export const useLaunch = (effect: () => any, deps: any[]) => {
  useEffect(() => {
    effect();
  }, deps);
};
