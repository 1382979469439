import { parseExpression } from 'cron-parser';

export class CronRule {
  constructor(public readonly rule: string) {}

  nextDate(): Date {
    return this.current.next().toDate();
  }

  private get current() {
    return parseExpression(this.rule);
  }

  *[Symbol.iterator]() {
    const it = parseExpression(this.rule, { iterator: true });
    while (true) yield it.next().value.toDate();
  }

  iterate() {
    return this[Symbol.iterator]();
  }

  // this.exp = parseExpression(rule);
  // private exp: CronExpression<false>;
  // private get current() {
  //   // @ts-ignore
  //   this.exp['_currentDate'] = new CronDate(new Date(), 'UTC');
  //   return this.exp;
  // }
}
