import { LeaderboardConfigPage } from 'app/leaderboard/config.page';
import { LeaderboardDashboard } from 'app/leaderboard/dashboard';
import { LeaderHistoryPage } from 'app/leaderboard/history.page';
import { StdTabJunction } from 'packs/std';

export const LeaderboardJunction = (): JSX.Element => {
  return (
    <StdTabJunction
      routes={[
        ['dashboard', <LeaderboardDashboard />],
        ['config', <LeaderboardConfigPage />],
        ['history', <LeaderHistoryPage />],
      ]}
    />
  );
};
