import { css, cx } from '@emotion/css';
import { useField } from 'formik';
import { FmkError } from 'packs/fmk/FmkStyling';
import { StdNumberInput } from 'packs/std';
import { createElement, ReactNode } from 'react';

type FmkNumberInputProps = InputProps & {
  name: string;
  label?: ReactNode;
  about?: ReactNode;
};

export function FmkNumberInputGroup({
  name,
  label,
  about,
  className,
  ...props
}: FmkNumberInputProps) {
  const [field, meta, actions] = useField(name);
  const error = meta.touched && meta.error;

  return (
    <div className={cx(groupClass, className)}>
      <label className="tg-subheader">{label}</label>
      {about && <div className="tg-caption">{about}</div>}
      {createElement(StdNumberInput, {
        ...props,
        ...field,
        onChange(value) {
          actions.setTouched(true);
          actions.setValue(value);
        },
        name,
      })}
      {error && <FmkError>{error}</FmkError>}
    </div>
  );
}

const groupClass = css`
  position: relative;
  width: 100%;
`;

type ExampleFieldProps = {
  label: string;
  className?: string;
  name: string;
};

// export const ExampleField = ({ name, className, label }: ExampleFieldProps): JSX.Element => {
//   const [field, meta] = useField(name);
//   return (
//     <div className={cx(boxClass, className)}>
//       <label className={labelClass}>{label}</label>
//       <input {...field} className={inputClass} />
//       {meta.touched && meta.error && <div className={errorClass}>{meta.error}</div>}
//     </div>
//   );
// };
