import cronstrue from 'cronstrue';
import { QuartzFooter } from 'packs/blocks/quartz';
import { Stable } from 'packs/blocks/suss-table';
import { SussProvider } from 'packs/libs/Suss';
import { apiReq } from 'packs/libs/api';
import { CopyIcon } from 'packs/single/copy-icon';
import { ShortSpan } from 'packs/single/short-span';
import { StdKindButton, StdLink } from 'packs/std';
import { Link } from 'react-router-dom';

const request = (query, context) => {
  return apiReq({
    action: 'giveaway.template.search',
    body: { ...query, ...context },
  });
};

export const GiveawayTemplateSearchPage = (): JSX.Element => {
  return (
    <div className="flex flex-col gap-4s">
      <div className="flex gap-4s">
        {/* <div className="tg-h1">Search Templates</div> */}
        <Link to="/giveaway/templates/add">
          <StdKindButton children="Add Template" />
        </Link>
      </div>
      <SussProvider query={{ sort: [['enabled', 'desc']] }} request={request}>
        <div className="flex flex-col gap-4s w300s">
          <Stable Row={Row}>
            <th>Id</th>
            <th>Skin</th>
            <th>Cost</th>
            <th>Cron</th>
          </Stable>
          <QuartzFooter />
        </div>
      </SussProvider>
    </div>
  );
};

type RawProps = {
  data: any;
};

const Row = ({ data }: RawProps): JSX.Element => {
  return (
    <tr>
      <td>
        <CopyIcon copy={data.id} />
        <StdLink to={`/giveaway/templates/${data.id}`}>{data.id}</StdLink>
      </td>
      <td>{data.prize.skin.id}</td>
      <td>{data.prize.cost}</td>
      <td>
        <ShortSpan max={30}>{cronstrue.toString(data.cron)}</ShortSpan>
      </td>
    </tr>
  );
};

// const slightlyPaleClass = css`
//   background-color: var(--grey-950);
//   opacity: 0.7;
// `;
