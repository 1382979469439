type ErrorData = any;

export class Exception extends Error {}

export class RuntimeException extends Exception {}

export class LogicException extends Exception {}

// @ts-ignore
function factory<T extends Error>(Type: typeof T) {
  return function createException(data?: ErrorData): T {
    const exception = new Type();
    switch (typeof data) {
      case "object":
        Object.assign(exception, data);
        break;
      case "string":
        exception.message = data;
    }
    return exception;
  };
}

export const exceptLogic = factory(LogicException)
export const exceptRuntime = factory(RuntimeException)

const except = Object.assign(factory(Exception), {
  runtime: exceptRuntime,
  logic: exceptLogic,
});

export default except;
