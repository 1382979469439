import { css } from '@emotion/css';
import { fetchEmployeeRolesList } from 'domain/employee/role/employee-role.api';
import omitNil from 'fnd/support/omitNil';
import { Stable, StableHead, StableQInput } from 'packs/blocks/suss-table';
import { apiReq } from 'packs/libs/api';
import { SussProvider, useSussQueryProp } from 'packs/libs/Suss';
import { StdTabSwitch } from 'packs/std';
import { memo, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

const request = async (query, context) => {
  return apiReq({
    action: 'employee.search',
    body: { ...omitNil(query), ...context },
      });
};

export function EmployeeAreaSearch({ children }: Rcp) {
  return (
    <SussProvider size={100} request={request}>
      <div className="flex flex-col gap-6s">
        <RoleFilter/>
        <div className="flex gap-6s">
          <div className="std-paper flex flex-col gap-4s">
            <div className={searchClass}>
              <StableQInput placeholder='Search by email'/>
            </div>
            <div className="p4sa">
              <Stable Row={TableRow}>
                <StableHead>
                  Email
                </StableHead>
                <StableHead>
                  Role
                </StableHead>
              </Stable>
            </div>
          </div>
          {children}
        </div>
      </div>
    </SussProvider>
  );
}

function RoleFilter() {
  const [roleId, setRoleId] = useSussQueryProp<string | undefined>('role');
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    (async () => {
      const roles = await fetchEmployeeRolesList();
      setTabs([
        [undefined, 'All Roles'],
        ...roles.map(role => [role.id, role.name]),
      ]);
    })();
  }, []);

  return useMemo(() => (
    <StdTabSwitch
      value={roleId}
      onChange={setRoleId}
      tabs={tabs}
      type='b'
    />
  ), [roleId, tabs]);
}

type ListItemProps = {
  data: {
    id: string
    email: string
    role: {
      name: string
    }
  }
}

const TableRow = memo(
  function ListItem({
    data: {
      id, email, role,
    },
  }: ListItemProps) {
    return (
      <tr>
        <td>
          <Link
            className={linkClass}
            to={`/employee/users/${id}`}
          >
            {email}
          </Link>
        </td>
        <td>
          {role.name}
        </td>
      </tr>
    );
  },
);

const linkClass = css`
  transition: color 300ms ease-in;

  &:hover {
    color: var(--blue-800);
  }
`;

const searchClass = css`
  padding: 12px;
  border-bottom: 1px solid var(--grey-800);
`;