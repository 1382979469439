import { useEffect, useState } from 'react';

import { getActor } from 'domain/actor';
import { infException, infSuccess } from 'fnd/inf/Inf';
import { urlToBlob } from 'fnd/support/blob';
import pipeStrict from 'fnd/support/pipeStrict';
import { progress } from 'packs/libs/Progress';
import { Swiss, useSwissState } from 'packs/state/swiss-store';
import { stringify } from 'qs';
import execIfFn from 'support/execIfFn';
import { stashGet } from 'support/memory/stash';

type CommonOptions = {
  action: string;
  headers?: Record<string, string>;
  query?: any;
  body?: any;
  attach?: any[];
};

type ApiFetchOptions = CommonOptions & {
  method: string;
};

export type ApiReqOptions = CommonOptions & {
  success?: true;
  error?: false;
  progress?: boolean;
};

export class ApiClient {
  constructor(private readonly location) {}

  async fetch({ action, body, headers, query, method }: ApiFetchOptions): Promise<Response> {
    let url = `${this.location}/${action}`;
    if (query) url += `?${stringify(query)}`;

    const response = await fetch(url, {
      method,
      body,
      headers,
    });

    if (!response.ok) {
      throw { response };
    }

    return response;
  }

  private ProgressMiddleware(opts: ApiReqOptions, next) {
    return opts.progress ? progress(() => next(opts)) : next(opts);
  }

  private InfMiddleware(opts: ApiReqOptions, next) {
    let promise = next(opts);
    if (opts.success) {
      promise.then(() => infSuccess('Success', { autoClose: 2000 }));
    }
    if (!opts.error) {
      promise.catch((error) => {
        const { response, ...others } = error;
        infException({
          ...others,
          action: opts.action,
          query: opts.query,
          body: opts.body,
          employee: getActor().authed && getActor().payload.id,
          status: error.response.status,
          rid: error.response.headers.get('rid'),
        });
      });
    }
    return promise;
  }

  private async RequestMiddleware({ action, query, body, headers = {}, attach }: ApiReqOptions) {
    const access = getActor()?.jwt;
    if (access) headers['authorization'] = access;

    const resolved = await resolveBody(body, attach);
    try {
      const response = await this.fetch({
        action,
        query,
        headers,
        body: resolved,
        method: resolved === undefined ? 'get' : 'post',
      });

      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (e) {
        return text as any;
      }
    } catch (error) {
      if (!error.response) throw error;

      if (error.response.status === 401) {
        await getActor()?.kickOut();
        throw error;
      }

      const plain = await error.response.text();
      try {
        Object.assign(error, JSON.parse(plain));
      } catch (e) {
        Object.assign(error, { plain });
      }

      throw error;
    }
  }

  @stashGet
  get req() {
    const exec = pipeStrict<ApiReqOptions, Promise<any>>(
      [this.ProgressMiddleware, this.InfMiddleware, this.RequestMiddleware].map((v) => v.bind(this))
    );
    return <T = any>(options: ApiReqOptions | string): Promise<T> => {
      return exec(typeof options === 'string' ? { action: options } : options);
    };
  }
}

export const Api = new ApiClient(ENV_API_URL);

export const apiReq: <T = any>(options: ApiReqOptions | string) => Promise<T> = Api.req.bind(Api);

window['api'] = Api;

type ReqOpts = ApiReqOptions | string;

export function useApiReq<T = any>(
  opts: ReqOpts | (() => ReqOpts),
  deps: any[] = []
): T | undefined {
  const [state, setState] = useState(undefined);
  useEffect(() => {
    apiReq(execIfFn(opts)).then(setState);
  }, deps);
  return state;
}

export const useApiSwiss = <T = any>(
  opts: ReqOpts | (() => ReqOpts),
  deps: any[] = []
): [T, Swiss<T>] => {
  const store = useSwissState(undefined);
  useEffect(() => {
    apiReq(execIfFn(opts)).then(store[1].setState);
  }, deps);
  return store;
};

async function resolveBody(body, attach): Promise<any> {
  if (!attach) return JSON.stringify([body]);

  const formData = new FormData();
  const $info = [];
  for (const [i, file] of attach.entries()) {
    if (file === undefined) continue;
    const name = `att${i}`;
    if (file instanceof File) {
      formData.append(name, file);
      $info[i] = {
        name: file.name,
        type: file.type,
        size: file.size,
      };
    } else {
      const { url, ...info } = file;
      formData.append(name, await urlToBlob(url));
      $info[i] = info;
    }
  }
  formData.append('data', JSON.stringify(body || {}));
  formData.append('info', JSON.stringify($info));

  return formData;
}
