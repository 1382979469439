import { SimpleOutpost } from 'packs/single/simple-outpost';
import React, { createElement, Fragment, useEffect, useState } from 'react';

export type SyringeInjectorProps = {
  onClose(): void;
};

export type SyringeInjector = React.ComponentType<SyringeInjectorProps>;

type Inject = (injector: SyringeInjector) => () => void;

const initialState = () => new Map();

export function makeSyringe(displayName?: string): [React.ComponentType, Inject] {
  let i = 0;
  let beforeMount: SyringeInjector[] = [];
  let doInject: Inject = beforeMount.push.bind(beforeMount);

  const Syringe = () => {
    const [items, setItems] = useState<Map<number, React.ReactNode>>(initialState);

    useEffect(() => {
      function set(key: number, val: React.ReactNode) {
        setItems((items) => new Map(items).set(key, val));
      }

      function del(key: number) {
        setItems((items) => {
          const next = new Map(items);
          next.delete(key);
          return next;
        });
      }

      doInject = (Injector: SyringeInjector) => {
        const key = ++i;

        const onClose = () => del(key);

        // if yarn start -> hot reloading support
        if (__DEV__) {
          set(
            key,
            createElement(
              function Dev() {
                return (
                  <SimpleOutpost>
                    <Injector key={key} onClose={onClose} />
                  </SimpleOutpost>
                );
              },
              { key }
            )
          );
        } else {
          set(
            key,
            <SimpleOutpost>
              <Injector key={key} onClose={onClose} />
            </SimpleOutpost>
          );
        }

        return onClose;
      };

      for (const Injector of beforeMount) doInject(Injector);
      beforeMount = [];
    }, []);

    return createElement(Fragment, {
      children: [...items.values()],
    });
  };

  Syringe[Symbol.toStringTag] = `${displayName ?? ''}Syringe`;

  return [Syringe, (injector) => doInject(injector)];
}
