import { usePlayerDetailsStore } from 'app/player/details/store';
import { apiReq } from 'packs/libs/api';
import { StdAutoTextArea, StdKindButton } from 'packs/std';
import { useState } from 'react';

export const PlayerDetailsSummaryNoteBlock = (): JSX.Element => {
  const [data, op] = usePlayerDetailsStore();
  const [value, setValue] = useState(data.note);
  return (
    <div className="flex flex-col gap-2s std-paper p4sa">
      <div className="flex justify-between">
        <div className="tg-subhead">Note</div>
        <StdKindButton
          size="xs"
          kind={value === data.note ? 'ah' : 'a'}
          children="Save"
          onClick={async () => {
            await apiReq({
              action: 'player.upd',
              body: { id: data.id, note: value },
              success: true,
            });
            op.updState({ note: value });
          }}
        />
      </div>
      <StdAutoTextArea
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </div>
  );
};
