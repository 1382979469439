import { css } from '@emotion/css';
import SettingsIcon from '@mui/icons-material/Settings';
import { usePlayerDetailsStore } from 'app/player/details/store';
import { usePlayerAmplitudeStatsConfig } from 'app/player/details/summary/amplitude/config';
import { openPlayerAmplitudeSettingsDialog } from 'app/player/details/summary/amplitude/settings';
import { ExpandIcon } from 'packs/iconic/expand';
import { apiReq } from 'packs/libs/api';
import { SimpleOutpost } from 'packs/single/simple-outpost';
import { useMemo, useState } from 'react';
import { useLaunch } from 'support/react/use-launch';

export const PlayerAmplitudeStats = (): JSX.Element => {
  const [{ id: playerId }] = usePlayerDetailsStore();
  const [data, setData] = useState(null);

  useLaunch(async () => {
    setData(null);
    const data = await apiReq({
      action: 'player.amplitude.user-properties.get',
      query: { playerId },
    });
    setData(data);
  }, [playerId]);

  return (
    <div className="std-paper p4sa">
      <div className="flex gap-4s justify-between items-center">
        <div className="tg-title1">Amplitude Stats</div>
        <SettingsIcon className="cursor-pointer" onClick={openPlayerAmplitudeSettingsDialog} />
      </div>
      <SimpleOutpost>
        <Content data={data} />
      </SimpleOutpost>
    </div>
  );
};

type ContentProps = {
  data: Record<string, unknown>;
};

const Content = ({ data }: ContentProps): JSX.Element => {
  const [config] = usePlayerAmplitudeStatsConfig();
  return useMemo(() => {
    if (config === null) return null;
    if (data === null) return null;

    const groups = [];

    const map = new Map(Object.entries(data));

    for (const opts of config.groups) {
      const data: [string, unknown][] = [];
      for (const prop of opts.fields) {
        const value = map.get(prop);
        if (value === undefined) continue;
        data.push([prop, value]);
        map.delete(prop);
      }
      if (data.length === 0) continue;
      groups.push(<Group title={opts.title} data={data} collapsedByDefault={opts.collapsed} />);
    }

    if (map.size > 0) {
      const data = [...map.entries()].sort((a, b) => a[0].localeCompare(b[0]));
      groups.push(<Group title="Others" data={data} collapsedByDefault={true} />);
    }

    return <div className="flex flex-col gap-4s">{...groups}</div>;
  }, [data, config]);
};

type GroupProps = {
  title: string;
  collapsedByDefault?: boolean;
  data: [string, unknown][];
};

const Group = ({ title, collapsedByDefault, data }: GroupProps): JSX.Element => {
  const [expanded, expand] = useState(!collapsedByDefault);

  return (
    <div>
      <div className="flex gap-4s items-center cursor-pointer" onClick={() => expand(!expanded)}>
        <ExpandIcon value={expanded} />
        <div className="tg-title2">{title}</div>
      </div>
      {expanded && (
        <div className="w-full">
          {data.map(([key, value]) => {
            return (
              <div key={key} className={lineClass}>
                <div className="w70s truncate">{key}</div>
                <div>{value}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const lineClass = css`
  display: flex;
  gap: 4px;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;
