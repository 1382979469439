import { QuestsDashArea } from 'app/quests/dash/quests.dash.area';
import { QuestsChapterSearchArea } from 'app/quests/QuestsChapterSearchArea';
import { QuestsTemplatesArea } from 'app/quests/template/QuestsTemplatesArea';
import { StdTabJunction } from 'packs/std';

export function QuestsPage() {
  return (
    <StdTabJunction
      routes={[
        ['dash', <QuestsDashArea />],
        ['chapters', <QuestsChapterSearchArea />],
        ['template', <QuestsTemplatesArea />],
      ]}
    />
  );
}
