import { css, cx } from '@emotion/css';
import { CaseCard } from 'domain/case/card';
import { CaseSlotCard } from 'domain/case/slot-card';
import { PlayerSimpleCard } from 'domain/player/simple-card';
import { apiReq } from 'packs/libs/api';
import { CopyIcon } from 'packs/single/copy-icon';
import { SimpleAttrTable } from 'packs/single/simple-attr-table';
import { StdOuterLink } from 'packs/std';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLaunch } from 'support/react/use-launch';

export const CaseBattleDetailsPage = (): JSX.Element => {
  const [data, setData] = useState(null);
  const { id } = useParams<{ id: string }>();

  useLaunch(async () => {
    setData(await apiReq({ action: 'case.battle.history.get', body: id }));
  }, []);

  if (data === null) return null;

  const { _id, members, cases, winner, ticketId } = data;

  return (
    <div className="flex flex-col gap-4s">
      <div>
        <div className="std-paper p4sa w120s">
          <SimpleAttrTable
            data={[
              ['id', _id],
              [
                'ticket',
                <span>
                  <StdOuterLink href={`https://api.random.org/tickets/form?ticket=${ticketId}`}>
                    {ticketId}
                  </StdOuterLink>
                  <CopyIcon copy={ticketId} />
                </span>,
              ],
              [
                'total sum',
                '$' + members.reduce((acc, { totalDropped }) => acc + totalDropped, 0).toFixed(2),
              ],
            ]}
          />
        </div>
      </div>

      <div className="std-paper p4sa">
        <div className="tg-title">Cases</div>
        <div className="flex flex-wrap gap-4s">
          {cases.map((data, i) => {
            return (
              <div key={i}>
                <Link to={`/cases/${data.id}`}>
                  <CaseCard data={data} />
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <div className="std-paper p4sa">
        <div className="tg-title">Participants</div>
        <div className="flex gap-4s">
          {members.map((position) => {
            const { player, dropped } = position;
            return (
              <div
                key={player.id}
                className={cx('flex flex-col gap-4s p2sa', winner === player.id && winnerClass)}
              >
                <PlayerSimpleCard data={position.player} />
                <div>
                  <div className="tg-subhead">Total: ${position.totalDropped.toFixed(2)}</div>
                  <div className="flex flex-col gap-4s">
                    {dropped.map(({ nonce, slot }) => {
                      return (
                        <div>
                          <div>
                            <CaseSlotCard data={slot} />
                          </div>
                          <div>nonce: {nonce}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const winnerClass = css`
  background-color: #33ff0039;
`;
