import { chronicle } from 'fnd/bridge/router';
import { QuartzFooter } from 'packs/blocks/quartz';
import { Stable } from 'packs/blocks/suss-table';
import { apiReq } from 'packs/libs/api';
import { SussProvider } from 'packs/libs/Suss';
import { StdKindButton } from 'packs/std';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

type PaymentMethodSearchBlockProps = { regionId: number };

export const PaymentMethodSearchBlock = ({
  regionId,
}: PaymentMethodSearchBlockProps): JSX.Element => {
  const request = useCallback(
    (query, context) =>
      apiReq({
        action: 'payment.method.search',
        body: { regionId, ...query, ...context },
        progress: true,
      }),
    []
  );

  const pass = {
    go: (id: number) => chronicle.push(`/payment/region/${regionId}/method/${id}`),
  };

  return (
    <div className="flex flex-col gap-4s w80s">
      <div className="flex justify-between">
        <div className="tg-title">Methods</div>
        <Link to="method/add">
          <StdKindButton children="Add Method" />
        </Link>
      </div>
      <div className="flex flex-col gap-4s">
        <SussProvider request={request}>
          <Stable Row={Row} pass={pass}>
            <th>name</th>
            <th>enabled</th>
          </Stable>
          <QuartzFooter />
        </SussProvider>
      </div>
    </div>
  );
};

type RowProps = {
  data;
  pass: {
    go: (id: number) => void;
  };
};

const Row = ({ data, pass }: RowProps): JSX.Element => {
  return (
    <tr onClick={() => pass.go(data.id)} className="cursor-pointer">
      <td>{data.name.en}</td>
      <td>{data.enabled ? 'enabled' : 'disabled'}</td>
    </tr>
  );
};

type ItemData = {
  preset: [3, 5, 10, 15, 20, 50];
  countries: [
    {
      code: 'AO';
      name: 'Angola';
    },
    {
      code: 'ZW';
      name: 'Zimbabwe';
    }
  ];
  id: 1;
  stage: 'active';
  name: {
    ru: 'Африка';
    en: 'Africa';
    es: '';
  };
  currency: 'USD';
  defaultAmount: 3;
  sort: 0;
  createdAt: {
    seconds: {
      low: 1680690918;
      high: 0;
      unsigned: false;
    };
    nanos: 0;
  };
  updatedAt: {
    seconds: {
      low: 1680690918;
      high: 0;
      unsigned: false;
    };
    nanos: 0;
  };
};
