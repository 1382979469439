import { PlayerRole } from 'domain/player/definitions';
import { PlayerImage } from 'domain/player/image';
import { openPlayerSearchDialog, PlayerSearchQuery } from 'domain/player/player-search';
import { enumOp } from 'fnd/support/enum-operator';
import { useCribField } from 'packs/crib/store';
import { CribErrorBox } from 'packs/crib/units/error-box';
import { StdKindButton } from 'packs/std';
import { Link } from 'react-router-dom';
import { z } from 'zod';

type CribPlayerFieldProps = {
  name: string;
  required?: boolean;
  query?: PlayerSearchQuery;
  getValue?<T extends { id: string; image: string; name: string }>(player): T;
};

export const CribPlayerField = (props: CribPlayerFieldProps): JSX.Element => {
  const [{ value, error }, op] = useCribField(() => {
    let schema: any = z.object({}).passthrough();
    const { name, required } = props;
    if (!required) schema = schema.optional();
    return { name, schema };
  });

  const call = () => {
    openPlayerSearchDialog({
      query: props.query,
      submit: (data) => {
        const value = props.getValue
          ? props.getValue(data)
          : { id: data.id, name: data.name, image: data.image };

        op.setValue(value);
      },
    });
  };

  return (
    <div>
      {value ? (
        <div className="flex w70s gap-2s">
          <div className="rounded-md cursor-pointer" onClick={call}>
            <PlayerImage className="h20s" code={value.image} />
          </div>
          <div>
            <div>
              <Link className="std-link" to={`/players/${value.id}`} target="_blank">
                {value.name}
              </Link>
            </div>
            <div>{enumOp(PlayerRole).valKeyMap.get(value.role as PlayerRole)}</div>
          </div>
        </div>
      ) : (
        <StdKindButton children="Select Player" onClick={call} />
      )}
      <CribErrorBox error={error} />
    </div>
  );
};
