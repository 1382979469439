import { el } from "fnd/bridge/react";
import { ComponentType, lazy, Suspense } from "react";

export default function stdLoadable<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
): T {
  const Component = lazy<T>(factory);

  // @ts-ignore
  return function StdLoader(props) {
    return (
      <Suspense fallback={null}>
        {el(Component, props)}
      </Suspense>
    );
  };
}
