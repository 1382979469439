import { css, cx } from '@emotion/css';
import Modal from '@material-ui/core/Modal';
import React from 'react';

export type StdDialogProps = React.HTMLAttributes<HTMLDivElement> & {
  opened?: boolean;
  children: React.ReactNode;
  onClose(): void;
  closeIcon?: boolean;
  className?: string;
};

export function StdDialog({ opened = true, onClose, ...props }: StdDialogProps) {
  return (
    <Modal onClose={onClose} open={opened}>
      <div className={boxClass}>
        <div {...props} className={cx(contentClass, props.className)} />
      </div>
    </Modal>
  );
}

const boxClass = css`
  border-radius: 3px;
  left: 50%;
  outline: none;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const contentClass = css`
  background: var(--grey-900);
`;
