import { cx } from '@emotion/css';
import HistoryIcon from '@mui/icons-material/History';
import { AccessGuard } from 'domain/actor/access-guard';
import { openAuditHistory } from 'domain/etc/audit/history';

type AuditIconProps = {
  stack: string[];
  access?: string;
  className?: string;
};

export const AuditIcon = ({ stack, className, access }: AuditIconProps): JsxElement => {
  return (
    <AccessGuard access={access ?? 'audit.read'}>
      <HistoryIcon
        onClick={() => openAuditHistory(stack)}
        className={cx('cursor-pointer', className)}
      />
    </AccessGuard>
  );
};
