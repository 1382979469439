import { ContestCurrentCalendarPage } from 'app/contest/current/calendar';
import { ContestAdd, ContestAddFromTemplate } from 'app/contest/form';
import { ContestHistoryJunction } from 'app/contest/history/junction';
import { ContestTemplateAddPage, ContestTemplateEditPage } from 'app/contest/template/form';
import { ContestTemplateSearchPage } from 'app/contest/template/search';
import { StdTabJunction } from 'packs/std';

export const ContestJunction = (): JSX.Element => {
  return (
    <StdTabJunction
      routes={[
        ['current', <ContestCurrentCalendarPage />],
        { path: 'templates/add', elem: <ContestTemplateAddPage />, hidden: true },
        { path: 'templates/:templateId', elem: <ContestTemplateEditPage />, hidden: true },
        ['templates', <ContestTemplateSearchPage />],
        { path: 'add/from-template/:templateId', elem: <ContestAddFromTemplate />, hidden: true },
        { path: 'add', elem: <ContestAdd />, hidden: true },
        { path: 'history', elem: <ContestHistoryJunction /> },
      ]}
    />
  );
};
