import { EnumMap } from 'packs/struct/enum-map';

export type ContestData = {
  id: string;
  templateId?: string;
  title: LcRecord<string>;
  status: ContestStatus;
  start: number;
  duration: number;
  joined: number;
  cap: number; // max members
  requirements: any[];
  cost: number;
  code?: string;
  prize: number[];
  upon: ContestUpon[];
};

export enum ContestStatus {
  pending = 'pending',
  appeared = 'appeared',
  active = 'active',
  calculation = 'calculation',
  finished = 'finished',
}

export enum ContestUpon {
  crush = 'crush',
  upgrade = 'upgrade',
  caseBattle = 'case_battle',
}

export enum ContestUponCode {
  crush,
  upgrade,
  caseBattle,
}

export const ContestUponCodeMap = EnumMap.create(ContestUpon, ContestUponCode);
