import { css, cx } from '@emotion/css';
import { Ace, config, edit } from 'ace-builds';
import { dump, load } from 'js-yaml';
import { useSingleton } from 'packs/state/use-singleton';

import mode_yaml_url from 'ace-builds/src-noconflict/mode-yaml?url';
import theme_github from 'ace-builds/src-noconflict/theme-github?url';
import { infException } from 'fnd/inf/Inf';
import { debounce } from 'lodash';
config.setModuleUrl('ace/mode/yaml', mode_yaml_url);
config.setModuleUrl('ace/theme/github', theme_github);

type ConfigCellProps<T extends Rsa = Rsu> = {
  className?: string;
  value: T;
  onChange(T): void;
};

export const Pile = (props: ConfigCellProps): JSX.Element => {
  // const [error, setError] = useState();
  const store = useSingleton(() => {
    let parent: HTMLElement;
    let editor: Ace.Editor;

    let lastContent;
    const setContent = (content: string) => {
      editor.setValue((lastContent = content), content.length);
    };

    const doDump = (value: any) => dump(value, { lineWidth: 200, noCompatMode: true });

    const setValue = (value: any) => {
      setContent(doDump((lastValue = value)));
    };

    let lastValue: any = props.value;
    const update = (props: ConfigCellProps) => {
      if (lastValue !== props.value) {
        setValue(props.value);
      }
    };

    const doLoad = (content: string) => {
      try {
        return load(content);
      } catch (e) {
        infException(e);
        throw e;
      }
    };

    const launchSave = debounce(() => {
      const content = editor.getValue();
      if (lastContent !== content) {
        props.onChange((lastValue = doLoad((lastContent = content))));
      }
    }, 300);

    const content = (
      <div
        ref={(el: HTMLElement) => {
          if (el === null) {
            parent = null;
            editor?.destroy();
          } else {
            parent = el;
            editor = edit(el, {
              mode: 'ace/mode/yaml',
              theme: 'ace/theme/github',
              selectionStyle: 'text',
            });
            editor.setOptions({
              maxLines: Infinity,
              fontSize: '16px',
            });
            editor.on('change', launchSave);
            editor.on('change', console.log);
            setValue(lastValue);
          }
        }}
        className={cx('p4sa', props.className)}
      />
    );

    return {
      update,
      content,
    };
  });

  store.update(props);

  return store.content;
};

const mainClass = css``;

const panelClass = css``;

const editorClass = css``;
