import { FmkDebug, FmkForm, FmkInputGroup, FmkSubmitButton, useFmkConfig } from 'packs/fmk';
import styled from 'styled-components';
import React from 'react';
import { infException } from 'fnd/inf/Inf';
import { css } from '@emotion/css';

type AuthStepPwdProps = {
  submit
}
export default function AuthStepPwd(props: AuthStepPwdProps) {
  const config = useFmkConfig(yup => ({
    initialValues: {
      email: '',
      pwd: '',
    },
    validationSchema: yup.object({
      email: yup.string().email(),
      pwd: yup.string().max(1024),
    }),
    simpleSubmit: async (values, { setErrors }) => {
      try {
        await props.submit(values);
      } catch (e) {
        console.log(e);
        if (e.status === 400) {
          setErrors({
            pwd: 'Invalid email or password',
          });
        } else {
          infException(e);
        }
      }
    },
  }));

  return (
    <div className={mainClass}>
      <SignInTitle>
        Sign In
      </SignInTitle>
      <FmkForm {...config} className="flex flex-col gap-4s">
        <FmkInputGroup
          name={'email'}
          autoComplete={'username'}
          label={'Email'}
        />
        <FmkInputGroup
          name={'pwd'}
          type={'password'}
          autoComplete={'current-password'}
          label={'Password'}
        />
        <FmkSubmitButton
          children={'submit'}
        />
        <FmkDebug/>
      </FmkForm>
    </div>
  );
}

const mainClass = css`
  width: 50vh;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  padding: 20px;
`;

const SignInTitle = styled.h1`
  padding: 0;
  margin: 10px 0;
  text-align: center;
  font-size: 30px;
  color: var(--dark-blue-100);
`;
