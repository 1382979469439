import { usePlayerDetailsStore } from 'app/player/details/store';
import { PlayerRole } from 'domain/player/definitions';
import { enumOp } from 'fnd/support/enum-operator';
import { apiReq } from 'packs/libs/api';
import { CopyIcon } from 'packs/single/copy-icon';
import { SimpleAttrTable } from 'packs/single/simple-attr-table';
import { SimpleEditTextField } from 'packs/single/simple-edit-text-field';
import { StdSelect } from 'packs/std';
import { StdCheckbox } from 'packs/std/inputs/checkbox';
import { StdTime } from 'packs/std/time/format';

export const PlayerDetailsSummaryBasicInfo = (): JSX.Element => {
  const [data, op] = usePlayerDetailsStore();
  const simpleUpd = async (data) => {
    await apiReq({
      action: 'player.upd',
      body: { id: playerId, ...data },
      success: true,
    });
    op.updState(data);
  };

  const {
    id: playerId,
    name,
    balance,
    role,
    bot,
    crushRoundsPlayed,
    welcomeActivated,
    tradeUrl,
    steamLevel,
    steamId,
    lastLoginAt,
    signUpAt,
    timeOnline,
    social,
  } = data;

  return (
    <div className="flex flex-col gap-4s">
      <div className="std-paper p4sa">
        <div className="tg-caption p2sb">
          {playerId} <CopyIcon copy={playerId} />
        </div>
        <SimpleAttrTable
          data={[
            [
              'Name',
              <SimpleEditTextField value={name} onChange={async (name) => simpleUpd({ name })} />,
            ],
            ['Balance', `$${balance}`],
            [
              'Role',
              <StdSelect
                className="w30s"
                options={enumOp(PlayerRole).labelValue}
                value={enumOp(PlayerRole).labelValueMap.get(role)}
                // getOptionValue={(v) => v}
                // getOptionLabel={(v) => v}
                onChange={async ({ value: role }) => {
                  simpleUpd({ role });
                }}
              />,
            ],
            [
              'Is Bot',
              <StdCheckbox
                checked={bot}
                onChange={async (bot) => {
                  simpleUpd({ bot });
                }}
              />,
            ],
            ['Signed Up', StdTime.ms.datetime(signUpAt)],
            ['Last Login', StdTime.ms.datetime(lastLoginAt)],
            ['Crush Round Played', crushRoundsPlayed],
            ['Welcome Activated', welcomeActivated ? 'Yes' : 'No'],
            ['Trade Url', tradeUrl ?? '~'],
            ['Steam Level', steamLevel],
            ['Steam Id', steamId],
          ]}
        />
      </div>
    </div>
  );
};
