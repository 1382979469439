import { Dump } from 'packs/single/Dump';
import * as React from 'react';
import { toast, ToastOptions, TypeOptions } from 'react-toastify';

// function doToast(node: React.ReactNode) {
//   toast(node, {
//     position: 'top-right',
//   });
// }

type Opts = ToastOptions;

export function infNote(type: TypeOptions, children: React.ReactNode, opts?: Opts) {
  // doToast(el(Note, {type, children}));
  toast(children, {
    type,
    position: 'top-right',
    ...opts,
  });
}

export function infSuccess(children: React.ReactNode = 'success', opts?: Opts) {
  infNote('success', children, opts);
}

export function infError(children: React.ReactNode = 'error', opts?: Opts) {
  infNote('error', children, opts);
}

export function infException(error) {
  infError(<Dump data={error} />);
}

export const infDispatch = (action: () => Promise<any>) => {
  action().catch(infException);
};


export const shotSuccessToast = infSuccess;