export function formatError(error: any) {
  if (error instanceof Error) {
    const output: Rsa = { ...error };
    output.class = error.constructor.name;
    output.code = error.name;
    output.message = error.message;
    output.stack = error.stack;
    if (error.cause) {
      output.cause = formatError(error.cause);
    }
    return output;
  }

  return error;
}
