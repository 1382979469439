import styled from 'styled-components';
import React from 'react';


export const FmkStretch = styled.div`
  width: 100%;
`;


type FmkWrapProps = React.HTMLAttributes<HTMLDivElement> & {
  label: string;
};

export function FmkWrap({label, children, ...props}: FmkWrapProps) {
  return (
    <WrapDiv {...props}>
      <WrapLabelDiv>{label}</WrapLabelDiv>
      {children}
    </WrapDiv>
  );
}

const WrapDiv = styled.div`
  margin: 16px 0;
`;

const WrapLabelDiv = styled.div`
  padding-bottom: 8px;
`;

export const FmkError = styled.div`
  color: #e54141;
  display: flex;
  flex-direction: row-reverse;
  font-size: 10px;
`;
