import { el } from 'fnd/bridge/react';
import { Form as FormikForm, Formik, FormikConfig, FormikValues, useFormikContext } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { StdKindButtonProps, StdKindButton } from 'packs/std';
import React, { useMemo } from 'react';
import * as yup from 'yup';

type FmkConfig<V> = Omit<FormikConfig<V>, 'onSubmit'> & {
  onSubmit?: (values: V, formikHelpers: FormikHelpers<V>) => void | Promise<any>;
  simpleSubmit?: (values: V, formikHelpers: FormikHelpers<V>) => void | Promise<void>;
};

type FormProps<V> = FmkConfig<V> & {
  children: React.ReactNode;
  className?: string;
};

export const FmkForm = function <Values extends FormikValues = FormikValues>({
  children,
  onSubmit,
  simpleSubmit,
  className,
  ...props
}: FormProps<Values>) {
  const submit = useMemo(
    () =>
      onSubmit
        ? onSubmit
        : async (values, helpers) => {
            try {
              await simpleSubmit(values, helpers);
            } finally {
              helpers.setSubmitting(false);
            }
          },
    [onSubmit, simpleSubmit]
  );

  return (
    <Formik {...props} onSubmit={submit}>
      <FormikForm className={className}>{children}</FormikForm>
    </Formik>
  );
};

export const FmkDebug = (): JSX.Element => {
  if (__DEV__) {
    console.debug('Fpc Debug', useFormikContext());
  }
  return null;
};

type SubmitButtonProps = Omit<StdKindButtonProps, 'type' | 'disabled'>;
export const FmkSubmitButton = (props: SubmitButtonProps) =>
  el(StdKindButton, {
    ...props,
    type: 'submit',
    disabled: useFormikContext().isSubmitting,
  });

type Yup = typeof yup;

type ConfigFactory<V> = (yup: Yup) => FmkConfig<V>;

export function useFmkConfig<V>(factory: ConfigFactory<V>, deps: any[] = []): FmkConfig<V> {
  return useMemo(() => factory(yup), deps);
}
