import { cx } from '@emotion/css';
import { StdKindButton } from 'packs/std/inputs/std.kind-button';
import { ReactNode } from 'react';

type Option = string | [any, ReactNode];

type SwitchSelectProps = {
  value: any;
  options: Option[];
  onChange(value: any): any;
  className?: string;
};

export const SwitchSelect = ({
  value,
  options,
  onChange,
  className,
}: SwitchSelectProps): JSX.Element => {
  return (
    <div className={cx('flex', className)}>
      {options.map((opt, i) => {
        const option = resolveOption(opt);
        return (
          <StdKindButton
            children={option.label}
            onClick={() => onChange(option.value)}
            kind={option.value === value ? 'a' : 'ah'}
          />
        );
      })}
    </div>
  );
};

const resolveOption = (opt: Option) => {
  if (typeof opt === 'string') return { value: opt, label: opt };
  return { value: opt[0], label: opt[1] };
};
