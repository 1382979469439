import { formatInTimeZone } from 'date-fns-tz';
import { useSingleton } from 'packs/state/use-singleton';
import { createRefEffect } from 'support/react/ref-effect';

export const HeaderClock = () => {
  return useSingleton(() => {
    const ref = createRefEffect<HTMLDivElement>((el) => {
      const timer = setInterval(() => {
        el.textContent = formatInTimeZone(new Date(), 'UTC', 'yyyy-MM-dd HH:mm:ss');
      }, 1000);
      return () => clearInterval(timer);
    });
    return <div title="UTC Time" ref={ref} />;
  });
};
