import { useMemo, useState } from 'react';
import {
  FmkForm,
  FmkNumberInputGroup,
  FmkAutoTextArea,
  FmkWrap,
  useFmkConfig,
  FmkInput,
} from 'packs/fmk';
import { cc, voidMemo } from 'fnd/bridge/react';
import isObjectEmpty from 'fnd/support/isObjectEmpty';
import styled from '@emotion/styled';
import { useField, useFormikContext } from 'formik';
import { Slider } from '@material-ui/core';
import { debounce } from 'packs/deps';
import { lcRec, SUPPORTED_LOCALES } from 'fnd/l10n';
import { css } from '@emotion/css';

export type QuestTemplateData = {
  chance: number;
  options: any;
};

type QuestTemplateProps = {
  code: string;
  schema: Rsa;
  template?: Partial<QuestTemplateData>;
  update(code: string, data: QuestTemplateData): void;
};

export const QuestTemplate = voidMemo(function QuestTemplate({
  code,
  schema,
  update,
  template,
}: QuestTemplateProps) {
  const config = useFmkConfig<QuestTemplateData>((yup) => {
    function jsonToYup(props) {
      function propSchema({ minimum, exclusiveMinimum }) {
        let schema = yup.number().required();
        if (minimum) schema = schema.min(minimum);
        if (exclusiveMinimum) schema = schema.moreThan(exclusiveMinimum);
        return schema;
      }

      return yup.object(
        Object.entries<any>(props).reduce(
          (obj, [prop, data]) => ({ ...obj, [prop]: propSchema(data) }),
          {}
        )
      );
    }

    // const l10nStringSchema = yup.
    // const l10nInitValues = LSL

    return {
      initialValues: {
        title: lcRec(''),
        description: lcRec(''),
        chance: 0,
        options: {},
        ...(template ?? {}),
      },
      validationSchema: yup.object({
        title: yup.object(lcRec(yup.string().required())),
        description: yup.object(lcRec(yup.string().required())),
        chance: yup.number().min(0).max(1).required(),
        options: jsonToYup(schema),
      }),
      simpleSubmit(values) {
        update(code, values);
      },
    };
  });

  return (
    <div className={mainClass}>
      <FmkForm {...config} className={'df'}>
        <AutoSave />
        {/*<FmkDebug/>*/}
        <Body code={code} schema={schema} />
      </FmkForm>
    </div>
  );
});

type BodyProps = {
  code: string;
  schema: Rsa;
};

function Body(props: BodyProps) {
  const ctx = useFormikContext<any>();
  const expanded = ctx.values.chance !== 0;

  return useMemo(() => {
    const { code, schema } = props;
    return (
      <div className="flex w-full">
        <Side />
        <div className={'p4sx p2sy df fdc fg1'}>
          <div className={'df jcsb aic'}>
            <ChanceField />
            <div className="tg-caption">{code}</div>
          </div>

          <LcGrid className={''}>
            {SUPPORTED_LOCALES.map((lc) => {
              return (
                <div key={lc}>
                  <div>{lc}</div>
                  <div className="p1sy">
                    <FmkInput name={`title.${lc}`} />
                  </div>
                </div>
              );
            })}
          </LcGrid>
          {expanded && (
            <>
              <LcGrid>
                {SUPPORTED_LOCALES.map((lc) => {
                  return (
                    <div key={lc}>
                      <FmkAutoTextArea name={`description.${lc}`} />
                    </div>
                  );
                })}
              </LcGrid>
              <div>
                {Object.entries<any>(schema).map(([name, { title, description }]) => {
                  return (
                    <FmkWrap key={name} label={title}>
                      <FmkNumberInputGroup
                        name={`options.${name}`}
                        placeholder={description}
                        style={{ width: '40xp' }}
                      />
                    </FmkWrap>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }, [expanded]);
}

function ChanceField() {
  const [, { value }, actions] = useField('chance');
  return useMemo(() => {
    const change = debounce((val) => {
      actions.setTouched(true);
      actions.setValue(val);
    }, 100);
    return (
      <div className="df aic">
        <div className={changeLabelClass}>never</div>
        <div className={sliderBoxClass}>
          <Slider
            min={0}
            max={1}
            step={0.05}
            defaultValue={value}
            onChange={(e, val) => {
              change(val);
            }}
          />
        </div>
        <div className={changeLabelClass}>always</div>
      </div>
    );
  }, [value]);
}

const sliderBoxClass = css`
  width: 120px;
  padding: 0 12px;
`;

const changeLabelClass = css`
  font-size: 12px;
  font-weight: 300;
  color: var(--text-30);
`;

function Side() {
  const {
    values: { chance },
    errors,
  } = useFormikContext<any>();

  return (
    <div
      className={sideClass}
      style={{
        background: !isObjectEmpty(errors) ? '#ff8585' : chance !== 0 ? '#8bc1ff' : '#dbdbdb',
      }}
    />
  );
}

function AutoSave() {
  const { values, submitForm } = useFormikContext();
  const update = useMemo(() => {
    const debounceSubmit = debounce(submitForm, 500);

    let current = values;

    return (values) => {
      if (current !== values) {
        current = values;
        debounceSubmit();
      }
    };
  }, [submitForm]);

  update(values);

  return null;
}

const LcGrid = styled.div`
  width: 100%;
  display: grid;
  column-gap: 4px;
  grid-template-columns: repeat(${SUPPORTED_LOCALES.length}, 1fr);
`;

const mainClass = `std-paper ${css`
  width: ${100 + SUPPORTED_LOCALES.length * 300}px;
`}`;

const sideClass = css`
  width: 60px;
`;
