import { PromoAdd } from 'app/promo/form';
import { PromoDashboard } from 'app/promo/dashboard';
import { PromoDetails } from 'app/promo/details';
import { StdTabJunction } from 'packs/std';

export const PromoJunction = (): JSX.Element => {
  return (
    <StdTabJunction
      routes={[
        ['dashboard', <PromoDashboard />],
        // ['history', <PromoHistory />],
        { path: 'add', elem: <PromoAdd />, hidden: true },
        { path: ':code', elem: <PromoDetails />, hidden: true },
      ]}
    />
  );
};
