import { apiReq } from 'packs/libs/api';
import { ConfigCell } from 'packs/single/config-cell';
import { StdKindButton } from 'packs/std';
import { useState } from 'react';

export const MajorConfigPage = (): JSX.Element => {
  const [op, setOp] = useState<any>();

  return (
    <div className="p4sa flex flex-wrap gap-4s">
      <ConfigCell
        className="w200s"
        title="Global Config (Major Config)"
        prefix="major-config"
        op={setOp}
      />
      <div className="flex flex-wrap gap-4s">
        <div>
          <StdKindButton
            children="toggle disable withdrawal"
            onClick={() => {
              const { withdrawal } = op.getState();
              console.log(op.getState());
              if (withdrawal) {
                op.mutState((draft) => {
                  delete draft.withdrawal;
                });
              } else {
                op.mutState((draft) => {
                  draft.withdrawal = { enabled: false };
                });
              }
            }}
          />
        </div>
        <div>
          <StdKindButton
            children="send update service worker"
            onClick={async () => {
              await apiReq({ action: 'sw.update', progress: true });
            }}
          />
        </div>
      </div>
    </div>
  );
};
