import { HashMap } from 'support/struct/hash-map';

export class IHashMap<K, V> {
  private source: HashMap<K, V>;

  static by<K, V>(indexBy: (item: V) => K, list: V[]): IHashMap<K, V> {
    return IHashMap.fromEntries<K, V>(list.map((value) => [indexBy(value), value]));
  }

  static fromHashMap<K, V>(map: HashMap<K, V>) {
    const obj = new IHashMap<K, V>();
    obj.source = map;
    return obj;
  }

  static fromEntries<K, V>(entries?: readonly (readonly [K, V])[]) {
    return IHashMap.fromHashMap<K, V>(new HashMap<K, V>(entries));
  }

  static empty<K, V>() {
    return IHashMap.fromEntries<K, V>();
  }

  private constructor() {}

  find(key: K): V | undefined {
    return this.source.find(key);
  }

  // @ts-ignore
  set(key: K, val: V): IHashMap<K, V> {
    return IHashMap.fromHashMap<K, V>(new HashMap<K, V>(this.source).set(key, val));
  }

  get(key: K): V {
    return this.source.get(key);
  }

  mut(cb: (map: HashMap<K, V>) => void) {
    const next = new HashMap(this.source);
    cb(next);
    return IHashMap.fromHashMap<K, V>(next);
  }

  updOne(key: K, cb: (val: V) => V) {
    const value = this.find(key);
    if (value === undefined) return this;
    return this.set(key, cb(value));
  }

  map<T>(fn: (val: V) => T): T[] {
    const result: T[] = [];
    for (const value of this.values()) result.push(fn(value));
    return result;
  }

  mapEntries<T>(fn: (val: [K, V]) => T): T[] {
    const result: T[] = [];
    for (const value of this.source.entries()) result.push(fn(value));
    return result;
  }

  listMapV<T>(fn: (val: V) => T): T[] {
    const result: T[] = [];
    for (const value of this.values()) result.push(fn(value));
    return result;
  }

  values() {
    return this.source.values();
  }

  keys() {
    return this.source.keys();
  }

  del(key: K) {
    const next = new HashMap<K, V>(this.source);
    next.delete(key);
    return IHashMap.fromHashMap<K, V>(next);
  }

  delMany(keys: K[]) {
    return this.mut((map) => {
      for (const id of keys) {
        map.delete(id);
      }
    });
  }

  get size() {
    return this.source.size;
  }
}
