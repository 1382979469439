import { css } from '@emotion/css';
import { appSyringe } from 'app/syringe';
import { SkinCard } from 'domain/skin/card';
import { apiReq } from 'packs/libs/api';
import { useSuss } from 'packs/libs/Suss';
import { SassyDialog } from 'packs/single/sassy-dialog';
import { StdKindButton, StdNumberInput } from 'packs/std';
import { useMemo } from 'react';

type SkinData = {
  id: string;
  image: string;
  color: [number, number, number];
  price: number;
};

type Opts = {
  submit(data: SkinData): void;
};

const PRICE_PROP = 'price';
const PRICE_OPERATORS = ['<->', '>=', '<='];

export const openSkinSearchDialog = (opts: Opts) => {
  appSyringe(function ItemSelectPop({ onClose }): JSX.Element {
    return (
      <SassyDialog
        className="w180s"
        onClose={onClose}
        placeholder="Skin name"
        query={{ [PRICE_PROP]: { op: PRICE_OPERATORS[0] } }}
        request={async (query) => {
          const body: Rsa = {};
          if (query.price.value) body.price = query.price;
          else if (query.q) body.q = query.q;
          else return;
          return apiReq({
            action: 'skin.search',
                        body,
          });
        }}
        filters={
          <div>
            <div className="tg-caption text-center">OR</div>
            <PriceFilter />
          </div>
        }
      >
        {(results) => (
          <div className={listClass}>
            {results.map((skin) => {
              return (
                <button
                  key={skin.id}
                  tabIndex={1}
                  onClick={() => {
                    opts.submit(skin);
                    onClose();
                  }}
                >
                  <SkinCard data={skin} />
                </button>
              );
            })}
          </div>
        )}
      </SassyDialog>
    );
  });
};

export const PriceFilter = (): JSX.Element => {
  const suss = useSuss();
  const state = suss[0].query[PRICE_PROP];
  return useMemo(() => {
    const { op, value } = state;
    const stateOp = suss[1].query.prop(PRICE_PROP);
    return (
      <div className="flex gap-1s">
        {PRICE_OPERATORS.map((val) => (
          <StdKindButton
            key={val}
            children={val}
            active={val === op}
            onClick={() => {
              stateOp.updState({ op: val });
            }}
          />
        ))}
        <StdNumberInput
          placeholder="amount"
          onChange={(value) => {
            stateOp.updState({ value });
          }}
        />
      </div>
    );
  }, [state]);
};

const listClass = css`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 16px;
`;
