import {useUniStore} from 'packs/state/UniStore';
import {el, makeNullContext} from 'fnd/bridge/react';
import {useContext} from 'react';

const Context = makeNullContext<boolean>();

export const useProgress = () => useContext(Context);

let stackAdd: (amount: number) => void;

export const progressUp = () => stackAdd(1);
export const progressDown = () => stackAdd(-1);

export async function progress<T>(fn: () => Promise<T>): Promise<T> {
  progressUp();
  try {
    return await fn();
  } finally {
    progressDown();
  }
}

export function ProgressProvider({children}: Rcp) {
  const value = useUniStore<boolean, boolean>((set, get) => {
    let stack = 0;

    stackAdd = (add: number) => {
      stack += add;
      const active = stack > 0;
      if (get() !== active) set(active);
    };

    return [false, stack => stack];
  });

  return el(Context.Provider, {value, children});
}
