import { useCribField } from 'packs/crib/store';
import { CribErrorBox } from 'packs/crib/units/error-box';
import { StdInput, StdInputProps, StdNumberInput } from 'packs/std';
import execIfFn from 'support/execIfFn';
import { ZodTypeAny } from 'zod';

type CribInputFieldProps = StdInputProps & {
  name: string;
  schema?: ZodTypeAny | (() => ZodTypeAny);
  editable?: boolean;
};

export const CribInputField = (props: CribInputFieldProps): JSX.Element => {
  const { name, schema, ...others } = props;
  const [{ value, error }, op] = useCribField(() => {
    return { name, schema: execIfFn(schema), initialValue: undefined };
  });

  return (
    <div>
      <StdInput
        {...others}
        value={value}
        onChange={(e) => {
          op.setValue(e.target.value);
        }}
      />
      <CribErrorBox error={error} />
    </div>
  );
};

type CribNumberInputFieldProps = {
  name: string;
  schema?: ZodTypeAny | (() => ZodTypeAny);
  editable?: boolean;
  number?: boolean;
  initialValue?: number;
  placeholder?: string;
  autoFocus?: boolean;
};

export const CribNumberInputField = (props: CribNumberInputFieldProps): JSX.Element => {
  const { name, schema, ...others } = props;
  const [{ value, error }, op] = useCribField(() => {
    return { name, schema: execIfFn(schema), initialValue: props.initialValue };
  });
  return (
    <div>
      <StdNumberInput {...others} value={value} onChange={op.setValue} />
      <CribErrorBox error={error} />
    </div>
  );
};
