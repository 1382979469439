import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createElement } from 'react';
import adapterLocale from 'date-fns/esm/locale/ru';

type StdDateTimeInputProps = {
  value: Date | undefined;
  onChange: (value: Date | undefined) => void;
};

export const StdDateTimeInput = ({ value, onChange }: StdDateTimeInputProps): JSX.Element => {
  return (
    <div className="p2sy">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
        <DateTimePicker
          // @ts-ignore
          renderInput={(props) => createElement(TextField, props)}
          label="DateTimePicker"
          value={value ?? null}
          onChange={(newValue) => {
            onChange(newValue ?? undefined);
          }}
        />
      </LocalizationProvider>
    </div>
  );
};
