import { PlayerPeriodicQuestsBlock } from 'app/player/details/quests/periodic';
import { PlayerSingleQuestsBlock } from 'app/player/details/quests/single';
import { AccessGuard } from 'domain/actor/access-guard';

type PlayerQuestsProps = {
  playerId: string;
};

export const PlayerQuests = ({ playerId }: PlayerQuestsProps): JSX.Element => {
  return (
    <div className="flex flex-wrap gap-4s">
      <PlayerSingleQuestsBlock playerId={playerId} />
      <div>
        <PlayerPeriodicQuestsBlock playerId={playerId} />
      </div>
    </div>
  );
};
