import { css, cx } from '@emotion/css';
import { SkinData } from 'domain/skin/definitions';
import { SkinImage } from 'domain/skin/image';
import { skinNameViewOnSkin } from 'domain/skin/name';
import { stdLinearClamp } from 'packs/std';
import { CSSProperties } from 'react';
import { rgbTupleToString } from 'support/etc/rgb-tuple-to-string';

type SkinCardProps = {
  data: SkinData;
  cost?: number;
  onClick?();
  className?: string;
};

export const SkinCard = (props: SkinCardProps): JSX.Element => {
  const { data, onClick, className } = props;
  const { id, image, color } = data;
  let price = props.cost ?? data.price;

  const name = skinNameViewOnSkin(data);
  return (
    <div
      className={cx(mainClass, className, onClick && clickableClass)}
      style={{ '--l-color': rgbTupleToString(color) } as CSSProperties}
      onClick={onClick}
    >
      <div className={imageBoxClass}>
        <SkinImage className={imageClass} code={image} alt={id} />
      </div>
      <div className={contentClass}>
        <div className={headerClass}>
          {price && (
            <span className={costClass} title={price.toString()}>
              ${price}
            </span>
          )}
        </div>
        <div className={nameClass}>
          <div className={titleClass} title={name.model}>
            {name.model}
          </div>
          <div className={subtitleClass} title={name.brand}>
            {name.brand}
          </div>
        </div>
      </div>
      {lowLineEl}
      {/* <Dump className={dumpClass} data={data} /> */}
    </div>
  );
};

const dumpClass = css`
  position: absolute;
  background: var(--grey-800);
  top: 105%;
  max-width: 300px;
  word-break: normal;
  white-space: pre-wrap;
  /* display: none; */
`;

const mainClass = css`
  background: var(--dark-blue-600);
  border-radius: 6px;
  height: 150px;
  width: 160px;
  color: white;
  font-size: ${stdLinearClamp(10, 16)};
  position: relative;

  /* &:hover {
    .${dumpClass} {
      display: block;
    }
  } */
`;

const clickableClass = css`
  cursor: pointer;
  box-shadow: 0 0 6px 4px rgba(var(--l-color), 0.2);
`;

const lowLineClass = css`
  background: rgb(var(--l-color));
  bottom: 2px;
  height: 2px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 52%;
`;

const lowLineEl = <div className={lowLineClass} />;

const contentClass = css`
  background: linear-gradient(180deg, rgba(var(--l-color), 0) 0%, rgba(var(--l-color), 0.2) 100%);
  background-origin: border-box;
  border: transparent solid 2px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 15px 10px 10px;
  position: relative;
  text-align: left;

  &.selected {
    border-color: rgb(var(--l-color));
  }
`;

const headerClass = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const costClass = css`
  font-size: 1em;
`;

const imageBoxClass = css`
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 10%;
  right: 10%;
`;

const imageClass = css`
  margin: 7px auto 11px;
  user-select: none;
  object-fit: contain;
`;

const nameClass = css`
  flex-basis: 30px;
`;

const textClass = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const titleClass = cx(
  textClass,
  css`
    font-size: 1em;
    line-height: 100%;
    font-weight: 600;
  `
);

const subtitleClass = cx(
  textClass,
  css`
    font-size: 0.6em;
    font-weight: 500;
    line-height: 100%;
    margin-top: 3px;
  `
);
