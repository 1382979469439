import { css, cx } from '@emotion/css';
import LaunchIcon from '@mui/icons-material/Launch';
import { AnchorHTMLAttributes } from 'react';

type StdOuterLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {};

export const StdOuterLink = ({
  children,
  className,
  ...others
}: StdOuterLinkProps): JSX.Element => {
  return (
    <a className={cx(mainClass, className)} target="_blank" rel="noreferrer" {...others}>
      {children}
      <LaunchIcon fontSize="small" />
    </a>
  );
};

export { LaunchIcon as StdOuterLinkIcon };

const mainClass = css`
  color: #0088ff;
`;
