import { useFormikContext } from 'formik';


type FmkLazyErrorProps = {
  name: string
};

export const FmkLazyError = ({
  
}: FmkLazyErrorProps): JSX.Element => {
  const context = useFormikContext();
  console.log(context); 
  return null;
}

// const create_view = (path: string)