type Entries<K, V> = [K, V][] | Map<K, V>

export default class IMap<K, V> {
  private readonly inner: Map<K, V>;

  static make<K, V>(entries?: Entries<K, V>) {
    return new IMap(new Map(entries));
  }

  private constructor(map: Map<K, V>) {
    this.inner = map;
  }

  find(key: K): V | undefined {
    return this.inner.get(key);
  }

  get(key: K): V {
    const val = this.find(key);
    if (val === undefined) throw new Error('imap.key.missing');
    return val;
  }

  set(key: K, val: V): IMap<K, V> {
    return new IMap(new Map(this.inner).set(key, val));
  }

  del(key: K) {
    const next = new Map(this.inner);
    next.delete(key);
    return new IMap(next);
  }

  mut(cb: (map: Map<K, V>) => void) {
    const next = new Map(this.inner);
    cb(next);
    return new IMap(next);
  }

  upd(key: K, cb: (value: V) => V): IMap<K, V> {
    return this.set(key, cb(this.get(key)));
  }

  map<T = unknown>(cb: (value: V, key: K) => T): T[] {
    const result: T[] = [];
    for (const entry of this.inner.entries()) {
      result.push(cb(entry[1], entry[0]));
    }
    return result;
  }

  selfMap(cb: (value: V, key: K) => V): IMap<K, V> {
    return IMap.make(this.map<[K, V]>((val, key) => [key, cb(val, key)]));
  }

  delMany(keys: K[]) {
    const next = IMap.make(this.inner);
    for (const id of keys) {
      next.inner.delete(id);
    }
    return next;
  }

  values() {
    return this.inner.values();
  }

  keys() {
    return this.inner.keys();
  }

  entries() {
    return this.inner.entries();
  }

  allKeys() {
    return [...this.keys()];
  }

  allValues() {
    return [...this.values()];
  }

  get size() {
    return this.inner.size;
  }
}
