import { formatDuration, intervalToDuration } from 'date-fns';
import { DateTime } from 'luxon';

export const STD_LUX_FORMAT = 'yyyy-MM-dd HH:mm:ss';

const createLux =
  <T>(c: (input: T) => DateTime) =>
  (val: T) =>
    c(val).toFormat(STD_LUX_FORMAT);

const createDuration = (fn: (arg) => Date) => {
  return (value) => {
    console.log(value);
    if (!value) return;
    return formatDuration(intervalToDuration({ start: 0, end: fn(value) }), {
      format: ['years', 'months', 'days', 'hours', 'minutes', 'seconds'],
    });
  };
};

const fromMillis = (val: number) => new Date(val);

export const StdTime = {
  ms: {
    datetime: createLux(DateTime.fromMillis),
    duration: createDuration(fromMillis),
  },
  iso: {
    datetime: createLux(DateTime.fromISO),
  },
};
