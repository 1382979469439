import { apiReq } from 'packs/libs/api';
import { Censor } from 'packs/single/censor';
import { useState } from 'react';
import { useLaunch } from 'support/react/use-launch';

export const PlayerConfigPage = (): JSX.Element => {
  return (
    <div>
      <PlayerNameCensor />
    </div>
  );
};

const PlayerNameCensor = (): JSX.Element => {
  const [state, setState] = useState(null);
  useLaunch(async () => {
    setState(
      await apiReq({
        action: 'player.name-censor.find',
      })
    );
  }, []);
  if (state === null) return null;
  return (
    <div className="std-paper p4sa w80s">
      <div className="tg-title">Name Censor</div>
      <Censor
        value={state}
        onChange={async (data) => {
          await apiReq({
            action: 'player.name-censor.set',
            body: data,
            success: true,
          });
          setState(data);
        }}
      />
    </div>
  );
};
