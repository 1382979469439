import { PromoType, ReplPromoKind } from 'domain/promo/definitions';
import { SkinCard } from 'domain/skin/card';
import { SkinCostCribField } from 'domain/skin/field';
import { openSkinSearchDialog } from 'domain/skin/search';
import { chronicle } from 'fnd/bridge/router';
import { enumOp } from 'fnd/support/enum-operator';
import {
  CribErrorBox,
  CribInputField,
  CribNumberInputField,
  CribSimpleForm,
  CribSubmitButton,
  useCribField,
  useCribWatch,
} from 'packs/crib';
import { CribDateTimeField } from 'packs/crib/fields/date-field';
import { CribDurationField } from 'packs/crib/fields/duration-field';
import { FmkLazyError } from 'packs/fmk';
import { apiReq } from 'packs/libs/api';
import { StdFieldBox, StdKindButton, SwitchSelect } from 'packs/std';
import { useMemo } from 'react';
import { z } from 'zod';

type PromoModProps = {
  initialData: Rsa;
  submit(data: Rsa): Promise<void>;
};

export const PromoMod = ({ initialData, submit }: PromoModProps): JSX.Element => {
  const doSubmit = async (data: any) => {
    console.log('submitting', data);
    await submit(data);
  };
  return (
    <CribSimpleForm submit={doSubmit} initialData={initialData}>
      <div className="flex flex-col gap-4s std-paper p4sa w200s">
        {initialData.code ? (
          <div className="tg-title1">Code: {initialData.code}</div>
        ) : (
          <StdFieldBox title="Code">
            <CribInputField name="code" schema={() => z.string().max(32)} />
          </StdFieldBox>
        )}
        <StdFieldBox title="Quantity" about="to infinite number - leave empty">
          <CribNumberInputField name="qty" schema={() => z.number().int().positive().optional()} />
        </StdFieldBox>
        <StdFieldBox title="Expires At">
          <CribDateTimeField name="expiresAt" schema={() => z.date().min(new Date())} />
        </StdFieldBox>
        <TypeField />
        <CribSubmitButton />
      </div>
    </CribSimpleForm>
  );
};

export const TypeField = (): JSX.Element => {
  const [{ value, error }, op] = useCribField(() => {
    return { name: 'type', schema: z.nativeEnum(PromoType), initialValue: PromoType.replenish };
  });
  return (
    <>
      <StdFieldBox title="Promo Type">
        <SwitchSelect
          value={value}
          options={enumOp(PromoType).values}
          onChange={(value) => {
            op.setValue(value);
            op.context.lens<any>('data').set(undefined);
          }}
        />
        <CribErrorBox error={error} />
      </StdFieldBox>
      {value && <TypeDataField />}
    </>
  );
};

export const PromoAdd = (): JSX.Element => {
  return (
    <PromoMod
      initialData={{}}
      submit={async (data) => {
        await apiReq({
          action: 'promo.add',
          progress: true,
          body: data,
          success: true,
        });

        chronicle.push(`/promo/${data.code}`);
      }}
    />
  );
};

export const TypeDataField = (): JSX.Element => {
  const type = useCribWatch('type');
  return useMemo(() => {
    switch (type) {
      case PromoType.replenish:
        return <ReplDataField />;
      case PromoType.skin:
        return <SkinCostCribField name="data" required />;
      case PromoType.balance:
        return <BalanceDataField />;
      default:
        return null;
    }
  }, [type]);
};

const BalanceDataField = (): JSX.Element => {
  return (
    <StdFieldBox title="Cash $">
      <CribNumberInputField name="data.amount" placeholder="$100" schema={z.number().positive()} />
    </StdFieldBox>
  );
};

const SkinDataField = (): JSX.Element => {
  const [{ value, error }, op] = useCribField(() => {
    return {
      name: 'data',
      schema: z.object({
        skin: z.any(),
        cost: z.number().positive(),
      }),
    };
  });

  const compose = () => {
    const selectSkin = () => {
      openSkinSearchDialog({
        submit(skin) {
          op.setValue({
            skin: {
              id: skin.id,
              color: skin.color,
              image: skin.image,
            },
            cost: skin.price,
          });
        },
      });
    };

    if (!value) {
      return <StdKindButton onClick={selectSkin} children="select skin" />;
    }

    return (
      <div className="flex flex-col gap-4s">
        <SkinCard data={value.skin} onClick={selectSkin} />
        <StdFieldBox title="Cost $">
          <CribNumberInputField name="data.cost" />
        </StdFieldBox>
        <CribErrorBox error={error} />
      </div>
    );
  };

  return (
    <div>
      {compose()}
      <FmkLazyError name={'data.skin'} />
    </div>
  );
};

const ReplDataField = (): JSX.Element => {
  const [{ value, error }, op] = useCribField(() => {
    return {
      name: 'data.kind',
      schema: z.nativeEnum(ReplPromoKind),
      initialValue: ReplPromoKind.percent,
    };
  });
  return (
    <>
      <StdFieldBox title="Replenish Promo Bonus Type">
        <SwitchSelect
          value={value}
          options={enumOp(ReplPromoKind).values}
          onChange={(value) => {
            op.setValue(value);
            // op.context.lens<any>('data').set(undefined);
          }}
        />
        <CribErrorBox error={error} />
      </StdFieldBox>
      {value && <ReplValueField />}
      <StdFieldBox title="Timer" about="duration">
        <CribDurationField name="data.timer" />
      </StdFieldBox>

      <StdFieldBox title="Minimum Deposit $">
        <CribNumberInputField name="data.minDep" schema={() => z.number().positive().optional()} />
      </StdFieldBox>
    </>
  );
};

export const ReplValueField = (): JSX.Element => {
  const kind = useCribWatch('data.kind');
  return useMemo(() => {
    switch (kind) {
      case ReplPromoKind.amount:
        return (
          <StdFieldBox key={ReplPromoKind.amount} title="Bonus Amount">
            <CribNumberInputField name="data.value" schema={z.number().positive()} />
          </StdFieldBox>
        );
      case ReplPromoKind.percent:
        return (
          <StdFieldBox key={ReplPromoKind.percent} title="Bonus Percent">
            <CribNumberInputField name="data.value" schema={z.number().int().positive().max(100)} />
          </StdFieldBox>
        );
      default:
        return null;
    }
  }, [kind]);
};
