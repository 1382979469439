import { SkinData } from 'domain/skin';

export type CaseData = {
  id: string;
  name: string;
  about: string;
  price: number;
  config: any;
};

export type CaseSlotData = {
  type: CaseSlotType.skin;
  skin: SkinData;
  cost: number;
};

export enum CaseSlotType {
  skin = 'skin',
}
