import hotkeys, {HotkeysEvent} from 'hotkeys-js';
import {useEffect} from 'react';

hotkeys.filter = function filter() {
  return true;
};

type KeyHandler = {
  (keyboardEvent: KeyboardEvent, hotkeysEvent: HotkeysEvent): any
}

export function shortcut(keys: string, callback: KeyHandler) {
  function listener(key, hot) {
    key.preventDefault();
    callback(key, hot);
  }

  hotkeys(keys, listener);
  return () => {
    hotkeys.unbind(keys, listener);
  };
}

export type ScutCallback = KeyHandler;

export function useShortcut(key: string, callback: ScutCallback, deps?: any[]) {
  useEffect(() => shortcut(key, callback), deps);
}

type Entry = [key: string, callback: ScutCallback];
type EntriesFactory = () => Entry[]

export function useShortcutEntries(factory: EntriesFactory, deps: any[] = []) {
  useEffect(() => {
    const closes = factory().map(([key, cb]) => shortcut(key, cb));
    return () => {
      for (const close of closes) close();
    };
  }, deps);
}
