import React, { ReactNode } from 'react';

type StableHeadProps = {
  children?: ReactNode
}

export function StableHead({
  children,
}: StableHeadProps) {
  return (
    <th>{children}</th>
  );
}
