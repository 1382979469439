import {isEmpty, isNil} from "ramda";

export default function omitEmpty(obj: Rsa): Rsa {
  const result: any = {};
  for (let [i, v] of Object.entries(obj)) {
    if (!isNil(v) && !isEmpty(v)) result[i] = v;
  }
  return result;
}

