import {useOuterClick} from 'packs/hooks/useOuterClick';
import styled from 'styled-components';
import {useBasicStore} from 'support/react/basic-store';
import { css, cx } from '@emotion/css';

type BlankDynamicInputProps = {
  value?: string
  placeholder?: string
  className?: string
  onUpdate(value: string): void
  onCancel(): void
}

export function BlankDynInput({
  value: initialValue, onUpdate, onCancel,
  placeholder, className
}: BlankDynamicInputProps) {
  const [value, [onChange, submit]] = useBasicStore<string, [(event) => void, () => void]>(() => [
    initialValue ?? '',
    (set, get) => {
      return [
        (e) => {
          set(e.target.value);
        },
        () => {
          const value = get();
          if (
            value === ''
            || value === (initialValue)
          ) onCancel();
          else onUpdate(value);
        },
      ];
    },
  ]);

  const ref = useOuterClick(onCancel, []);

  return (
    <input
      ref={ref}
      value={value}
      placeholder={placeholder}
      className={cx(mainClass, className)}
      onChange={onChange}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          submit();
        }
      }}
    />
  );
}

const mainClass = css`
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
`;
