import { formatDuration, intervalToDuration } from 'date-fns';
import { useCribField } from 'packs/crib/store';
import { CribErrorBox } from 'packs/crib/units/error-box';
import { InfoPopIcon } from 'packs/single/info-pop-icon';
import { StdInput } from 'packs/std/inputs/StdInput';
import { StdOuterLink } from 'packs/std/navigation/outer-link';
import parseDuration from 'parse-duration';
import { useEffect } from 'react';
import execIfFn from 'support/execIfFn';
import { useSwissState } from 'support/react/swiss';
import { ZodTypeAny, z } from 'zod';

type CribDurationFieldProps = {
  name: string;
  required?: boolean;
  autoFocus?: boolean;
};

export const CribDurationField = (props: CribDurationFieldProps): JSX.Element => {
  const [{ value, error }, op] = useCribField(() => {
    let schema: ZodTypeAny = z.number().int().positive();
    if (!props.required) schema = schema.optional();

    return { name: props.name, schema: execIfFn(schema) };
  });

  return (
    <div>
      <StdDurationInput value={value} onChange={op.setValue} />
      <CribErrorBox error={error} />
    </div>
  );
};

type StdDurationInputProps = {
  value: number;
  onChange: (value: number) => void;
  autoFocus?: boolean;
};

type State = {
  value: number;
  line: string;
  parsed: string;
};

const ms_to_duration = (ms: number): string => {
  if (!ms) return;
  return formatDuration(intervalToDuration({ start: 0, end: ms }), {
    format: ['years', 'months', 'days', 'hours', 'minutes', 'seconds'],
  });
};

const resolve_line = (line: string): State => {
  const value = parseDuration(line);
  const parsed = ms_to_duration(value);
  return { value, line, parsed };
};

const resolve_value = (value: number): State => {
  const line = ms_to_duration(value);
  const parsed = ms_to_duration(value);
  return { value, line, parsed };
};

const StdDurationInput = (props: StdDurationInputProps): JSX.Element => {
  const [state, op] = useSwissState(() => resolve_value(props.value));

  const { line, parsed } = state;

  useEffect(() => {
    if (props.value !== state.value) {
      op.setState(resolve_value(props.value));
    }
  }, [props.value]);

  return (
    <div>
      <div className="flex gap-1s items-center">
        <StdInput
          autoFocus={props.autoFocus}
          className="flex-1"
          value={line}
          onChange={(e) => {
            const state = resolve_line(e.target.value);
            op.setState(state);
            props.onChange(state.value);
          }}
        />
        <InfoPopIcon
          info={
            <div className="std-paper p4sa">
              <StdOuterLink href="https://github.com/jkroso/parse-duration#api">
                syntax
              </StdOuterLink>
              <pre>
                {`
Example:
1m 2w 3d4h  5m 6s - 1 month 2 weeks 3 days 4 hours 5 minutes 6 seconds

Available unit types are:
milliseconds (ms)
seconds (s, sec)
minutes (m, min)
hours (h, hr)
days (d)
weeks (w, wk)
months
years (y, yr)`}
              </pre>
            </div>
          }
        />
      </div>
      <div className="tg-caption">{parsed}</div>
    </div>
  );
};
