

export enum ReplPromoKind {
  percent = 'percent',
  amount = 'amount',
}

export enum PromoType {
  balance = 'balance',
  replenish = 'replenish',
  skin = 'skin',
}