import { useSuss } from 'packs/libs/Suss';
import { apiReq } from 'packs/libs/api';
import { Dump } from 'packs/single/Dump';
import { StdKindButton } from 'packs/std';
import { useMemo } from 'react';

export const WithdrawalSearchDetailed = (): JSX.Element => {
  const [state, op] = useSuss();
  const detailedId = state.stash.detailed;

  return useMemo(() => {
    if (!detailedId) return null;
    const data = state.data.find(detailedId);
    if (data === undefined) return null;
    console.log(data, 'data');

    const { id, sellingPrice, purchasePrice, itemId, error, input, logs } = data;

    return (
      <div className="flex-1 relative">
        <div className="flex flex-col sticky top-0 h200s">
          <div className="p4sa std-paper overflow-auto">
            <Dump data={{ id, itemId, sellingPrice, purchasePrice, error, input, logs }} />
            {logs === undefined && (
              <StdKindButton
                kind="ah"
                children="load details"
                onClick={async () => {
                  op.opItem(id).setState(await apiReq({ action: 'withdrawal.get', query: { id } }));
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }, [detailedId, state.data]);
};
