import { createBrowserHistory } from 'history';
import useForceRefresh from 'packs/state/useForceRefresh';
import { useContext, useEffect } from 'react';
import { __RouterContext } from 'react-router';
import { matchPath } from 'react-router-dom';
import { Subscriber } from 'support/struct/subscriber';

export const chronicle = createBrowserHistory<Rsa>();

export function useRelativeMatch<T extends Rsa = Rsa>(path: string) {
  const context = useContext(__RouterContext);
  return matchPath<T>(chronicle.location.pathname, `${context.match.path}${path}`) ?? context.match;
}

let path = chronicle.location.pathname;
const event = new Subscriber();
chronicle.listen((location) => {
  if (location.pathname !== path) {
    path = location.pathname;
    event.emit();
  }
});
export const useLocationPath = () => {
  const commit = useForceRefresh();
  useEffect(() => event.sub(commit));
  return path;
};


window['chronicle'] = chronicle;
chronicle.listen(console.log)