import { format } from 'date-fns';
import { PromoType } from 'domain/promo/definitions';
import { chronicle } from 'fnd/bridge/router';
import { Duration } from 'luxon';
import { QuartzFilterZone, QuartzFooter } from 'packs/blocks/quartz';
import { Stable, StableSortHead } from 'packs/blocks/suss-table';
import { apiReq } from 'packs/libs/api';
import { SussProvider, useSuss } from 'packs/libs/Suss';
import { CopyIcon } from 'packs/single/copy-icon';
import { Dump } from 'packs/single/Dump';
import { StdKindButton, StdLink } from 'packs/std';
import { useMemo } from 'react';

export const PromoDashboard = (): JSX.Element => {
  const request = (query, context) => {
    return apiReq({
      action: 'promo.search',
      body: { ...query, ...context },
    });
  };
  return (
    <div className="flex flex-col gap-4s">
      <span>
        <StdKindButton
          children="Add Promo"
          onClick={() => {
            chronicle.push('/promo/add');
          }}
        />
      </span>
      <div className="w300s flex flex-col gap-4s">
        <SussProvider pk="code" request={request} query={{ sort: [['startsAt', 'desc']] }}>
          <QuartzFilterZone qInput="code" />
          <Stable Row={Row}>
            <th>Code</th>
            <th title="Quantity">Qty</th>
            <th title="Activated">Act</th>
            <th>Type</th>
            <th>Details</th>
            <StableSortHead name={'startsAt'}>Start</StableSortHead>
            <StableSortHead name={'expiresAt'}>Expiration</StableSortHead>
          </Stable>
          <QuartzFooter />
        </SussProvider>
      </div>
    </div>
  );
};

const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

type RowProps = {
  data;
};

const Row = ({ data }: RowProps): JSX.Element => {
  return (
    <tr>
      <td>
        <CopyIcon copy={data.code} />
        <StdLink to={`/promo/${data.code}`}>{data.code}</StdLink>
      </td>
      <td>{data.qty ?? 'Inf'}</td>
      <td>{data.activated}</td>
      <td>{data.type}</td>
      <td>
        <div className="w-max-110s">{composeDetails(data)}</div>
      </td>
      <td>{format(data.startsAt, DATE_FORMAT)}</td>
      <td>{format(data.expiresAt, DATE_FORMAT)}</td>
    </tr>
  );
};

const composeDetails = ({ type, data }: any) => {
  if (type === PromoType.replenish) {
    let result = '';
    if (data.kind === 'percent') result += `${data.value}%`;
    else if (data.kind === 'amount') result += `$${data.value}`;
    else throw new Error('invalid kind');

    if (data.minDep) result += `; min dep: ${data.minDep}`;
    if (data.timer) result += `; timer: ${Duration.fromMillis(data.timer).toFormat('hh:mm:ss')}`;
    return result;
  } else if (type === PromoType.balance) {
    return `$${data.amount}`;
  } else if (type === PromoType.skin) {
    return `${data.skin.id} ($${data.cost})`;
  } else throw new Error('invalid type');
};

export const List = (): JSX.Element => {
  const [{ data }] = useSuss();
  return useMemo(() => {
    return (
      <div className="flex flex-col gap-2s">
        {data.listMapV((data) => {
          return <PromoCard data={data} key={data.code} />;
        })}
      </div>
    );
  }, [data]);
};

type PromoCardProps = {
  data: Rsa;
};

const PromoCard = ({ data }: PromoCardProps): JSX.Element => {
  return (
    <div
      onClick={() => {
        chronicle.push(`/promo/${data.code}`);
      }}
      className="std-paper p4sa cursor-pointer"
    >
      <Dump data={data} />
    </div>
  );
};
