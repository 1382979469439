import {useQuePropReplace} from 'packs/libs/Que';
import {useApiReq} from 'packs/libs/api';
import {StdTabSwitch} from 'packs/std';
import {QuestChapterPeriod, QuestChapterPeriodTitleEntries} from 'domain/quest/quest.defs';
import {QuestChapterTemplate} from 'app/quests/template/QuestChapterTemplate';

type ChapterData = { quests: ChapterQuestData[] }
type ChapterQuestData = {
  code: string
  options: Rsa
}

export function QuestsTemplatesArea() {
  const [period = QuestChapterPeriod.day, setPeriod] =
    useQuePropReplace<QuestChapterPeriod>('period');

  const quests = useApiReq('quest.template.quest.all');

  return (
    <div>
      <StdTabSwitch
        type="b"
        value={period}
        className={'p4sy'}
        onChange={setPeriod}
        tabs={QuestChapterPeriodTitleEntries}
      />
      <QuestChapterTemplate
        key={period}
        period={period}
        quests={quests}
      />
    </div>
  );
}


