import { apiReq } from 'packs/libs/api';
import { Pile } from 'packs/single/pile';
import { StdKindButton, StdPage, StdTabSwitch } from 'packs/std';
import { ReactNode, useState } from 'react';
import { useLaunch } from 'support/react/use-launch';

enum CrushMode {
  normal = 'normal',
  extreme = 'extreme',
}

export function CrushModesPage() {
  const [mode, setMode] = useState<any>(() => 'normal');
  const [config, setConfig] = useState<any>();

  useLaunch(async () => {
    setConfig(
      await apiReq({
        action: 'crush.mode.config.get',
        body: { mode },
      })
    );
  }, [mode]);

  const composeContent = (): ReactNode => {
    if (!config) return;

    return (
      <div className="flex flex-col gap-4s p4sy w70s">
        <Pile value={config} onChange={setConfig} />
        <StdKindButton
          children="Save"
          onClick={() => {
            apiReq({
              action: 'crush.mode.config.put',
              body: { mode, config },
              success: true,
            });
          }}
        />
        {/* {ENV_SANDBOX && <CrushModeSetExactCef />}
        <Bots />
        <ReloadFlowProcess mode={mode} /> */}
      </div>
    );
  };

  return (
    <StdPage title={'Crush Modes'}>
      <StdTabSwitch
        value={mode}
        onChange={setMode}
        tabs={[
          [CrushMode.normal, 'Normal'],
          [CrushMode.extreme, 'Extreme'],
        ]}
      />
      {composeContent()}
    </StdPage>
  );
}

// function CrushModeSetExactCef() {
//   const { config } = useCrushModeState();
//   const [value, setValue] = useForkState<number>(config.x.exact);

//   return (
//     <StdPaper className={'p4sa'}>
//       <div className={'tg-title'}>Exact Cef</div>
//       <StdNumberInput className={'w60s m4sy'} value={value} onChange={setValue} />
//       <StdKindButton
//         className={'m1sr'}
//         children={'clear'}
//         disabled={value === undefined}
//         kind={'ah'}
//         onClick={() => {
//           setValue(undefined);
//           getCrushModesOperator().iupConfig({
//             x: { $unset: ['exact'] },
//           });
//         }}
//       />
//       <StdKindButton
//         children={'save'}
//         onClick={() => {
//           getCrushModesOperator().iupConfig({
//             x: { exact: { $set: value } },
//           });
//         }}
//       />
//     </StdPaper>
//   );
// }

// function Bots() {
//   const [bots, op] = useLensConfig('shenanigan');

//   return useMemo(() => {
//     const { enabled } = bots;

//     return (
//       <div className="std-paper p4sa flex flex-col gap-4s">
//         <div className="tg-subheader">Bots</div>
//         <div className="w20s">
//           <StdButtonSwitch
//             value={enabled}
//             falsy={'disabled'}
//             truly={'enabled'}
//             onChange={(enabled) => {
//               op.updState({ enabled });
//             }}
//           />
//         </div>
//       </div>
//     );
//   }, [bots]);
// }

// type ReloadFlowProcessProps = {
//   mode: CrushMode;
// };

// function ReloadFlowProcess({ mode }: ReloadFlowProcessProps) {
//   return (
//     <div className="std-paper p4sa">
//       <StdKindButton
//         kind="a"
//         children="Reload Process"
//         onClick={() => {
//           fancyConfirmation({
//             async onOk() {
//               await apiReq({
//                 action: 'crush.reload-flow',
//                 success: true,
//                 body: mode,
//               });
//             },
//           });
//         }}
//       />
//     </div>
//   );
// }
