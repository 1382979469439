import { AntiFraudJunction } from 'app/anti-fraud/junction';
import { CaseJunction } from 'app/cases/junction';
import { ChatConfigPage } from 'app/chat/config-page';
import { ContestJunction } from 'app/contest/junction';
import { CrushModesPage } from 'app/crush/modes/CrushModesPage';
import { EmployeesJunction } from 'app/employees/junction';
import { EmployeeProfilePage } from 'app/employees/profile/EmployeeProfilePage';
import { GiveawayJunction } from 'app/giveaway/junction';
import { AdminNavigationConfig } from 'app/layout/menu/AdminMenu';
import { LeaderboardJunction } from 'app/leaderboard/junction';
import { MajorConfigPage } from 'app/major-config/major-config';
import { PaymentJunction } from 'app/payment/junction';
import { PlayerJunction } from 'app/player/junction';
import { PromoJunction } from 'app/promo/junction';
import { OauthAuthed } from 'app/public/oauth';
import { QuestsPage } from 'app/quests/QuestsPage';
import { UpgradeJunction } from 'app/upgrade/junction';
import { WithdrawalJunction } from 'app/withdrawal/junction';
import stdLoadable from 'packs/std/loadable';
import React from 'react';

export const adminRoutes: AdminNavigationConfig = [
  {
    title: 'Contest',
    access: 'contest.read',
    component: ContestJunction,
    path: 'contests',
  },
  {
    title: 'Crash',
    access: 'crush.write',
    component: CrushModesPage,
    path: 'crush/modes',
  },
  {
    title: 'Quests',
    access: 'crush.quest.read',
    component: QuestsPage,
    path: 'quests',
  },
  // {
  //   title: 'Crash Sets',
  //   access: 'crush-set.read',
  //   component: CrushSetsPage,
  //   path: 'crush-sets',
  // },
  {
    title: 'Chat',
    access: 'chat.write',
    component: ChatConfigPage,
    path: 'chat',
  },
  {
    title: 'Upgrade',
    access: 'upgrade.read',
    component: UpgradeJunction,
    path: 'upgrade',
  },
  {
    title: 'Withdrawal',
    access: 'payment.read',
    component: WithdrawalJunction,
    path: 'withdrawal',
  },
  {
    title: 'Payment',
    access: 'payment.read',
    component: PaymentJunction,
    path: 'payment',
  },
  {
    title: 'Promo',
    access: 'promo.read',
    component: PromoJunction,
    path: 'promo',
  },
  // {
  //   title: 'Status',
  //   access: 'admin',
  //   component: StatusPage,
  //   path: 'status',
  // },
  {
    title: 'Cases',
    component: CaseJunction,
    path: 'cases',
  },
  {
    title: 'Giveaway',
    component: GiveawayJunction,
    path: 'giveaway',
  },
  {
    title: 'Leaderboard',
    component: LeaderboardJunction,
    path: 'leaderboard',
  },
  {
    title: 'Players',
    component: PlayerJunction,
    path: 'players',
  },
  {
    title: 'Global Config',
    component: MajorConfigPage,
    path: 'major-config',
  },
  {
    title: 'Employees',
    component: EmployeesJunction,
    path: 'employee',
  },
  {
    title: 'AntiFraud',
    component: AntiFraudJunction,
    path: 'anti-fraud',
  },
];

if (__DEV__) {
  // adminRoutes.push({
  //   title: 'Cockpit',
  //   component: stdLoadable(() => import('app/cockpit/cockpit')),
  //   path: 'cockpit',
  // });
  adminRoutes.push({
    title: 'Develop',
    component: stdLoadable(() => import('app/develop')),
    path: 'develop',
  });
}

export const adminAdditionalRoutes: [string, React.ComponentType][] = [
  ['/profile', EmployeeProfilePage],
  ['/oauth', OauthAuthed],
];
