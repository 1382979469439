import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { LeaderboardConfigData } from 'app/leaderboard/definitions';
import { SkinCard } from 'domain/skin/card';
import { openSkinSearchDialog } from 'domain/skin/search';
import { apiReq } from 'packs/libs/api';
import { useSwissState } from 'packs/state/swiss-store';
import { StdFieldBox, StdKindButton, StdNumberInput } from 'packs/std';
import { StdButtonBool } from 'packs/std/inputs/std.button-bool';
import { useLaunch } from 'support/react/use-launch';

export const LeaderboardConfigPage = (): JSX.Element => {
  const [config, op] = useSwissState<LeaderboardConfigData>(null);

  useLaunch(async () => {
    op.setState(
      await apiReq({
        action: 'leaderboard.config.get',
      })
    );
  }, []);

  if (!config) return null;

  return (
    <div className="flex flex-col gap-4s std-paper p4sa">
      <StdButtonBool
        value={config.enabled}
        onChange={(enabled) => {
          op.updState({ enabled });
        }}
      />
      <div className="flex flex-wrap gap-4s">
        {config.prize.map(({ skin, cost }, i) => {
          return (
            <div className="flex">
              <div className="flex flex-col gap-1s">
                <div className="tg-title">{i + 1}.</div>
                <EditIcon
                  className="cursor-pointer"
                  onClick={(e) => {
                    openSkinSearchDialog({
                      submit: ({ id, image, price, color }) => {
                        op.mutState((draft) => {
                          draft.prize[i] = { skin: { id, image, color }, cost: price };
                        });
                      },
                    });
                  }}
                />
                <DeleteOutlineIcon
                  className="cursor-pointer"
                  onClick={() => {
                    op.mutState((draft) => {
                      draft.prize = draft.prize.filter((_, key) => key !== i);
                    });
                  }}
                />
              </div>
              <div>
                <SkinCard data={skin} />
                <StdFieldBox title="Cost" className="w40s">
                  <StdNumberInput
                    value={cost}
                    onChange={(value) => {
                      op.mutState((draft) => {
                        draft.prize[i].cost = value;
                      });
                    }}
                  />
                </StdFieldBox>
              </div>
            </div>
          );
        })}
        <div>
          <StdKindButton
            children="add item"
            onClick={(e) => {
              openSkinSearchDialog({
                submit: ({ id, image, price, color }) => {
                  op.mutState((draft) => {
                    draft.prize.push({ skin: { id, image, color }, cost: price });
                  });
                },
              });
            }}
          />
        </div>
      </div>
      <div>
        <StdKindButton
          children="Save"
          onClick={() => {
            apiReq({
              action: 'leaderboard.config.set',
              body: config,
              success: true,
            });
          }}
        />
      </div>
    </div>
  );
};
