import { css, cx } from '@emotion/css';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type StdInnerLinkProps = {
  to: string;
  className?: string;
  children: ReactNode;
  title?: string;
  onClick?(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
};

export const StdLink = ({ children, className, ...props }: StdInnerLinkProps): JSX.Element => {
  return (
    <Link className={cx(mainClass, className)} {...props}>
      {children}
    </Link>
  );
};

const mainClass = css`
  color: var(--dim-blue-400);
`;
