import { useCribField } from 'packs/crib/store';
import { CribErrorBox } from 'packs/crib/units/error-box';
import { StdInput } from 'packs/std';
import { useEffect } from 'react';
import { useSwissState } from 'support/react/swiss';
import { z } from 'zod';

type CribCsvFieldProps = {
  name: string;
  offset?: number;
};

const join = (parts: any[]): string => {
  if (!parts) return '';
  return parts.join(', ');
};

const split = (line: string): number[] => {
  return line.split(',').map((val) => {
    const num = parseFloat(val);
    if (isNaN(num)) return null;
    return num;
  });
};

export const CribCsvField = (props: CribCsvFieldProps): JsxElement => {
  const [field, fieldOp] = useCribField(() => {
    const opts: Rsa = { name: props.name, initialValue: [] };
    opts.schema = z.array(z.number().positive()).optional();
    return opts;
  });

  const [state, op] = useSwissState<{ line: string; value: number[] }>(() => ({
    line: join(field.value),
    value: field.value,
  }));

  useEffect(() => {
    const lens = fieldOp.context.lens(props.name);
    return fieldOp.context.observers.sub(() => {
      const cribValue = lens.get() as number[];
      if (cribValue !== op.getState().value) {
        op.setState({ value: cribValue, line: join(cribValue) });
      }
    });
  }, []);

  const offset = props.offset ?? 0;
  return (
    <div>
      <StdInput
        value={state.line}
        onChange={(e) => {
          const line = e.target.value;

          const value = split(line);

          op.setState({
            line,
            value,
          });

          fieldOp.setValue(value);
        }}
      />
      <CribErrorBox error={field.error} />
      <ul>
        {state.value.map((val, i) => {
          const i2 = i + offset;
          return (
            <li key={`${i}.${val}`}>
              <span className="p2sr">{i2}.</span>
              <span>{isNaN(val) ? null : `${val}$`}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

// export const PrizeWatcher = (): JSX.Element => {
//   const prize = useCribWatch<string>('prize');
//   if (!prize) return null;
//   const parts = prize.split(',');
//   const children = [];
//   let i = 0;
//   for (const part of parts) {
//     ++i;
//     const num = parseFloat(part);
//     children.push(
//       <li key={`${i}.${num}`}>
//         <span className="p2sr">{i}.</span>
//         <span>{isNaN(num) ? null : `${num}$`}</span>
//       </li>
//     );
//   }
//   return <ul>{children}</ul>;
// };
