import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { useActor } from 'domain/actor';

export function HeaderAccountBlock() {
  const actor = useActor();
  return (
    <div className={'df aic'}>
      <Link to={'/profile'} className={'p2sx'}>
        {actor.email}
      </Link>
      <LogoutIcon
        onClick={actor.logout}
      />
    </div>
  );
}

const LogoutIcon = styled(ExitToAppIcon)`
  cursor: pointer;
  color: var(--dark-blue-100);
`;
