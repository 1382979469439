import { useCribField } from 'packs/crib/store';
import { CribErrorBox } from 'packs/crib/units/error-box';
import { StdCheckbox } from 'packs/std/inputs/checkbox';
import { ReactNode } from 'react';
import execIfFn from 'support/execIfFn';
import { ZodTypeAny } from 'zod';

type CribCheckBoxListFieldProps = {
  name: string | number;
  options: readonly [string | number, ReactNode][];
  schema?: OrFactory<ZodTypeAny>;
};

export const CribCheckBoxListField = ({
  name,
  options,
  schema,
}: CribCheckBoxListFieldProps): JsxElement => {
  const [{ value: current, error }, op] = useCribField(() => {
    return { name, schema: execIfFn(schema), initialValue: [] };
  });

  return (
    <div className="flex flex-col gap-2s">
      {options.map(([value, label]) => {
        return (
          <div className="flex gap-2s">
            <StdCheckbox
              checked={current.includes(value)}
              onChange={(checked) => {
                op.mutValue((draft) => {
                  if (checked) {
                    draft.push(value);
                  } else {
                    return draft.filter((v) => v !== value);
                  }
                });
              }}
            />
            <div className="tg-label1">{label}</div>
          </div>
        );
      })}
      <CribErrorBox error={error} />
    </div>
  );
};
