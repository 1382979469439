import { stashGet } from 'support/memory/stash';

class SkinNameView {
  // M4A1-S | Master Piece (Minimal Wear)
  constructor(public readonly id: string) {}

  @stashGet
  // M4A1-S
  get model(): string {
    return this.id.split('|')[0].trim();
  }

  @stashGet
  // Master Piece
  get brand(): string {
    const tmp = this.id.split('|')[1];
    if (!tmp) return this.id;
    return tmp.replace(/\(.*\)/, '').trim();
  }

  @stashGet
  // M4A1-S | Master Piece
  get std() {
    return this.id.replace(/\(.*\)/, '').trim();
  }

  // Minimal Wear
  // get wearRating() {
  //   throw 'not-implemented'
  // }
}

const sym = Symbol();

export const skinNameViewOnSkin = (skin: { id: string }): SkinNameView => {
  return (skin[sym] ??= new SkinNameView(skin.id));
};

export const skinNameView = (id: string) => new SkinNameView(id);
