import {SussProvider} from 'packs/libs/Suss';
import {apiReq} from 'packs/libs/api';
import {Stable, StableHead, StableSortHead} from 'packs/blocks/suss-table';
import {memo} from 'react';
import {DateLine} from 'packs/std/lines/DateLine';
import {QuartzFooter} from 'packs/blocks/quartz/QuartzFooter';
import {QuartzTabFilter} from 'packs/blocks/quartz/QuartzTabFilter';
import {QuestChapterPeriod, QuestChapterPeriodTitleEntries} from 'domain/quest/quest.defs';
import {QuartzFilterZone} from 'packs/blocks/quartz';

const request = (query, context) => apiReq({
  action: 'quest.chapter.search',
  body: {...query, ...context},
  });

export function QuestsChapterSearchArea() {
  const periodOptions: any = [[undefined, 'All'], ...QuestChapterPeriodTitleEntries];
  return (
    <SussProvider request={request}>
      <div className="w200s">
        <QuartzTabFilter
          type={'b'}
          className={'p4sy'}
          name={'period'}
          options={periodOptions}
        />
        <QuartzFilterZone qInput={'id'}/>
        <Stable Row={Item} className={'m2sy'}>
          <StableHead>Period</StableHead>
          <StableSortHead name={'startAt'}>
            Start
          </StableSortHead>
          <StableSortHead name={'finishAt'}>
            Finish
          </StableSortHead>
          <StableSortHead name={'reward'}>
            Reward
          </StableSortHead>
        </Stable>
        <QuartzFooter/>
      </div>
    </SussProvider>
  );
}

const periodTitleMap = new Map(QuestChapterPeriodTitleEntries);

type QuestChapterData = {
  id: string
  period: QuestChapterPeriod
  reward: number
  startAt: number
  finishAt: number
}

type ItemProps = {
  data: QuestChapterData
}
const Item = memo(
  function Item({
    data: {
      id, reward, period,
      startAt, finishAt,
    },
  }: ItemProps) {
    return (
      // <tr className={cc({past: finishAt < Date.now()})}>
      <tr>
        <td>{periodTitleMap.get(period)}</td>
        <td><DateLine t={startAt}/></td>
        <td><DateLine t={finishAt}/></td>
        <td>{reward}$</td>
      </tr>
    );
  },
);
