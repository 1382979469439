import { ContestForm } from 'app/contest/form';
import { chronicle } from 'fnd/bridge/router';
import { CloudFile } from 'packs/cloud';
import { apiReq } from 'packs/libs/api';
import { fancyConfirmation } from 'packs/single/fancy-confirmation';
import { StdButtonSwitch, StdKindButton } from 'packs/std';
import { useParams } from 'react-router-dom';
import { useSwissState } from 'support/react/swiss';
import { useLaunch } from 'support/react/use-launch';

export const ContestTemplateAddPage = (): JsxElement => {
  return (
    <ContestForm
      contest={{}}
      template
      submit={async (body, attach) => {
        const { id } = await apiReq({
          action: 'contest.template.add',
          success: true,
          body,
          attach,
          progress: true,
        });

        chronicle.push('/contests/templates/' + id);
      }}
    />
  );
};

export const ContestTemplateEditPage = (): JsxElement => {
  const { templateId } = useParams<{ templateId: string }>();

  const [data, op] = useSwissState(null);

  useLaunch(async () => {
    const data = await apiReq({ action: 'contest.template.get', query: { id: templateId } });
    op.setState({
      ...data,
      image: CloudFile.fromUrl(`${ENV_CLOUD_MEDIA_URL}/contest-template/${data.id}`),
    });
  }, []);

  if (data === null) return null;

  const purgeContests = async () => {
    await apiReq({
      action: 'contest.template.cancel-contests',
      body: { id: data.id },
      success: true,
    });
  };

  return (
    <div className="flex flex-col gap-4s">
      <div className="std-paper p4sa flex flex-col gap-4s w100s">
        <div className="tg-caption1">{data.id}</div>
        <StdButtonSwitch
          value={data.enabled}
          onChange={() => {
            apiReq({
              action: 'contest.template.upd.enabled',
              body: { id: data.id, value: !data.enabled },
              success: true,
            });
            op.updState({ enabled: !data.enabled });
          }}
        />

        <StdKindButton
          kind="eh"
          children="delete template"
          onClick={async () => {
            fancyConfirmation({
              about: (
                <StdKindButton
                  children={'cancel all template contests first'}
                  onClick={purgeContests}
                />
              ),
              async onOk() {
                await apiReq({
                  action: 'contest.template.del',
                  body: { id: data.id },
                  success: true,
                });

                chronicle.push('/contests/templates');
              },
            });
          }}
        />

        <StdKindButton
          kind="eh"
          children={'cancel all template contests'}
          onClick={() => {
            fancyConfirmation({
              async onOk() {
                await purgeContests();
              },
            });
          }}
        />
      </div>

      <ContestForm
        template
        contest={data}
        submit={async (body, attach) => {
          await apiReq({
            action: 'contest.template.upd',
            success: true,
            body,
            attach,
            progress: true,
          });
        }}
      />
    </div>
  );
};
