import { useCribField } from 'packs/crib/store';
import { CribErrorBox } from 'packs/crib/units/error-box';
import { SwitchSelect } from 'packs/std';
import { z, ZodTypeAny } from 'zod';

type CribSimpleSwitchSelectProps = {
  name: string;
  options: string[];
  schema?: OrFactory<ZodTypeAny>;
  initialValue?: string;
};

export const CribSimpleSwitchSelect = (props: CribSimpleSwitchSelectProps): JSX.Element => {
  const [{ value, error }, op] = useCribField(() => {
    return {
      name: props.name,
      schema: z.enum(props.options as any),
      initialValue: props.initialValue,
    };
  });
  return (
    <div>
      <SwitchSelect
        value={value}
        options={props.options}
        onChange={(value) => {
          op.setValue(value);
          op.context.lens<any>('data').set(undefined);
        }}
      />
      <CribErrorBox error={error} />
    </div>
  );
};
