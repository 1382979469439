import { PlayerAntiFraudPage } from 'app/player/details/anti-fraud';
import { PlayerRestrictionsBlock } from 'app/player/details/player-restrictions-block';
import { PlayerQuests } from 'app/player/details/quests/quests';
import { PlayerDetailsRewardBlock } from 'app/player/details/reward-block';
import { PlayerDetailsStoreProvider, usePlayerDetailsStore } from 'app/player/details/store';
import { PlayerSummary } from 'app/player/details/summary/summary';
import { PlayerTransfers } from 'app/player/details/transfers';
import { PlayerUpgradePage } from 'app/player/details/upgrade-page';
import { PlayerDetailsWithdrawals } from 'app/player/details/withdrawals';
import { AccessGuard } from 'domain/actor/access-guard';
import { PlayerImage } from 'domain/player/image';
import { chronicle } from 'fnd/bridge/router';
import { apiReq } from 'packs/libs/api';
import { fancyConfirmation } from 'packs/single/fancy-confirmation';
import { StdKindButton, StdOuterLinkIcon, StdTabJunction } from 'packs/std';

type PlayerDetailsPageProps = {};

export const PlayerDetailsPage = ({}: PlayerDetailsPageProps) => {
  return (
    <PlayerDetailsStoreProvider>
      <Content />
    </PlayerDetailsStoreProvider>
  );
};

const Content = (): JSX.Element => {
  const [data] = usePlayerDetailsStore();
  return (
    <div className="flex gap-4s">
      <div className="flex flex-col gap-4s w40s">
        <div>
          <PlayerImage className="h40s rounded-md" code={data.image} />
        </div>
        <PlayerRestrictionsBlock playerId={data.id} />

        <AccessGuard access="player.admin">
          <PlayerDetailsRewardBlock playerId={data.id} />
        </AccessGuard>

        <DeletePlayerBlock playerId={data.id} />
      </div>
      <div className="flex flex-col gap-4s">
        <StdTabJunction
          routes={[
            ['summary', <PlayerSummary />],
            ['transfers', <PlayerTransfers playerId={data.id} />],
            ['withdrawals', <PlayerDetailsWithdrawals playerId={data.id} />],
            ['upgrades', <PlayerUpgradePage playerId={data.id} />],
            ['quests', <PlayerQuests playerId={data.id} />],
            ['anti-fraud', <PlayerAntiFraudPage playerId={data.id} />],
          ]}
        />
      </div>
    </div>
  );
};

export const DeletePlayerBlock = ({ playerId }: { playerId: string }): JSX.Element => {
  return (
    <AccessGuard access="player.admin">
      <div className="p2sa std-paper flex flex-col gap-2s">
        <StdKindButton
          kind="ah"
          onClick={async () => {
            window.open(
              await apiReq({ action: 'player.auth.create-pwa-url', query: { playerId } }),
              '_blank'
            );
          }}
        >
          Site <StdOuterLinkIcon />
        </StdKindButton>
        <StdKindButton
          children="Delete"
          kind="danger-h"
          onClick={() => {
            fancyConfirmation({
              about: 'This will delete the player and all his data.',
              async onOk() {
                await apiReq({
                  progress: true,
                  action: 'player.del',
                  body: { playerId },
                });
                chronicle.push('/players');
              },
            });
          }}
        />
      </div>
    </AccessGuard>
  );
};
