import {EmployeeRole} from 'domain/employee/role/employee-role.defs';
import {useEffect, useState} from 'react';
import {StdSelect} from 'packs/std';
import {useField} from 'formik';
import {FmkError} from 'packs/fmk';
import {fetchEmployeeRolesList} from 'domain/employee/role/employee-role.api';

type SelectEmployeeRoleProps = {
  value: EmployeeRole
  onChange(value?: EmployeeRole): void
}

export function SelectEmployeeRole({
  value, onChange,
}: SelectEmployeeRoleProps) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    (async () => {
      const roles = await fetchEmployeeRolesList();

      setOptions(roles);
    })();
  }, []);

  return (
    <StdSelect
      options={options}
      value={value}
      onChange={onChange}
      getOptionLabel={role => role.name}
    />
  );
}

type SelectEmployeeRoleFieldProps = {
  name: string
}

export function SelectEmployeeRoleField({
  name,
}: SelectEmployeeRoleFieldProps) {
  const [, {value, error, touched}, {setTouched, setValue}] = useField(name);

  return (
    <div>
      <SelectEmployeeRole
        value={value}
        onChange={value => {
          setTouched(true);
          setValue(value);
        }}
      />
      <FmkError>{error && touched}</FmkError>
    </div>
  );
}
