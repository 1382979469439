import { QuestsDashEarnedSum } from 'app/quests/dash/quests.dash.earned-sum';
import { QuestDashOneDump } from 'app/quests/dash/quests.dash.one-dump';
import { AccessGuard } from 'domain/actor/access-guard';
import { QuestChapterPeriod } from 'domain/quest/quest.defs';
import { infSuccess } from 'fnd/inf/Inf';
import { apiReq } from 'packs/libs/api';
import { StdKindButton } from 'packs/std';

export function QuestsDashArea() {
  return (
    <div>
      <div className="std-paper p4sa w-min">
        <StdKindButton
          kind="ah"
          children="Check Rotation"
          onClick={() => {
            apiReq<QuestChapterPeriod[]>({
              action: 'quest.chapter.check-rotation',
                          }).then((periods) => {
              if (periods.length === 0) {
                infSuccess('all periods are filled by sets');
              } else {
                infSuccess(`new periods created: ${periods.join(' ')}`);
              }
            });
          }}
        />
      </div>
      <div className="p4sy flex gap-4s">
        <QuestsDashEarnedSum />
        <AccessGuard access={'admin'}>
          <QuestDashOneDump />
        </AccessGuard>
      </div>
    </div>
  );
}
