import styled, { keyframes } from 'styled-components';
import { cc } from 'fnd/bridge/react';
import { useProgress } from 'packs/libs/Progress';

export function ProgressLine() {
  const active = useProgress();
  return <MainDiv className={cc({ active })} />;
}

const slide = keyframes`
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 100%;
  }
`;

const MainDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;

  &.active {
    background: linear-gradient(
        90deg,
        rgba(243, 243, 249, 1) 0%,
        rgba(255, 112, 112, 1) 14%,
        rgba(255, 191, 112, 1) 24%,
        rgba(251, 255, 133, 1) 33%,
        rgba(153, 255, 133, 1) 43%,
        rgba(92, 241, 255, 1) 53%,
        rgba(112, 167, 255, 1) 65%,
        rgba(134, 112, 255, 1) 76%,
        rgba(255, 133, 255, 1) 86%,
        rgba(243, 243, 249, 1) 100%
      )
      0% 0% / 1200%;
    animation: 2s linear 0s infinite normal none running ${slide};
  }
`;
