import { PromoMod } from 'app/promo/form';
import omitNil from 'fnd/support/omitNil';
import { apiReq } from 'packs/libs/api';
import { fancyConfirmation } from 'packs/single/fancy-confirmation';
import { StdKindButton } from 'packs/std';
import { StdDateTimeInput } from 'packs/std/inputs/date';
import { useParams } from 'react-router-dom';
import { useSwissState } from 'support/react/swiss';
import { useLaunch } from 'support/react/use-launch';

export const PromoDetails = (): JSX.Element => {
  const { code } = useParams<Rsa>();
  const [{ data, formData }, op] = useSwissState<Rsa>({});
  const loadData = async () => {
    const data = await apiReq({
      action: 'promo.get',
      progress: true,
      query: { code },
          });
    const formData = {
      ...omitNil(data),
      expiresAt: new Date(data.expiresAt),
    };
    console.log(data);
    op.setState({ data, formData });
  };

  useLaunch(loadData, [code]);

  if (!data) return null;

  return (
    <div className="flex flex-col gap-4s ">
      <div className="flex gap-4s">
        <span className="std-paper p4sa">
          <StdKindButton
            children="close"
            kind="danger"
            onClick={async () => {
              fancyConfirmation({
                onOk: async () => {
                  await apiReq({
                    action: 'promo.close',
                    query: { code },
                    success: true,
                  });
                  loadData();
                },
              });
            }}
          />

          {/* <StdKindButton
          children="archive"
          kind="ah"
          onClick={async () => {
            fancyConfirmation({
              onOk: async () => {
                await apiReq({
                  action: 'promo.close',
                  query: { code },
                  success: true,
                });
                loadData();
              },
            });
          }}
        /> */}
        </span>
      </div>
      <div>
        <PromoMod
          key={data.code}
          initialData={formData}
          submit={async (data) => {
            await apiReq({
              action: 'promo.upd',
              progress: true,
              body: data,
              success: true,
            });
            await loadData();
          }}
        />
      </div>
    </div>
  );
};
