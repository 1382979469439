import { AccessGuard } from 'domain/actor/access-guard';
import { apiReq } from 'packs/libs/api';
import { fancyConfirmation } from 'packs/single/fancy-confirmation';
import { StdKindButton } from 'packs/std';

export enum QuestSingleCode {
  // STARTER
  subscribeSocialNetwork = 'subscribe.social-network',
  subscribeBlog = 'subscribe.blog',
  subscribeDiscord = 'subscribe.discord',
  crushNormalPlayRoundsOnce = 'crush.normal.play-rounds-once',
  giveawayTakePart = 'giveaway.take-part',
  learnProvablyFair = 'learn.provably-fair',
  depositOnce = 'deposit.once',
  withdrawOnce = 'withdraw.once',
}
const STARTER_QUESTS_CODES = [
  QuestSingleCode.depositOnce,
  QuestSingleCode.crushNormalPlayRoundsOnce,
  QuestSingleCode.withdrawOnce,
  QuestSingleCode.giveawayTakePart,
  QuestSingleCode.learnProvablyFair,
  QuestSingleCode.subscribeDiscord,
  QuestSingleCode.subscribeBlog,
  QuestSingleCode.subscribeSocialNetwork,
];

type QuestPlayerComplementBlockProps = {
  playerId: string;
};

export const PlayerSingleQuestsBlock = ({
  playerId,
}: QuestPlayerComplementBlockProps): JSX.Element => {
  return (
    <AccessGuard access="player.admin">
      <SingleQuests playerId={playerId} />
    </AccessGuard>
  );
};

const SingleQuests = ({ playerId }): JSX.Element => {
  return (
    <div className="std-paper p4sa flex flex-col gap-4s">
      <div className="tg-title">Starter Quests</div>
      <div className="flex justify-between gap-4s">
        <StdKindButton
          kind="danger-h"
          children={`reset all`}
          onClick={() => {
            fancyConfirmation({
              onOk() {
                apiReq({
                  action: 'quest.hack.single.clear',
                  success: true,
                  body: { playerId },
                });
              },
            });
          }}
        />
        <StdKindButton
          children="complete all"
          onClick={() => {
            fancyConfirmation({
              onOk() {
                apiReq({
                  action: 'quest.hack.advance.batch',
                  body: STARTER_QUESTS_CODES.map((code) => {
                    return { code, playerId, advance: 100 };
                  }),
                  success: true,
                });
              },
            });
          }}
        />
      </div>
      {/* <div>
        <StdKindButton
          children="advance all"
          onClick={() => {
            apiReq({ action: 'quest.single.advance-all', success: true,body: { playerId } });
          }}
        />
      </div> */}
      <div className="flex flex-col gap-4s">
        {STARTER_QUESTS_CODES.map((code) => {
          return (
            <div key={code} className="flex justify-between items-center gap-4s">
              {code}{' '}
              <StdKindButton
                kind="ah"
                children="complete"
                onClick={() => {
                  apiReq({
                    action: 'quest.hack.advance',
                    body: { code, playerId, advance: 100 },
                    success: true,
                  });
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
