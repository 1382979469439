import {DateTime, DateTimeFormatOptions} from 'luxon';
import React, {memo} from 'react';
import {juxProp} from 'fnd/juxtapose';

export enum DateFormat {
  stdDateMin = 'yyyy-LL-dd HH:mm',
  stdDateSec = 'yyyy-LL-dd HH:mm:ss'
}

type DateLineProps = {
  t: number | null;
  f?: string;
  ls?: DateTimeFormatOptions;
  or?: React.ReactChild;
}
export const DateLine = memo(
  function DateLine({
    t, f, ls, or = null,
  }: DateLineProps) {
    if (!t) {
      return <>{or}</>;
    }

    const dt = DateTime.fromMillis(t).toUTC();

    if (f) return <>{dt.toFormat(f)}</>;

    if (ls) return <>{dt.toLocaleString(ls)}</>;

    return <>{dt.toFormat(DateFormat.stdDateMin)}</>;
  }, juxProp('t'),
);
