import { el } from 'fnd/bridge/react';
import { debounce } from 'packs/deps';
import { useSuss } from 'packs/libs/Suss';
import { StdInput, StdInputProps } from 'packs/std';
import { useMemo } from 'react';

type QuartzQInputProps = StdInputProps;

export function QuartzQInput(props: QuartzQInputProps) {
  const suss = useSuss();
  const onChange = useMemo(() => {
    const name = props.name ?? 'q';
    const update = debounce((value: string) => {
      if (value === '') {
        suss[1].mutQuery((draft) => {
          delete draft[name];
        });
      } else {
        suss[1].updQuery({ [name]: value });
      }
    }, 200);

    return (e) => {
      update(e.target.value);
    };
  }, []);

  return el(StdInput, { onChange, ...props });
}
