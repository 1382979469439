import { SkinCard } from 'domain/skin/card';
import { openSkinSearchDialog } from 'domain/skin/search';
import { CribErrorBox, CribNumberInputField, useCribField } from 'packs/crib';
import { FmkLazyError } from 'packs/fmk';
import { StdFieldBox, StdKindButton } from 'packs/std';
import { z, ZodTypeAny } from 'zod';

type SkinCostCribFieldProps = {
  name: string;
  required?: boolean;
};

export const SkinCostCribField = (props: SkinCostCribFieldProps): JSX.Element => {
  const [{ value, error }, op] = useCribField(() => {
    let schema: ZodTypeAny = z.object({
      skin: z.any(),
      cost: z.number().positive(),
    });
    if (!props.required) schema = schema.optional();
    return {
      name: props.name,
      schema,
    };
  });

  const compose = () => {
    const selectSkin = () => {
      openSkinSearchDialog({
        submit(skin) {
          console.log(skin);
          op.setValue({
            skin: {
              id: skin.id,
              color: skin.color,
              image: skin.image,
            },
            cost: skin.price,
          });
        },
      });
    };

    if (!value) {
      return <StdKindButton onClick={selectSkin} children="select skin" />;
    }

    const costName = `${props.name}.cost`;

    return (
      <div className="flex flex-col gap-4s">
        <SkinCard data={value.skin} onClick={selectSkin} />
        <StdFieldBox title="Cost $">
          <CribNumberInputField name={costName} />
        </StdFieldBox>
        <CribErrorBox error={error} />
      </div>
    );
  };

  return (
    <div>
      {compose()}
      <FmkLazyError name={'data.skin'} />
    </div>
  );
};
