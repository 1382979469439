import { CloudFile } from 'packs/cloud';
import { CloudImage, CloudImageConfig } from 'packs/cloud/image';
import { CribErrorBox, useCribField } from 'packs/crib';
import { z, ZodTypeAny } from 'zod';

type CribCloudImageConfig = CloudImageConfig & {};

type CribCloudImageProps = {
  config?: CribCloudImageConfig;
  ratio?: number;
  className?: string;
  name: string;
  required?: boolean;
};

export const CribCloudImageField = (props: CribCloudImageProps): JSX.Element => {
  const [{ value, error }, op] = useCribField(() => {
    let schema: ZodTypeAny = z.any();
    // .refine(
    //   async (v) => {
    //     return v ? v.checkValid() : true;
    //   },
    //   { message: 'Image is not valid' }
    // );
    if (props.required) {
      schema = schema.refine((v) => v instanceof CloudFile, {
        message: 'Image is required',
      });
    }

    if (props.ratio) {
      schema = schema.refine(
        async (v) => {
          if (v === undefined) return true;
          const ratio = await v.getAspectRatio();
          return ratio === props.ratio;
        },
        {
          message: `Image aspect ratio must be ${decimalToRatio(props.ratio)}`,
        }
      );
    }
    return { name: props.name, schema };
  });
  return (
    <div className={props.className}>
      <CloudImage value={value} onChange={op.setValue} config={props.config} />
      <CribErrorBox error={error} />
    </div>
  );
};

const decimalToRatio = (decimal: number): string => {
  const fraction = decimalToFraction(decimal);
  const [n1, n2] = fraction.split('/');
  const gcd = findGcd(parseInt(n1), parseInt(n2));
  const ratio = `${parseInt(n1) / gcd}:${parseInt(n2) / gcd}`;
  return ratio;
};

const decimalToFraction = (decimal: number): string => {
  const precision = 10000;
  const numerator = (decimal * precision).toFixed();
  const denominator = precision;
  const gcd = findGcd(parseInt(numerator), denominator);
  const fraction = `${parseInt(numerator) / gcd}/${denominator / gcd}`;
  return fraction;
};

const findGcd = (a: number, b: number): number => {
  return b === 0 ? a : findGcd(b, a % b);
};
