import classNames from 'classnames';
import { juxVoid } from 'fnd/juxtapose';
import { assign } from 'packs/deps';
import {
  createContext,
  createElement,
  DependencyList,
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const el = createElement;
export const cc = classNames;

export function voidMemo(Component) {
  return memo(Component, juxVoid);
}

export function useBool(init = false): [boolean, () => void, () => void] {
  const [bool, setBool] = useState(init);

  return [bool, () => setBool(true), () => setBool(false)];
}

/** @deprecated */
export function makeNullContext<T>(displayName?: string) {
  return assign(createContext<T>(null), { displayName });
}

export function useReverseMemo<T>(deps: DependencyList | undefined, factory: () => T): T {
  return useMemo<T>(factory, deps);
}

/** @deprecated */
export { useLaunch as useJustEffect } from 'support/react/use-launch';

export function useForkState<S>(
  value: S
): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
  const store = useState(value);
  useEffect(() => {
    if (store[0] !== value) store[1](value);
  }, [value]);
  return store;
}
