import { chronicle } from 'fnd/bridge/router';
import { iup } from 'fnd/support/iup';
import { CloudFile } from 'packs/cloud';
import {
  CribInputField,
  CribNumberInputField,
  CribSimpleForm,
  CribSubmitButton,
  useCribWatch,
} from 'packs/crib';
import { CribCloudImageField } from 'packs/crib/fields/cloud-image';
import { CribBoolButtonField } from 'packs/crib/fields/crib-bool-button';
import { CribLangsInputField } from 'packs/crib/fields/langs';
import { apiReq } from 'packs/libs/api';
import { StdFieldBox } from 'packs/std';
import { StdOuterLink } from 'packs/std/navigation/outer-link';
import { useState } from 'react';
import { useLaunch } from 'support/react/use-launch';
import { z } from 'zod';

type PaymentRegionFormProps = {
  submit(body: any, attach: any): void;
  initialData: any;
};

const diverge = (data) => {};

const PaymentRegionForm = ({ submit, initialData }: PaymentRegionFormProps): JSX.Element => {
  const doSubmit = (data) => {
    const { icon, ...rest } = data;
    submit(
      iup(rest, {
        presets: (line) => {
          if (!line) return [];
          return line
            .split(',')
            .map((x) => parseInt(x.trim()))
            .filter((x) => !isNaN(x));
        },
      }),
      [icon]
    );
  };

  return (
    <CribSimpleForm submit={doSubmit} initialData={initialData}>
      <div className="flex flex-col gap-4s std-paper p4sa w100s">
        <CribBoolButtonField name="enabled" />
        <StdFieldBox title="Name">
          <CribLangsInputField name="name" schema={z.string()} />
        </StdFieldBox>
        <StdFieldBox title="Icon">
          <CribCloudImageField name="icon" ratio={1} required />
        </StdFieldBox>
        <StdFieldBox
          title="Currency"
          about={
            <StdOuterLink href="https://en.wikipedia.org/wiki/ISO_4217">ISO_4217</StdOuterLink>
          }
        >
          <CribInputField name="currency" schema={z.string().length(3)} placeholder="USD" />
        </StdFieldBox>
        <StdFieldBox title="Default Amount">
          <CribNumberInputField
            name="defaultAmount"
            schema={z.number().multipleOf(0.01).positive()}
          />
        </StdFieldBox>
        <StdFieldBox title="Presets" about={'enumerate all winner positions using comma'}>
          <CribInputField name="presets" schema={z.string()} placeholder="1, 5.50,10.2, 25" />
          <PresetsWatcher />
        </StdFieldBox>
        <StdFieldBox title="Sort">
          <CribNumberInputField name="sort" schema={z.number().int()} initialValue={100} />
        </StdFieldBox>
        <div>
          <CribSubmitButton />
        </div>
      </div>
    </CribSimpleForm>
  );
};

const getIconUrl = (code: string): string => `${ENV_CLOUD_MEDIA_URL}/payment/region/${code}`;

export const PaymentRegionEditBlock = ({ id }: { id: number }): JSX.Element => {
  const [data, setData] = useState<Rsa>(null);

  useLaunch(async () => {
    setData(null);
    const data = await apiReq({ action: 'payment.region.get', body: id });
    setData(
      iup(data, {
        icon: { $set: CloudFile.fromUrl(getIconUrl(data.id)) },
        presets: (list) => list.join(', '),
      })
    );
  }, [id]);

  const submit = async (body, attach) => {
    await apiReq({
      action: 'payment.region.upd',
      progress: true,
      success: true,
      body,
      attach,
    });
  };

  if (!data) return null;

  return (
    <div className="flex flex-col gap-4s">
      <div className="tg-title">Edit Region</div>
      <PaymentRegionForm initialData={data} submit={submit} />
    </div>
  );
};

export const PaymentRegionAddPage = (): JSX.Element => {
  const initialData = {};
  const submit = async (body, attach) => {
    const { id } = await apiReq({
      action: 'payment.region.add',
      progress: true,
      success: true,
      body,
      attach,
    });

    chronicle.replace(`/payment/region/${id}`);
  };

  return (
    <div className="flex flex-col gap-4s">
      <div className="tg-title">Add Region</div>
      <PaymentRegionForm initialData={initialData} submit={submit} />
    </div>
  );
};

const PresetsWatcher = (): JSX.Element => {
  const prize = useCribWatch<string>('presets');
  if (!prize) return null;
  const parts = prize.split(',');
  const children = [];
  let i = 0;
  for (const part of parts) {
    ++i;
    const num = parseFloat(part);
    children.push(
      <li key={`${i}.${num}`}>
        <span className="p2sr">{i}.</span>
        <span>{isNaN(num) ? null : `${num}$`}</span>
      </li>
    );
  }
  return <ul>{children}</ul>;
};
