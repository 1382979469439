import { css, cx } from '@emotion/css';
import { apiReq, useApiSwiss } from 'packs/libs/api';
import { enumOp } from 'fnd/support/enum-operator';
import { CheckBox } from 'packs/iconic/CheckBox';
import { boolFork } from 'support/etc/bool-fork';
import { PlayerRestrictionScope } from 'domain/player/restrictions';

type PlayerRestrictionsBlockProps = {
  playerId: string;
};

export const PlayerRestrictionsBlock = ({
  playerId,
}: PlayerRestrictionsBlockProps): JSX.Element => {
  const [data, op] = useApiSwiss<Record<PlayerRestrictionScope, boolean>>({
    action: 'player.restrictions.get',
    query: { playerId },
      });

  const composeContent = () => {
    if (data === undefined) return 'loading...';

    return (
      <div>
        {enumOp(PlayerRestrictionScope).values.map((scope) => {
          const value = data[scope];
          return (
            <div key={scope} className={cx('flex gap-2s p2sa', forkScopeColor(value))}>
              <CheckBox
                className="cursor-pointer"
                checked={value}
                onClick={async () => {
                  await apiReq({
                    action: 'player.restrictions.scope.set',
                    body: { playerId, scope, value: !value },
                    success: true,
                  });
                  op.updState({ [scope]: !value });
                }}
              />
              <div>{scope}</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-md w40s">
      <div className="tg-h6 p2sx">Restrictions</div>
      <div className="flex flex-col gap-1s">{composeContent()}</div>
    </div>
  );
};

const forkScopeColor = boolFork(
  css`
    background: #ffeeee;
  `,

  css`
    background: #e4ffe4;
  `
);
