import { PaymentMethodAddBlock, PaymentMethodEditBlock } from 'app/payment/method/form';
import { PaymentMethodSearchBlock } from 'app/payment/method/search-block';
import { StdJunction, StdPrePath } from 'packs/std';

export const PaymentMethodJunction = ({ regionId }): JSX.Element => {
  return (
    <StdPrePath path="method">
      <StdJunction
        routes={[
          ['add', <PaymentMethodAddBlock regionId={regionId} />],
          [':paymentId', <PaymentMethodEditBlock regionId={regionId} />],
          ['', <PaymentMethodSearchBlock regionId={regionId} />],
        ]}
      />
    </StdPrePath>
  );
};
