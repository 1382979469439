import { EmployeeAreaEdit } from 'app/employees/users/employee.area.edit';
import { EmployeeAreaSearch } from 'app/employees/users/employee.area.search';
import { EmployeeAddArea } from 'app/employees/users/EmployeeAddArea';
import { AccessGuard } from 'domain/actor/access-guard';
import { apiReq } from 'packs/libs/api';
import { StdJunction, StdKindButton } from 'packs/std';
import { Link, useRouteMatch } from 'react-router-dom';

export function EmployeeMain() {
  const { path } = useRouteMatch();

  return (
    <div className="flex flex-col gap-4s">
      <AccessGuard access="player.write">
        <div className="flex gap-4s">
          <Link to={`${path}/add`}>
            <StdKindButton>add new</StdKindButton>
          </Link>
          <StdKindButton
            children="Logout all employees"
            kind="ah"
            onClick={() => {
              apiReq({ action: 'employee.logout-all',  });
            }}
          />
        </div>
      </AccessGuard>

      <EmployeeAreaSearch>
        <StdJunction
          routes={[
            ['add', <EmployeeAddArea />],
            [':employeeId', <EmployeeAreaEdit />],
          ]}
        />
      </EmployeeAreaSearch>
    </div>
  );
}
