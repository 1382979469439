import { cx } from '@emotion/css';
import { ReactNode } from 'react';

type StdCompoundCardGroupProps = {
  children: ReactNode;
  className?: string;
};

export const StdCompoundCardGroup = ({
  children,
  className,
}: StdCompoundCardGroupProps): JsxElement => {
  return <div className={cx('flex flex-wrap gap-2s', className)}>{children}</div>;
};

type StdCompoundCardProps = {
  children: ReactNode;
  className?: string;
  title?: ReactNode;
};

export const StdCompoundCard = ({
  className,
  children,
  title,
}: StdCompoundCardProps): JsxElement => {
  return (
    <div className="min-content">
      {title && <div className="tg-title">{title}</div>}
      <div className={cx('std-paper p4sa flex flex-col gap-2s', className)}>{children}</div>
    </div>
  );
};
