import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { PlayerRole } from 'domain/player/definitions';
import { CribNumberInputField, useCribField } from 'packs/crib';
import { CribDurationField } from 'packs/crib/fields/duration-field';
import { StdFieldBox, StdSelect } from 'packs/std';
import { ComponentType, createElement } from 'react';
import { HashMap } from 'support/struct/hash-map';
import { AnySchema } from 'yup';
import { z } from 'zod';

export type PlayerRequirementsData = [string, any][];

type PlayerRequirementsBlockProps = {
  preview?: boolean;
  data: PlayerRequirementsData;
  onChange?(data: any, valid: boolean): void;
};

type State = {
  list: {
    type: ComponentType<ReqTypeProps>;
    value: any;
  };
};

type ReqTypeProps = {
  name: string;
};

type ReqType = {
  schema?: AnySchema;
  about?: string;
  component?: ComponentType<ReqTypeProps>;
  initial?: any;
  title: string;
};

type PlayerRequirementFieldProps = {
  name: string;
  editable?: boolean;
};

export const PlayerRequirementField = (props: PlayerRequirementFieldProps): JSX.Element => {
  const [{ value }, fieldOp] = useCribField<PlayerRequirementsData>(() => {
    return {
      name: props.name,
      initialValue: [],
      schema: z.array(z.tuple([z.string(), z.any()])),
    };
  });

  return (
    <StdFieldBox title="Player Requirements">
      <div className="flex flex-col gap-4s">
        <StdSelect
          className="w80s"
          options={[...Types.keys()]}
          getOptionLabel={(code) => Types.get(code).title}
          placeholder="add requirement"
          onChange={(value) => {
            console.log(value, fieldOp.getValue());
            fieldOp.mutValue((draft) => {
              draft.push([value, Types.get(value).initial]);
            });
          }}
        />
        {value.map(([code, opts], i) => {
          const type = Types.get(code);
          return (
            <div key={`${code}${i}`} className="flex">
              <div>
                <DeleteOutlineIcon
                  className="cursor-pointer"
                  onClick={() => {
                    fieldOp.mutValue((draft) => {
                      draft.splice(i, 1);
                    });
                  }}
                />
              </div>
              <div>
                <div className="tg-subtitle1">{type.title}</div>
                {type.about && <div className="tg-caption2">{type.about}</div>}
                {type.component
                  ? createElement(type.component, { name: `${props.name}.${i}.1` })
                  : null}
              </div>
            </div>
          );
        })}
      </div>
    </StdFieldBox>
  );
};

export const SteamLevel = (): JSX.Element => {
  return <div>{/* <StdNumberInput /> */}</div>;
};

const MinimumDepositField = (props): JSX.Element => {
  const { name } = props;
  return (
    <div>
      <StdFieldBox level={2} title="Duration">
        <CribDurationField required name={`${name}.duration`} />
      </StdFieldBox>
      <StdFieldBox level={2} title="Amount">
        <CribNumberInputField name={`${name}.amount`} schema={() => z.number().positive()} />
      </StdFieldBox>
    </div>
  );
};

const SteamLevelField = (props): JSX.Element => {
  const { name } = props;
  return (
    <div>
      <StdFieldBox level={2} title="Minimum Steam Level">
        <CribNumberInputField
          initialValue={3}
          name={`${name}.min`}
          schema={() => z.number().positive()}
        />
      </StdFieldBox>
    </div>
  );
};

const PlayerRoleField = (props): JSX.Element => {
  useCribField(() => {
    return { initialValue: PlayerRole.vip, name: props.name, schema: z.string() };
  });
  return <div>means only for vip for now</div>;
};

const Types = new HashMap<string, ReqType>([
  [
    'nickname',
    {
      title: 'Nickname',
      about: 'Players should have CSGOCOCK in their nickname',
    },
  ],
  [
    'steam_level',
    {
      title: 'Steam Level',
      component: SteamLevelField,
    },
  ],
  [
    'deposit',
    {
      title: 'Minimum Deposit',
      component: MinimumDepositField,
    },
  ],
  [
    'role',
    {
      title: 'Player Role',
      component: PlayerRoleField,
    },
  ],
]);
