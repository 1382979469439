import { ContestHistoryInsightPage } from 'app/contest/history/insight';
import { ContestHistorySearchPage } from 'app/contest/history/search';

export const ContestHistoryJunction = (): JsxElement => {
  return (
    <div className="flex flex-wrap gap-4s">
      <ContestHistorySearchPage />
      <ContestHistoryInsightPage />
    </div>
  );
};
