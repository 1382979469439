class Ref<T> {
  private factory: () => T;

  constructor(factory: () => T) {
    this.factory = factory;
  }

  get v(): T {
    const value = this.factory();
    Object.defineProperty(this, 'v', { value });
    delete this.factory;
    return value;
  }
}

export function stashRef<T>(factory: () => T): Ref<T> {
  return new Ref<T>(factory);
}

export function stash<T extends () => any>(factory: T): T {
  const ref = stashRef<T>(factory);
  // @ts-ignore
  return () => ref.v;
}

export function stashCb<T extends (...args: any[]) => any>(factory: () => T): T {
  const ref = stashRef(factory);
  return function () {
    return ref.v(...arguments);
  } as T;
}

type Descriptor<T> = TypedPropertyDescriptor<T>;

export function stashGet<T = unknown>(target: any, key: string, descriptor: Descriptor<T>) {
  const { get } = descriptor;
  if (get === undefined) throw 'unexpected use of @memGet';
  descriptor.get = function () {
    const value = get.apply(this);
    Object.defineProperty(this, key, { value });
    return value;
  };
}

export function stash_method<T = unknown>(
  target: any,
  key: string,
  descriptor: Descriptor<() => T>
) {
  const { value } = descriptor;
  if (typeof value !== 'function') throw Error('unexpected use of @stashMethod');
  descriptor.value = function () {
    const result: T = value.apply(this);
    Object.defineProperty(this, key, {
      value: () => result,
    });
    return result;
  };
}
