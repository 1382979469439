import { css, cx } from '@emotion/css';
import { EditorView, basicSetup } from 'codemirror';
import { infError } from 'fnd/inf/Inf';
import { dump, load } from 'js-yaml';
import { apiReq } from 'packs/libs/api';
import { Dump } from 'packs/single/Dump';
import { Swiss } from 'packs/state/swiss-store';
import { useSingleton } from 'packs/state/use-singleton';
import { StdKindButton } from 'packs/std';
import { ReactNode, useEffect } from 'react';
import { formatError } from 'support/etc/formatError';

type Operator = Swiss<Rsa>;

type ConfigCellProps = {
  title?: ReactNode;
  prefix: string;
  className?: string;
  op?(op: Operator): void;
};

export const ConfigCell = (props: ConfigCellProps): JSX.Element => {
  const context = useSingleton(() => {
    let parent: HTMLElement;
    let editor: EditorView;

    const { prefix, op } = props;

    const doLoad = async () => {
      editor = new EditorView({
        doc: await loadData(),
        extensions: [basicSetup],
        parent: parent,
      });
    };

    const loadData = async () => {
      const data = await apiReq({
        action: `${prefix}.find`,
      });
      return dump(data ?? {});
    };

    const loadSafe = () => {
      try {
        const yaml = editor.state.doc.toString();
        return load(yaml);
      } catch (e) {
        infError(<Dump data={formatError(e)} />);
        throw e;
      }
    };

    const save = async () => {
      await apiReq({
        action: `${prefix}.set`,
        success: true,
        body: loadSafe(),
      });
    };

    const setContent = (content: string) => {
      const state = editor.state;
      // state.update({ changes: { from: 0, to: state.doc.length, insert: content } });
      state.update({ changes: { from: 0, insert: content } });
    };

    const reload = async () => {
      setContent(await loadData());
    };

    if (op) {
      const operator = new Swiss(
        (state) => {
          setContent(dump(state));
        },
        () => load(editor.state.doc.toString())
      );

      op(operator);
    }

    return {
      ref: (el: HTMLElement) => {
        if (el === null) {
          parent = null;
          editor?.destroy();
        } else {
          parent = el;
        }
      },
      load: () => {
        doLoad();
      },
      save,
      reload,
    };
  });

  useEffect(context.load, []);

  return (
    <div className={cx('std-paper p4sa', props.className)}>
      {props.title && <div className="tg-title">{props.title}</div>}
      <div className="p2sy flex gap-2s">
        <StdKindButton children="save" onClick={context.save} />
        {/* <StdKindButton kind="ah" children="reload" onClick={context.reload} /> */}
      </div>
      <div ref={context.ref} className={editorClass}></div>
    </div>
  );
};

const mainClass = css``;

const panelClass = css``;

const editorClass = css``;
