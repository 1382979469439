import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { usePaymentVendors, VendorsOp } from 'app/payment/method/vendor';
import { chronicle } from 'fnd/bridge/router';
import { CloudFile } from 'packs/cloud';
import {
  CribNumberInputField,
  CribSimpleForm,
  CribSubmitButton,
  useCribContext,
  useCribWatch,
} from 'packs/crib';
import { CribCloudImageField } from 'packs/crib/fields/cloud-image';
import { CribBoolButtonField } from 'packs/crib/fields/crib-bool-button';
import { CribLangsInputField } from 'packs/crib/fields/langs';
import { CribPrimitiveSelect } from 'packs/crib/fields/primitive-select';
import { CribSimpleSwitchSelect } from 'packs/crib/fields/switch-select';
import { apiReq } from 'packs/libs/api';
import { StdFieldBox } from 'packs/std';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLaunch } from 'support/react/use-launch';
import { z } from 'zod';

const CATEGORIES = [
  'cards',
  'e-wallets',
  'crypto',
  'skins',
  // 'kinguin'
];
const LABELS = ['none', 'isBest', 'star', 'thumbUp'];

type PaymentRegionFormProps = {
  submit(body: any, attach: any): void;
  initialData: any;
};

const PaymentMethodForm = ({ submit, initialData }: PaymentRegionFormProps): JSX.Element => {
  const doSubmit = (data) => {
    const { icon, ...rest } = data;
    submit(rest, [icon]);
  };

  return (
    <CribSimpleForm submit={doSubmit} initialData={initialData}>
      <div className="flex flex-col gap-4s std-paper p4sa w100s">
        <CribBoolButtonField name="enabled" />
        <StdFieldBox title="Name">
          <CribLangsInputField name="name" schema={z.string()} />
        </StdFieldBox>
        <StdFieldBox title="Icon">
          <CribCloudImageField name="icon" required />
        </StdFieldBox>
        <StdFieldBox title="Label">
          <CribSimpleSwitchSelect name="label" options={LABELS} initialValue={LABELS[0]} />
        </StdFieldBox>
        <VendorField />
        <StdFieldBox title="Minimal amount">
          <CribNumberInputField
            name="minimalAmount"
            schema={z.number().multipleOf(0.01).positive().optional()}
          />
        </StdFieldBox>
        <StdFieldBox title="Minimal Percent">
          <CribNumberInputField
            name="commissionPercent"
            schema={z.number().multipleOf(0.01).positive().optional()}
          />
        </StdFieldBox>
        <StdFieldBox title="Minimal Commission">
          <CribNumberInputField
            name="commissionMinimal"
            schema={z.number().multipleOf(0.01).positive().optional()}
          />
        </StdFieldBox>
        <StdFieldBox title="Sort">
          <CribNumberInputField name="sort" schema={z.number().int()} initialValue={100} />
        </StdFieldBox>
        <div>
          <CribSubmitButton />
        </div>
      </div>
    </CribSimpleForm>
  );
};

const VendorField = (): JSX.Element => {
  const vendors = usePaymentVendors();

  if (!vendors) return null;

  return (
    <>
      <StdFieldBox title="Vendor">
        <CribPrimitiveSelect
          name="vendor"
          schema={z.string().min(1)}
          options={vendors.list}
          getOptionValue={(v) => v.name}
          getOptionLabel={(v) => v.name}
        />
      </StdFieldBox>
      <VendorSubFields vendors={vendors} />
    </>
  );
};

const VendorSubFields = ({ vendors }: { vendors: VendorsOp }): JSX.Element => {
  const vendor = useCribWatch('vendor');
  const c = useCribContext();

  useEffect(() => {
    if (vendor === undefined) {
      if (c.getData().vendorMethod) c.data.updState({ vendorMethod: undefined });
    }
  }, [vendor]);

  if (!vendor) return null;

  const vendorData = vendors.get(vendor);
  console.log('vendorData', vendors, vendor, vendorData);

  return (
    <>
      <StdFieldBox title="Category">
        <CribPrimitiveSelect name="category" options={CATEGORIES} />
      </StdFieldBox>
      {vendorData.methods.length > 0 && (
        <StdFieldBox title="Vendor Method">
          <CribPrimitiveSelect
            name="vendorMethod"
            options={vendorData.methods}
            getOptionValue={(v) => v.id}
            getOptionLabel={(v) => (
              <span key={v.id} title={v.name}>
                {v.name}
              </span>
            )}
          />
        </StdFieldBox>
      )}
    </>
  );
};

const getIconUrl = (code: string): string => `${ENV_CLOUD_MEDIA_URL}/payment/method/${code}`;

export const PaymentMethodEditBlock = ({ regionId }): JSX.Element => {
  const { paymentId } = useParams<{ paymentId: string }>();

  const [data, setData] = useState<Rsa>(null);

  const id = parseInt(paymentId);

  useLaunch(async () => {
    setData(null);
    const data = await apiReq({
      action: 'payment.method.get',
      body: id,
    });
    setData({ ...data, icon: CloudFile.fromUrl(getIconUrl(data.id)) });
  }, [id]);

  const submit = async (body, attach) => {
    await apiReq({
      action: 'payment.method.upd',
      progress: true,
      success: true,
      body: { ...body, id, regionId },
      attach,
    });
  };

  if (!data) return <div>Loading...</div>;

  return (
    <div className="flex flex-col gap-4s">
      <div className="flex gap-4s aic">
        <ArrowBackIcon
          className="cursor-pointer"
          onClick={() => {
            chronicle.goBack();
          }}
        />
        <div className="tg-title">Edit Method</div>
      </div>
      <PaymentMethodForm initialData={data} submit={submit} />
    </div>
  );
};

export const PaymentMethodAddBlock = ({ regionId }): JSX.Element => {
  const initialData = {};
  const submit = async (body, attach) => {
    await apiReq({
      action: 'payment.method.add',
      progress: true,
      success: true,
      body: { regionId, ...body },
      attach,
    });
    chronicle.push(`/payment/region/${regionId}`);
  };
  return (
    <div className="flex flex-col gap-4s">
      <div className="tg-title">Add Region</div>
      <PaymentMethodForm initialData={initialData} submit={submit} />
    </div>
  );
};
