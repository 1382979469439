import { css, cx } from '@emotion/css';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

type ExpandTriggerProps = {
  value: boolean;
  onChange?(value: boolean): void;
  className?: string;
};

export const ExpandIcon = ({ value, onChange, className }: ExpandTriggerProps): JSX.Element => {
  return (
    <div
      className={cx(mainClass, className)}
      onClick={() => {
        onChange?.(!value);
      }}
    >
      {value ? <ExpandLess /> : <ExpandMore />}
    </div>
  );
};

const mainClass = css`
  cursor: pointer;
`;
