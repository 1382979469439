import { useActor } from 'domain/actor';
import { apiReq } from 'packs/libs/api';
import { fancyConfirmation } from 'packs/single/fancy-confirmation';
import { StdKindButton } from 'packs/std';

type LeaderboardDashboardProps = {};

export const LeaderboardDashboard = ({}: LeaderboardDashboardProps): JSX.Element => {
  const actor = useActor();
  return (
    <div>
      {actor.can('leaderboard.write') && (
        <div className="p4sy flex gap-4s">
          <StdKindButton
            children="refresh"
            onClick={() => apiReq({ action: 'leaderboard.refresh', success: true, progress: true })}
          />
          {actor.can('leaderboard.admin') && (
            <StdKindButton
              children="force rotate"
              onClick={() => {
                fancyConfirmation({
                  about:
                    "This will sum up all current player's progress and rotate the leaderboard",
                  onOk() {
                    apiReq({ action: 'leaderboard.rotate', success: true, progress: true });
                  },
                });
              }}
            />
          )}
          {actor.can('leaderboard.admin') && (
            <StdKindButton
              disabled
              children="recalculate"
              onClick={() => {
                fancyConfirmation({
                  about: 'This operation is not stable',
                  onOk() {
                    apiReq({ action: 'leaderboard.recalculate', success: true, progress: true });
                  },
                });
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
