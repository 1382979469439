import { css, cx } from '@emotion/css';
import { CaseData } from 'domain/case/definitions';
import { CaseImage } from 'domain/case/image';

type CaseCardProps = {
  data: CaseData;
  className?: string;
  onClick?(): void;
};

export const CaseCard = ({ data, className, onClick }: CaseCardProps): JSX.Element => {
  const { id, name, price } = data;
  return (
    <div className={cx(mainClass, className, onClick && 'cursor-pointer')}>
      <CaseImage code={id} className={imageClass} />
      <div className="tg-subhead">{name}</div>
      <div className="tg-subtitle1">${price}</div>
    </div>
  );
};

const mainClass = css`

`;

const imageClass = css`
  height: 80px;
`;
