import { css, cx } from '@emotion/css';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import { ButtonHTMLAttributes, createElement, useRef } from 'react';

export type StdButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export function StdButton(props: StdButtonProps): JSX.Element {
  const rippleRef = useRef(null);

  // @ts-ignore
  return (
    <button
      type="button"
      {...props}
      onMouseDown={(e) => {
        rippleRef.current.start?.(e);
        props.onMouseDown?.(e);
      }}
      onMouseUp={(e) => {
        rippleRef.current.stop?.(e);
        props.onMouseUp?.(e);
      }}
      onTouchStart={(e) => {
        rippleRef.current.start?.(e);
        props.onTouchStart?.(e);
      }}
      onTouchEnd={(e) => {
        rippleRef.current.stop?.(e);
        props.onTouchEnd?.(e);
      }}
      onMouseLeave={(e) => {
        rippleRef.current.stop?.(e);
        props.onMouseLeave?.(e);
      }}
      onDragLeave={(e) => {
        rippleRef.current.stop?.(e);
        props.onDragLeave?.(e);
      }}
      ref={rippleRef}
      className={cx(mainClass, props.className)}
    >
      {props.children}
      <TouchRipple className={rippleClass} ref={rippleRef} center={false} />
    </button>
  );
}

export const classifiedStdButton = (className: string) => {
  return function ClassifiedStdButton(props: StdButtonProps) {
    return createElement(StdButton, {
      ...props,
      className: cx(className, props.className),
    });
  };
};

const rippleClass = css`
  color: #fff;
`;

const mainClass = css`
  position: relative;
`;
