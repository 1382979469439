import { css, cx } from '@emotion/css';
import { el } from 'fnd/bridge/react';
import { SussOperator, useSuss } from 'packs/libs/Suss';
import { Swiss } from 'packs/state/swiss-store';
import React, { ReactElement, useMemo } from 'react';

export type StableRawProps<T> = {
  data: T;
  op(pk: string): Swiss<T>;
  pass: any;
  suss: SussOperator<T>;
};

type StableProps<T> = {
  children: React.ReactNode;
  className?: string;
  Row?: React.ComponentType<StableRawProps<T>>;
  body?: ReactElement;
  pass?: any;
};

export function Stable<T>({ children, Row, body, className, pass }: StableProps<T>) {
  return (
    <div className={cx(mainClass, 'std-paper', className)}>
      <table>
        <thead>
          <tr>{children}</tr>
        </thead>
        {body ?? <Body Row={Row} pass={pass} />}
      </table>
    </div>
  );
}

const Body = ({ Row, pass }): JsxElement => {
  const [{ data }, suss] = useSuss();
  const { opItem } = suss;

  return useMemo(() => {
    return (
      <tbody>
        {data.mapEntries((entry) =>
          el(Row, { key: entry[0], data: entry[1], op: opItem, suss, pass })
        )}
      </tbody>
    );
  }, [data]);
};

const mainClass = css`
  width: 100%;

  .p-0 {
    padding: 0;
  }

  table,
  th,
  td {
    border-collapse: collapse;
  }

  tr {
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-800);
    }

    &.selected {
      background: #ccccff;
    }

    &.past {
      background: #ccccff;
    }
  }

  thead {
    border-bottom: 1px solid var(--grey-700);
  }

  th,
  td {
    padding: 16px;
  }

  table {
    width: 100%;
  }

  tbody {
    tr:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  th {
    text-align: left;
    font-weight: 500;
  }
`;
