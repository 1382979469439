import { stashGet } from 'support/memory/stash';

export class EnumMap<A, B> {
  private constructor(private readonly map: Map<any, any>) {}

  get(a: A) {
    return this.map.get(a)!;
  }

  static create<A, B, C extends string>(a: Record<C, A>, b: Record<C, B>) {
    return new EnumMap<A, B>(new Map(Object.entries(a).map(([k, v]) => [v, b[k]])));
  }

  @stashGet
  get rev() {
    return new EnumMap<B, A>(new Map([...this.map.entries()].map(([k, v]) => [v, k])));
  }
}
