import React, {SVGProps} from 'react';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';
import {el} from 'fnd/bridge/react';


type CheckBoxProps = SVGProps<SVGElement> & {
  checked: boolean
}

export function CheckBox({
  checked, ...props
}: CheckBoxProps) {
  return el(
    checked ? CheckBoxOutlined : CheckBoxOutlineBlank,
    props,
  );
}
