import {StdPage} from 'packs/std';
import {Dump} from 'packs/single/Dump';
import { useActor } from 'domain/actor';


export function EmployeeProfilePage() {
  const actor = useActor();
  return (
    <StdPage title={'Profile'}>

      <Dump data={actor.payload}/>

    </StdPage>
  );
}
