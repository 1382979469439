import { cx } from '@emotion/css';
import { StdJunction } from 'packs/std/navigation/std.junction';
import { StdNavTabSwitch } from 'packs/std/navigation/StdNavTab';
import { StdTabType } from 'packs/std/navigation/StdTab';
import { ReactNode, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

type Route =
  | {
      path: string;
      elem: ReactNode;
      title?: ReactNode;
      hidden?: boolean;
      access?: boolean;
      exact?: boolean;
    }
  | [string, ReactNode];

type StdTabJunctionProps = {
  className?: string;
  type?: StdTabType;
  routes: Route[];
};

export const StdTabJunction = ({ className, routes, type }: StdTabJunctionProps): JSX.Element => {
  const { url: parent } = useRouteMatch();

  return useMemo(() => {
    const jun: any[] = [];
    const tabs: any[] = [];
    const prefix = parent === '/' ? '/' : parent + '/';
    for (let data of routes) {
      let { path, elem, title, hidden, access, exact } = resolveRoute(data);
      if (access === false) continue;
      title ??= <span className="capitalize">{path}</span>;
      jun.push([path, elem, exact]);
      if (!hidden) tabs.push([prefix + path, title]);
    }
    return (
      <div className={cx('flex flex-col gap-4s', className)}>
        <StdNavTabSwitch tabs={tabs} type={type} />
        <StdJunction routes={jun} />
      </div>
    );
  }, [routes, parent]);
};

const resolveRoute = (data) => {
  if (Array.isArray(data)) {
    return {
      path: data[0],
      elem: data[1],
    };
  }
  return data;
};

type CrumbyProps = {
  title: ReactNode;
  children: ReactNode;
};
