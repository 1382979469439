import { showSimplePopover } from 'packs/single/simple-popover';
import { ReactNode } from 'react';

type InfoPopIconProps = {
  info: ReactNode;
};

export const InfoPopIcon = ({ info }: InfoPopIconProps): JSX.Element => {
  const compose = () => {
    if (typeof info === 'string') return <div className="std-paper p4sa">{info}</div>;
    return info;
  };
  return (
    <div
      children="?"
      className='flex items-center justify-center w-6 h-6 bg-gray-200 rounded-full cursor-pointer'
      onClick={(e) => {
        showSimplePopover({
          children: compose(),
          anchor: e.currentTarget,
        });
      }}
    />
  );
};
