import { Stable } from 'packs/blocks/suss-table';
import { SussProvider, useSuss } from 'packs/libs/Suss';
import { apiReq } from 'packs/libs/api';
import { Dump } from 'packs/single/Dump';
import { StdTime } from 'packs/std/time/format';
import { createElement, memo } from 'react';

const request = (query, context) => {
  return apiReq({
    action: 'anti-fraud.report.search',
    body: { ...query, ...context },
  });
};

type PlayerAntiFraudPageProps = {
  playerId: string;
};

export const PlayerAntiFraudPage = ({ playerId }: PlayerAntiFraudPageProps): JsxElement => {
  return (
    <SussProvider request={request} query={{ playerId }}>
      <div className="flex gap-4s">
        <div>
          <Stable body={<List />}>
            <th>Created</th>
            <th>Risk Rank</th>
            <th>Risk Score</th>
          </Stable>
        </div>
        <div>
          <Detailed />
        </div>
      </div>
    </SussProvider>
  );
};

type ListProps = {};

const List = ({}: ListProps): JsxElement => {
  const [{ data, stash }, suss] = useSuss();
  const selected = stash.selected;

  const select = (id: string) => {
    suss.stash.updState({ selected: id });
  };

  return (
    <tbody>
      {data.mapEntries((entry) =>
        createElement(Row, { key: entry[0], data: entry[1], suss, selected, select })
      )}
    </tbody>
  );
};

type RowProps = Rsa;

const Row = memo(function Row({ data, selected, select }: RowProps): JsxElement {
  return (
    <tr onMouseOver={() => select(data.id)}>
      <td>{StdTime.iso.datetime(data.createdAt)}</td>
      <td>{data.riskRank}</td>
      <td>{data.riskScore}</td>
    </tr>
  );
});

const Detailed = (): JsxElement => {
  const [{ data, stash }, suss] = useSuss();

  const report = data.find(stash.selected);
  if (!report) return null;

  return (
    <div className="std-paper p4sa">
      <Dump data={report.payload} />
    </div>
  );
};
