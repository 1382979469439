import { useConsoleConfigOnProp } from 'domain/etc/console-config';

type PlayerAmplitudeStatsGroupConfig = {
  title: string;
  collapsed?: boolean;
  fields: string[];
};

export type PlayerAmplitudeStatsConfig = {
  groups: PlayerAmplitudeStatsGroupConfig[];
};

export const usePlayerAmplitudeStatsConfig = () =>
  useConsoleConfigOnProp<PlayerAmplitudeStatsConfig>('player_amplitude');
