import { memoize } from 'lodash';
import { stashGet } from 'support/memory/stash';

type EnumType<V> = Record<string, V>;

const ID = Symbol('EnumOperator');

class EnumOperator<T extends string | number> {
  private readonly val: EnumType<T>;

  constructor(val: EnumType<T>) {
    this.val = val;
  }

  includes(value: T) {
    return this.values.includes(value);
  }

  @stashGet
  get keys(): string[] {
    return Object.keys(this.val);
  }

  @stashGet
  get values(): T[] {
    return Object.values(this.val);
  }

  keyOf(value: T): string {
    const result = this.valKeyMap.get(value);
    if (result === undefined) throw new Error('enumOp.keyOf: value does not exists');
    return result;
  }

  get entries() {
    return Object.entries(this.val);
  }

  @stashGet
  get valKeyMap(): Map<T, string> {
    return new Map(this.entries.map(([key, val]) => [val, key]));
  }

  @stashGet
  get labelValue(): { label: string; value: T }[] {
    return this.entries.map(([label, value]) => ({ label, value }));
  }

  @stashGet
  get labelValueMap(): Map<T, { label: string; value: T }> {
    return new Map(this.entries.map(([label, value]) => [value, { label, value }]));
  }
}

export const enumOp = <T extends string | number>(obj: EnumType<T>): EnumOperator<T> =>
  // @ts-ignore
  obj[ID] ?? (obj[ID] = new EnumOperator<T>(obj));
