import { CaseSearchPage } from 'app/cases/search-page';
import { AccessGuard } from 'domain/actor/access-guard';
import { StdKindButton } from 'packs/std';
import { Link } from 'react-router-dom';

export const CaseDashboard = (): JSX.Element => {
  return (
    <div className="flex flex-col gap-4s">
      <div>
        <AccessGuard access="case.entity.write">
          <Link to="/cases/add">
            <StdKindButton children="Add Case" />
          </Link>
        </AccessGuard>
      </div>
      <div className="tg-title">Case Search</div>
      <CaseSearchPage />
    </div>
  );
};
