import { css, cx } from '@emotion/css';
import Popover, { PopoverOrigin } from '@material-ui/core/Popover';
import { appSyringe } from 'app/syringe';
import React, { createElement, forwardRef, ReactNode, useRef } from 'react';

export type SimplePopoverDropProps = {
  onClose(): void;
};

const defaultAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};
const defaultTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

type Options = {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

type SimplePopoverProps = Options & {
  className?: string;
  opened: boolean;
  onClose(): void;
  drop: React.ReactNode;
  children: React.ReactNode;
};

export const SimplePopover = ({
  children,
  opened,
  onClose,
  drop,
  className,
  anchorOrigin = defaultAnchorOrigin,
  transformOrigin = defaultTransformOrigin,
}: SimplePopoverProps) => {
  const ref = useRef();

  return (
    <div ref={ref} className={cx(mainClass, className)}>
      {opened &&
        createElement(Popover, {
          open: opened,
          onClose,
          anchorEl: ref.current,
          anchorOrigin,
          transformOrigin,
          PaperProps: { component: Paper },
          children: drop,
        })}
      {children}
    </div>
  );
};

type PaperProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};
const Paper = forwardRef<HTMLDivElement, PaperProps>(({ children, style }, ref) => {
  return createElement('div', { ref, children, style, tabIndex: -1, className: paperClass });
});

const mainClass = css`
  display: inline-block;
`;
const paperClass = css`
  position: absolute;
  outline: none;
`;

export function makeSimplePopover(opts: Options = {}) {
  return function PredefinedSimplePopover<T>(props: SimplePopoverProps) {
    return createElement(SimplePopover, { ...opts, ...props });
  };
}

type ShowSimplePopoverOptions = Options & {
  children: ReactNode;
  anchor: HTMLElement;
};

export const showSimplePopover = ({
  transformOrigin = defaultTransformOrigin,
  anchorOrigin = defaultAnchorOrigin,
  children,
  anchor,
}: ShowSimplePopoverOptions) => {
  return appSyringe(function SimplePopoverPortal({ onClose }) {
    return createElement(Popover, {
      open: true,
      onClose,
      transformOrigin,
      anchorOrigin,
      anchorEl: anchor,
      PaperProps: { component: Paper },
      children,
    });
  });
};
