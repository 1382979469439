import { css } from '@emotion/css';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Decimal from 'decimal.js';
import { CaseSlotType } from 'domain/case/definitions';
import { getCaseImageUrl } from 'domain/case/image';
import { SkinCard } from 'domain/skin/card';
import { openSkinSearchDialog } from 'domain/skin/search';
import { chronicle } from 'fnd/bridge/router';
import { DateTime } from 'luxon';
import { CloudFile } from 'packs/cloud';
import {
  CribInputField,
  CribNumberInputField,
  CribProblem,
  CribSimpleForm,
  CribSubmitButton,
  useCribContext,
  useCribField,
} from 'packs/crib';
import { CribCloudImageField } from 'packs/crib/fields/cloud-image';
import { CribAutoTextArea } from 'packs/crib/fields/textarea';
import { apiReq } from 'packs/libs/api';
import { useSingleton } from 'packs/state/use-singleton';
import { StdFieldBox, StdInput, StdKindButton } from 'packs/std';
import { memo, useEffect, useState } from 'react';
import { useSwissState } from 'support/react/swiss';
import { useLaunch } from 'support/react/use-launch';
import { z } from 'zod';

type CaseModProps = {
  data: any;
  submit(body: any, attach: any): void;
};

const CaseForm = ({ data: initialData, submit }: CaseModProps): JSX.Element => {
  const doSubmit = (data) => {
    const { image, slots, ...rest } = data;
    submit({ ...rest, config: { slots } }, image);
  };

  return (
    <CribSimpleForm initialData={initialData} submit={doSubmit}>
      <div className="flex flex-col gap-4s">
        <div className="flex gap-4s">
          <div className="std-paper p4sa gap-4s flex flex-col">
            <div className="w120s gap-4s flex flex-col">
              <StdFieldBox title="Name">
                <CribInputField name="name" schema={z.string().min(1).max(64)} />
              </StdFieldBox>
              <StdFieldBox title="Description">
                <CribAutoTextArea name="about" schema={z.string().min(1).max(255)} />
              </StdFieldBox>
              <StdFieldBox title="Price">
                <CribNumberInputField name="price" schema={z.number().positive()} />
              </StdFieldBox>
            </div>
          </div>
          <div className="std-paper p4sa gap-4s flex flex-col">
            <div className="w80s std-paper p4sa">
              <StdFieldBox title="Image">
                <CribCloudImageField required name="image" ratio={1} />
              </StdFieldBox>
            </div>
          </div>
        </div>
        <div className="std-paper p4sa gap-4s flex flex-col">
          <div className="tg-title">Slots</div>
          <TotalChance />
          <RtpField />
          <SlotListField />
        </div>
        <div>
          <CribSubmitButton />
        </div>
      </div>
    </CribSimpleForm>
  );
};

const TotalChance = (): JSX.Element => {
  const c = useCribContext();
  const [total, setTotal] = useState(() => new Decimal(0));
  useEffect(() => {
    const validator = (data) => {
      const total: Decimal = data.slots.reduce(
        (acc, slot) => acc.add(slot.chance ?? 0),
        new Decimal(0)
      );
      setTotal(total);
      if (!total.eq(100)) return CribProblem.simple({ message: 'Total chance must be 100%' });
    };
    c.validators.add(validator);
    return () => {
      c.validators.del(validator);
    };
  }, []);
  return (
    <div className={total.eq(100) ? goodClass : badClass}>Total Chance: {total.toString()}%</div>
  );
};

const RtpField = (): JSX.Element => {
  const [{ rtp, price }, op] = useSwissState(() => ({
    rtp: new Decimal(95),
    price: new Decimal(0),
  }));

  const c = useCribContext<any>();
  const calc = useSingleton(() => {
    let lastSlots;
    let lastTotal = new Decimal(0);
    // let lastT;

    const doCalc = () => {
      op.updState({ price: lastTotal.mul(100).div(op.getState().rtp).toDP(2) });
    };

    const checkSlots = (slots: any[]) => {
      if (slots === lastSlots) return;
      lastSlots = slots;
      lastTotal = slots.reduce(
        (acc, slot) => acc.add(new Decimal(slot.cost ?? 0).div(100).mul(slot.chance ?? 0)),
        new Decimal(0)
      );

      doCalc();
    };

    const setRtp = (rtp: Decimal) => {
      op.updState({ rtp });
      doCalc();
    };

    return { checkSlots, setRtp };
  });

  useEffect(() => {
    calc.checkSlots(c.getData().slots);
    return c.observers.sub((data) => {
      calc.checkSlots(data.slots);
    });
  }, []);

  return (
    <div className="flex items-center gap-2s">
      for RTP
      <div className="w12s">
        <StdInput
          value={rtp.toString()}
          onChange={(e) => {
            calc.setRtp(new Decimal(e.target.value));
          }}
        />
      </div>
      <div>recommended price: {price.toString()}</div>
    </div>
  );
};

const goodClass = css`
  color: green;
`;

const badClass = css`
  color: red;
`;

const SlotListField = (): JSX.Element => {
  const [{ value }, op] = useCribField(() => {
    return { name: 'slots', initialValue: [] };
  });

  return (
    <div className="flex flex-wrap gap-4s">
      {value.map((slot, i) => {
        return <SlotField key={i} type={slot.type} i={i} />;
      })}
      <div>
        <StdKindButton
          children="Add skin"
          onClick={() => {
            openSkinSearchDialog({
              submit: ({ price, ...skin }) => {
                op.mutValue((draft) => {
                  draft.push({ type: CaseSlotType.skin, skin, cost: price });
                });
              },
            });
          }}
        />
      </div>
    </div>
  );
};

type SlotFieldProps = {
  i: number;
  type: CaseSlotType;
};

export const SlotField = memo(function SlotField({ i, type }: SlotFieldProps): JSX.Element {
  const c = useCribContext();
  return (
    <div className="flex p2sa gap-2s">
      <div className="flex flex-col items-center gap-4s">
        <div className="tg-title">{i + 1}.</div>
        <DeleteOutlineIcon
          className="cursor-pointer"
          onClick={() => {
            c.mutData((draft) => {
              draft.slots = draft.slots.filter((_, x) => x !== i);
            });
          }}
        />
        <NavigateBeforeIcon
          className="cursor-pointer"
          onClick={() => {
            c.mutData((draft) => {
              if (i === 0) return;
              const tmp = draft.slots[i - 1];
              draft.slots[i - 1] = draft.slots[i];
              draft.slots[i] = tmp;
            });
          }}
        />
        <NavigateNextIcon
          className="cursor-pointer"
          onClick={() => {
            c.mutData((draft) => {
              if (i === draft.slots.length - 1) return;
              const tmp = draft.slots[i + 1];
              draft.slots[i + 1] = draft.slots[i];
              draft.slots[i] = tmp;
            });
          }}
        />
      </div>
      <div className="w40s">
        <SkinField name={`slots.${i}.skin`} />
        <StdFieldBox title="Cost">
          <CribNumberInputField name={`slots.${i}.cost`} />
        </StdFieldBox>
        <StdFieldBox title="Chance">
          <CribNumberInputField name={`slots.${i}.chance`} />
        </StdFieldBox>
      </div>
    </div>
  );
});

type SkinFieldProps = {
  name: string;
};

const SkinField = ({ name }: SkinFieldProps): JSX.Element => {
  const [{ value }, op] = useCribField(() => {
    return { name };
  });

  return (
    <SkinCard
      data={value}
      className="cursor-pointer"
      onClick={() => {
        openSkinSearchDialog({
          submit: (skin) => {
            op.mutValue((draft) => {
              draft.skin = skin;
            });
          },
        });
      }}
    />
  );
};

export const CaseAdd = (): JSX.Element => {
  const data: Rsa = {};

  if (__DEV__) {
    const suffix = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');
    Object.assign(data, {
      name: `test name ${suffix}`,
      about: `test about ${suffix}`,
    });
  }

  return (
    <div className="flex flex-col gap-4s">
      <div className="tg-title">Add Case</div>
      <CaseForm
        data={data}
        submit={async (body, image) => {
          const id = await apiReq({
            action: 'case.add',
            success: true,
            progress: true,
            body,
            attach: [image],
          });

          chronicle.push('/cases/' + id);
        }}
      />
    </div>
  );
};

export const CaseEdit = ({ id }: { id: string }): JSX.Element => {
  const [data, setData] = useState<Rsa>(null);

  useLaunch(async () => {
    setData(null);
    const { config, at, ...data } = await apiReq({ action: 'case.get', body: id });
    setData({ ...data, slots: config.slots, image: CloudFile.fromUrl(getCaseImageUrl(data.id)) });
  }, [id]);

  if (data === null) return <div>Loading...</div>;

  return (
    <div className="flex flex-col gap-4s">
      <div className="tg-title">Edit Case</div>
      <CaseForm
        data={data}
        submit={async (body, image) => {
          const attach = [];
          if (image !== data.image) attach.push(image);

          await apiReq({
            action: 'case.upd',
            success: true,
            progress: true,
            body,
            attach,
          });

          chronicle.push('/cases/' + id);
        }}
      />
    </div>
  );
};
