import { useSuss } from 'packs/libs/Suss';
import { StdInput } from 'packs/std';
import { useMemo } from 'react';
import { debounce } from 'packs/deps';

type StableQInputProps = {
  placeholder?: string;
};

export function StableQInput({ placeholder }: StableQInputProps) {
  const suss = useSuss();
  const onChange = useMemo(() => {
    const update = debounce((q: string) => {
      suss[1].updQuery({ q: q === '' ? undefined : q });
    }, 200);

    return (e) => {
      update(e.target.value);
    };
  }, []);

  return <StdInput placeholder={placeholder} onChange={onChange} />;
}
