import { css, cx } from '@emotion/css';
import { Modal } from '@mui/material';
import { debounce } from 'lodash';
import { SussProvider, SussProviderProps, useSuss } from 'packs/libs/Suss';
import { useSingleton } from 'packs/state/use-singleton';
import { StdInput } from 'packs/std';
import { ReactNode, useMemo, useState } from 'react';
import { IHashMap } from 'support/struct/i-hash-map';

type RenderResults = (result: IHashMap<string | number, any>) => React.ReactNode;

type SassyDialogProps = SussProviderProps &
  QSearchProps & {
    opened?: boolean;
    onClose(): void;
    className?: string;
    filters?: ReactNode;
    children: React.ReactNode | RenderResults;
  };

export const SassyDialog = ({
  opened = true,
  onClose,
  className,
  placeholder,
  children,
  filters,
  ...suss
}: SassyDialogProps): JSX.Element => {
  // @ts-ignore
  const results = typeof children === 'function' ? <Results render={children} /> : children;

  return (
    <SussProvider {...suss}>
      <Modal onClose={onClose} open={opened}>
        <div className={cx(boxClass, className)}>
          <div className={filtersClass}>
            <QSearch placeholder={placeholder} />
            {filters && <div>{filters}</div>}
          </div>
          <div className={resultsClass}>{results}</div>
        </div>
      </Modal>
    </SussProvider>
  );
};

type ResultProps = { render: RenderResults };
export const Results = (props: ResultProps): JSX.Element => {
  const suss = useSuss();
  return useMemo(() => {
    return <>{props.render(suss[0].data)}</>;
  }, [suss[0].data]);
};

type QSearchProps = {
  placeholder?: string;
};
const QSearch = ({ placeholder }: QSearchProps): JSX.Element => {
  const suss = useSuss();
  const [value, setValue] = useState<string>(suss[0].query.q);

  const onChange = useSingleton(() => {
    const dispatch = debounce((value) => {
      suss[1].updQuery({ q: value });
    }, 200);

    return (e) => {
      const val = e.target.value;
      setValue(val);
      dispatch(val);
    };
  });

  return useMemo(() => {
    return <StdInput autoFocus placeholder={placeholder} value={value} onChange={onChange} />;
  }, [value]);
};

const boxClass = css`
  border-radius: 3px;
  left: 50%;
  outline: none;
  position: fixed;
  top: 12%;
  transform: translateX(-50%);
  max-width: 60vw;
  background: white;
  padding: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

const resultsClass = css`
  overflow-y: auto;
`;

const filtersClass = css`
  display: flex;
  flex-direction: column;
`;

const contentClass = css`
  background: var(--grey-900);
`;
