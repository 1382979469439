import { css } from '@emotion/css';
import { apiReq, useApiReq } from 'packs/libs/api';
import { yup } from 'packs/deps';
import { FmkForm, FmkNumberInputGroup, FmkSubmitButton } from 'packs/fmk';
import { StdKindButton, StdLoading, StdPaper } from 'packs/std';

export type UpgradeConfigData = {
  rtp: number; // 90
  maxChance: number; // 90
  streak: {
    rtp: number;
    chance: number;
    steps: number;
  };
};

const schema = yup.object({
  rtp: yup.number().integer().min(0).max(100),
  // https://stackoverflow.com/a/56247127
  maxChance: yup.number().integer(),
  streak: yup.object({
    rtp: yup.number().integer(),
    chance: yup.number().integer(),
    steps: yup.number().integer().min(0),
  }),
});

export const UpgradeConfigBlock = () => {
  const initialValue = useApiReq(() => ({
    action: 'upgrade.config.get',
      }));

  const compose = () => {
    if (initialValue === undefined) return <StdLoading />;

    const submit = async (value) => {
      await apiReq({
        action: 'upgrade.config.set',
        body: value,
        success: true,
      });
    };

    return (
      <FmkForm
        validationSchema={schema}
        initialValues={initialValue}
        simpleSubmit={submit}
        className="flex flex-col gap-4s"
      >
        <FmkNumberInputGroup label="RTP" name="rtp" />
        <FmkNumberInputGroup label="Max Chance" name="maxChance" />
        <FmkNumberInputGroup label="Streak RTP" name="streak.rtp" />
        <FmkNumberInputGroup label="Streak Chance" name="streak.chance" />
        <FmkNumberInputGroup label="Streak Steps" name="streak.steps" />
        <div className="flex flex-row-reverse">
          <FmkSubmitButton children="Save" />
        </div>
      </FmkForm>
    );
  };

  return (
    <div className="flex flex-col gap-4s">
      <div className="std-paper p4sa w80s">
        <div className="tg-title p4sb">Upgrade Config</div>
        {compose()}
      </div>
    </div>
  );
};

const mainClass = css``;
