import { css, cx } from '@emotion/css';
import { cc, el } from 'fnd/bridge/react';
import React, { forwardRef, ReactNode } from 'react';
import './styles.scss';

export type StdInputProps = React.InputHTMLAttributes<HTMLInputElement> & {};

export const StdInput = forwardRef<HTMLInputElement, StdInputProps>(function StdInput(
  { className, ...props },
  ref
) {
  return el('input', {
    ...props,
    ref,
    className: cx(inputClass, className),
  });
});

export type StdInputGroupProps = StdInputProps & {
  label?: ReactNode;
  about?: ReactNode;
  error?: any;
};

export function StdInputGroup({ label, className, error, about, ...props }: StdInputGroupProps) {
  return (
    <div className={cx(groupClass, className)}>
      <label>{label}</label>
      {about && <div className="tg-caption">{about}</div>}
      <StdInput {...props} />
      {error && <span className={errorMessageClass}>{error}</span>}
    </div>
  );
}

const inputClass = css`
  background: transparent;
  font-size: 14px;
  border-radius: 5px;
  color: var(--dark-blue-500);
  outline: none;
  padding: 11px 15px 10px;
  width: 100%;
  display: block;
  border: 1px solid var(--c-grey);
  transition: all ease-in-out 0.2s;

  &:focus {
    outline: none;
    border-color: var(--c-blue);
  }

  &.error {
    border-color: var(--c-red);
  }
`;

const groupClass = css`
  position: relative;
  width: 100%;
`;

const groupLabelClass = css`
  color: var(--dark-blue-100);
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 0;
  transition: 0.2s ease all;
`;

const errorMessageClass = css`
  color: var(--c-red);
  font-size: 12px;
`;

export type StdTextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  error?: string;
};

export function StdTextArea({ className, error, ...props }: StdTextAreaProps) {
  return el('textarea', {
    ...props,
    className: cc('std-input', className, { error }),
  });
}
