import { css } from '@emotion/css';
import { CribInputField, CribSimpleForm, CribSubmitButton } from 'packs/crib';
import { CribPrimitiveSelect } from 'packs/crib/fields/primitive-select';
import { apiReq } from 'packs/libs/api';
import { StdFieldBox, StdKindButton } from 'packs/std';
import { Swiss, useSwissState } from 'support/react/swiss';
import { useLaunch } from 'support/react/use-launch';

export const WithdrawalAccountPage = (): JSX.Element => {
  const [data, op] = useSwissState<any[]>([]);
  useLaunch(async () => {
    op.setState(await apiReq({ action: 'withdrawal.account.list' }));
  }, []);

  return (
    <div className="flex flex-col gap-4s">
      <div className="flex gap-4s flex-wrap">
        {data.map((data) => {
          return <EditCard key={data.id} data={data} op={op} />;
        })}

        <div>
          <div className="std-paper p4sa">
            <Form
              key={data.length}
              data={{}}
              submit={async (input) => {
                const data = await apiReq({ action: 'withdrawal.account.add', body: input });
                op.mutState((draft) => {
                  draft.push(data);
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type Data = Rsa;
type EditCardProps = {
  data: Data;
  op: Swiss<Data[]>;
};

const EditCard = ({ data, op }: EditCardProps): JSX.Element => {
  return (
    <div className="std-paper p4sa flex flex-col gap-4s">
      <StdKindButton
        className="w-full"
        kind={data.current ? 'a' : 'ah'}
        children="current"
        onClick={async () => {
          await apiReq({ action: 'withdrawal.account.current.set', body: { id: data.id } });
          op.mutState((draft) => {
            for (const item of draft) {
              if (item.id === data.id) item.current = true;
              else item.current = false;
            }
          });
        }}
      />
      <div className="tg-h6">
        <span>balance: </span>
        <span>${data.balance}</span>
      </div>
      <Form
        edit
        key={data.id}
        data={data}
        submit={async (input) => {
          const data = await apiReq({ action: 'withdrawal.account.upd', body: input });
          op.mutState((draft) => {
            draft.push(data);
          });
        }}
      />
    </div>
  );
};

type FormProps = {
  data: Rsa;
  submit: (data: any) => Promise<void>;
  edit?: boolean;
};

const Form = (props: FormProps): JSX.Element => {
  const doSubmit = async (data) => {
    props.submit(data);
  };

  return (
    <CribSimpleForm submit={doSubmit} initialData={props.data} className="flex flex-col gap-4s">
      <StdFieldBox title="Name">
        <CribInputField name="name" required />
      </StdFieldBox>
      <StdFieldBox title="Token">
        <CribInputField name="token" required={!props.edit} />
      </StdFieldBox>
      <StdFieldBox title="Stage">
        <CribPrimitiveSelect className="w-full" name="stage" options={['active', 'disable']} />
      </StdFieldBox>
      <StdFieldBox title="Vendor">
        <CribInputField name="vendor" required />
      </StdFieldBox>
      <CribSubmitButton size="md" children={props.edit ? 'Update' : 'Add'} />
    </CribSimpleForm>
  );
};

const cardClass = css`
  background: #f9f9f9;
`;

const currentClass = css`
  ${cardClass}
  background: #f9fffb;
`;
