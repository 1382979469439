import { el } from 'fnd/bridge/react';
import { useField } from 'formik';
import { StdInputGroup, StdInputGroupProps } from 'packs/std';
import styled from 'styled-components';

type InputProps = StdInputGroupProps & {
  name: string;
  type?: string;
  label?: string
};

export function FmkInputGroup({name, type = 'text', className, ...props}: InputProps) {
  const [field, meta] = useField(name);
  const error = meta.touched && meta.error;

  return (
    <Stretch>
      {el(StdInputGroup, {
        ...props,
        ...field,
        error,
        type,
        name,
      })}
    </Stretch>
  );
}

const Stretch = styled.div`
  width: 100%;
`;
