import { createElement, ImgHTMLAttributes } from 'react';

type SkinImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  size?: 'medium' | 'full';
  code?: string;
};

export const SkinImage = (props: SkinImageProps): JSX.Element => {
  return createElement('img', {
    ...props,
    // Old API gave only partial URL
    // ByMykel returns full URLs, thus making this module obsolete
    // src: `https://steamcommunity-a.akamaihd.net/economy/image/${props.code}`,
    src: props.code,
  });
};
