import React from 'react';
import components from './components';

import styles from './styles';
import {SelectComponentsProps} from 'react-select/base';

export type SelectProps<T = any, V = unknown> = {
  options: T[];
  value: V;
  onChange(value: T): void;
  getLabel?(option: T): string;
  getValue?(option: T): V;
  placeholder?: string;
};

const rsa: Partial<SelectComponentsProps> = {
  // components, 
  // styles,
  menuPosition: 'absolute',
  menuPlacement: 'bottom',
  menuPortalTarget: document.body,
  menuShouldScrollIntoView: false,
};

export default rsa;
