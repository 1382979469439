export function readBlobAsBinaryString(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onabort = () => {
      reject(new Error('aborted'));
    };
    reader.onerror = () => {
      reject(new Error('error'));
    };
    reader.onload = (e) => {
      console.log(e);
      if (typeof e.returnValue) {
        // @ts-ignore
        resolve(e.target.result);
      } else {
        reject(new Error('something wrong'));
      }
    };
    reader.readAsBinaryString(blob);
  });
}

export function createImageFromUrl(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.onabort = reject;
    image.onchange = console.log
    image.src = url;
  });
}

export async function urlToBin(url: string): Promise<string> {
  const res = await fetch(url);
  return res.text();
}

export async function urlToBlob(url: string): Promise<Blob> {
  return await (await fetch(url)).blob();
}
