import { StdKindButton, StdKindButtonProps } from 'packs/std';
import * as React from 'react';
import styled from 'styled-components';

type ButtonSwitchProps = {
  value: boolean;
  onChange(value: boolean);
  falsy?: React.ReactNode;
  truly?: React.ReactNode;
  size?: StdKindButtonProps['size'];
};

export function StdButtonSwitch({ value, onChange, falsy, truly, size }: ButtonSwitchProps) {
  const cn = (active: boolean) => (active ? 'a' : 'ah');

  return (
    <MainDiv
      onClick={() => {
        onChange(!value);
      }}
    >
      <StdKindButton size={size} children={truly ?? 'Enabled'} kind={cn(value)} />
      <StdKindButton size={size} children={falsy ?? 'Disabled'} kind={cn(!value)} />
    </MainDiv>
  );
}

const MainDiv = styled.div`
  display: flex;

  > * {
    flex: 1;
  }
`;
