import { css } from '@emotion/css';
import { PlayerImage } from 'domain/player/image';
import { PlayerRestrictionScope } from 'domain/player/restrictions';
import { juxProp } from 'fnd/juxtapose';
import { QuartzFooter, QuartzQInput } from 'packs/blocks/quartz';
import { Stable, StableSortHead } from 'packs/blocks/suss-table';
import { SussProvider } from 'packs/libs/Suss';
import { apiReq } from 'packs/libs/api';
import { CopyIcon } from 'packs/single/copy-icon';
import { ShortSpan } from 'packs/single/short-span';
import { StdLink } from 'packs/std';
import { StdTime } from 'packs/std/time/format';
import { memo } from 'react';

const request = (query, context) => {
  return apiReq({
    action: 'player.search',
    body: { ...query, ...context },
  });
};

export const PlayerSearchPage = (): JSX.Element => {
  return (
    <SussProvider
      query={{ sort: [['lastLoginAt', 'desc']], include: ['restrictions', 'extra'] }}
      request={request}
    >
      <div className="flex flex-col gap-4s w320s">
        <div className="std-paper p4sa flex gap-4s items-center">
          <QuartzQInput name="q" placeholder="id || name || steamId" />
        </div>
        <Stable Row={Row}>
          <th></th>
          <th>Id</th>
          <th>Name</th>
          <th>SteamId</th>
          <StableSortHead name="signUpAt">Sign Up</StableSortHead>
          <StableSortHead name="lastLoginAt">Last Sign In</StableSortHead>
          <th>Restrictions</th>
        </Stable>
        <QuartzFooter />
      </div>
    </SussProvider>
  );
};

type RowProps = {
  data: {
    id: string;
    name: string;
    steamId: string;
    image: string;
    signUpAt: number;
    lastLoginAt: number;
    restrictions?: Record<PlayerRestrictionScope, boolean>;
  };
};

const PlayerRestrictionScopeValues = Object.values(PlayerRestrictionScope);

const Row = memo(({ data }: RowProps): JSX.Element => {
  const id = data.id;
  const restrictions = data.restrictions ?? {};
  return (
    <tr>
      <td className={imageCellClass}>
        {/* <div className={imageBoxClass}> */}
        <PlayerImage className={imageClass} code={data.image} />
        {/* </div> */}
      </td>
      <td>
        <CopyIcon copy={id} />
        <StdLink to={`/players/${data.id}`}>
          <ShortSpan max={12} rl={8}>
            {id}
          </ShortSpan>
        </StdLink>
      </td>
      <td>{data.name}</td>
      <td>
        <ShortSpan max={8}>{data.steamId}</ShortSpan>
      </td>
      <td>{StdTime.ms.datetime(data.signUpAt)}</td>
      <td>{StdTime.ms.datetime(data.lastLoginAt)}</td>
      <td>
        <div className="flex gap-1s">
          {Object.values(PlayerRestrictionScopeValues).map((scope) => {
            const value = restrictions[scope];
            return (
              <span title={scope} className={value ? trueScopeClass : falseScopeClass}>
                {scope[0]}
              </span>
            );
          })}
        </div>
      </td>
    </tr>
  );
}, juxProp('data'));

const imageCellClass = css`
  padding: 0 !important;
  position: relative;
  width: 50px;
`;

const imageClass = css`
  position: absolute;
  padding: 2px;
  height: 50px;
  border-radius: 6px;
`;

const restrictionScopeClass = css`
  color: white;
  font-weight: 600;
  border-radius: 3px;
  padding: 0 0.3rem 0 0.2rem;
  text-transform: uppercase;
  user-select: none;
`;

const falseScopeClass = css`
  ${restrictionScopeClass}
  background-color:#1ab51aef
`;

const trueScopeClass = css`
  ${restrictionScopeClass}
  background-color: #e70000;
`;
