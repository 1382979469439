import { appSyringe } from 'app/syringe';
import { yup } from 'packs/deps';
import { StdDialog, StdInput, StdKindButton } from 'packs/std';
import { useState } from 'react';

type Opts = Rsa & {
  value?: string
  type?: string,
  label: string,
  validate: yup.AnySchema,
  submit(value: string)
}

export function fancyInputDialog({
  value, type, label, validate, submit,
}: Opts) {
  appSyringe(
    function FancyFieldDialog({ onClose }) {
      const [val, setVal] = useState<string>(value);

      return (
        <StdDialog
          onClose={onClose}
          className="p6sa flex flex-col gap-4s"
        >
          <div className="flex flex-col">
            <div>{label}</div>
            <StdInput
              type={type}
              onChange={e => {
                const val = e.target.value;
                setVal(val);
              }}
            />
          </div>
          <div className="flex justify-end">
            <StdKindButton
              kind={'a'}
              children={'submit'}
              onClick={async () => {
                await submit(val);
                onClose();
              }}
            />
          </div>
        </StdDialog>
      );
    },
  );
}