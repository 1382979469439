import { appSyringe } from 'app/syringe';
import { openSkinSearchDialog } from 'domain/skin/search';
import { CribNumberInputField, CribSimpleForm, CribSubmitButton } from 'packs/crib';
import { CribAutoTextArea } from 'packs/crib/fields/textarea';
import { yup } from 'packs/deps';
import { FmkForm, FmkInputGroup, FmkNumberInputGroup, FmkSubmitButton } from 'packs/fmk';
import { apiReq } from 'packs/libs/api';
import { StdDialog, StdFieldBox, StdKindButton } from 'packs/std';
import { StdPopover } from 'packs/std/layouts/popover';
import { z } from 'zod';

type PlayerDetailsRewardBlockProps = {
  playerId: string;
};

export const PlayerDetailsRewardBlock = ({
  playerId,
}: PlayerDetailsRewardBlockProps): JSX.Element => {
  return (
    <div className="std-paper p2sa flex flex-col gap-2s">
      <StdKindButton
        kind="ah"
        children="Give Cash"
        onClick={(e) =>
          openCashPop(e, async (data) => {
            await apiReq({
              action: 'player.give.cash',
              body: { playerId, ...data },
              success: true,
            });
          })
        }
      />
      <StdKindButton
        kind="ah"
        children="Take Cash"
        onClick={(e) => {
          openCashPop(e, async (data) => {
            await apiReq({
              action: 'player.take.cash',
              body: { playerId, ...data },
              success: true,
            });
          });
        }}
      />
      <StdKindButton kind="ah" children="Give Skin" onClick={() => openGiveSkinDialog(playerId)} />
      <StdKindButton
        kind="ah"
        children="Give Deposit"
        onClick={(e) => {
          openCashPop(e, async (data) => {
            await apiReq({
              action: 'player.give.deposit',
              body: { playerId, ...data },
              success: true,
            });
          });
        }}
      />
    </div>
  );
};

const openGiveDepositDialog = (playerId: string) => {
  appSyringe(function RewardCashDialog({ onClose }) {
    return (
      <StdDialog onClose={onClose}>
        <FmkForm
          className="p4sa flex flex-col gap-4s"
          initialValues={{ cash: ENV_SANDBOX ? 10000 : 0, note: '' }}
          validationSchema={yup.object({
            cash: yup.number().positive().required(),
            note: yup.string().optional(),
          })}
          simpleSubmit={(data) => {
            apiReq({
              action: 'player.give.deposit',
              body: { playerId, ...data },
              success: true,
            });
            onClose();
          }}
        >
          <FmkNumberInputGroup autoFocus label="Cash" name="cash" />
          <FmkInputGroup label="Note (optional)" name="note" />
          <FmkSubmitButton children="Submit" />
        </FmkForm>
      </StdDialog>
    );
  });
};

const openCashPop = (e, submit: (data: any) => Promise<void>) => {
  appSyringe(function CashPop({ onClose }) {
    return (
      <StdPopover
        open
        onClose={onClose}
        anchorEl={e.target}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CribSimpleForm
          className="p4sa flex flex-col gap-4s"
          initialData={{
            cash: ENV_SANDBOX ? 10000 : 0,
          }}
          submit={async (data) => {
            await submit(data);
            onClose();
          }}
        >
          <StdFieldBox title="Cash">
            <CribNumberInputField name="cash" autoFocus schema={z.number().positive()} />
          </StdFieldBox>
          <StdFieldBox title="Note (optional)">
            <CribAutoTextArea schema={z.string().optional()} name="note" />
          </StdFieldBox>
          <CribSubmitButton />
        </CribSimpleForm>
      </StdPopover>
    );
  });
};

const openGiveSkinDialog = (playerId: string) => {
  openSkinSearchDialog({
    submit({ id: skinId, price }) {
      appSyringe(function RewardCashDialog({ onClose }) {
        return (
          <StdDialog onClose={onClose}>
            <FmkForm
              className="p4sa flex flex-col gap-4s"
              initialValues={{ description: '' }}
              validationSchema={yup.object({
                cost: yup.number().positive().optional(),
                description: yup.string().optional(),
              })}
              simpleSubmit={(data) => {
                apiReq({
                  action: 'player.give.skin',
                  body: { playerId, skinId, cost: price, ...data },
                  success: true,
                });
                onClose();
              }}
            >
              <FmkInputGroup autoFocus label="Note (optional)" name="note" />
              <FmkNumberInputGroup
                label="Cost (optional, exact number how much skin cost)"
                name="cost"
              />
              <FmkSubmitButton children="Submit" />
            </FmkForm>
          </StdDialog>
        );
      });
    },
  });
};
