import { css } from '@emotion/css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { infNote } from 'fnd/inf/Inf';
import { toast, TypeOptions } from 'react-toastify';

type CopyIconProps = {
  copy: string;
};

export const CopyIcon = ({ copy }: CopyIconProps): JSX.Element => {
  return (
    <ContentCopyIcon
      className={mainClass}
      onClick={() => {
        navigator.clipboard.writeText(copy);
        toast('copied', {
          type: 'info',
          position: 'bottom-right',
          autoClose: 1000,
          hideProgressBar: true,
        });
      }}
    />
  );
};

const mainClass = css`
  cursor: pointer;
  color: var(--text-40);
  font-size: 1em !important;
`;
