import { PaymentRegionDetailsPage } from 'app/payment/region/details';
import { PaymentRegionAddPage } from 'app/payment/region/form';
import { PaymentRegionSearchPage } from 'app/payment/region/search-page';
import { StdJunction } from 'packs/std';

export const PaymentRegionJunction = (): JSX.Element => {
  return (
    <StdJunction
      routes={[
        ['add', <PaymentRegionAddPage />],
        [':regionId', <PaymentRegionDetailsPage />],
        ['', <PaymentRegionSearchPage />],
      ]}
    />
  );
};
