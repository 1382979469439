import { SUPPORTED_LOCALES } from 'fnd/l10n';
import { CribInputField } from 'packs/crib/fields/input';
import { ZodTypeAny } from 'zod';

type CribLangsInputProps = {
  name: string;
  schema?: ZodTypeAny | (() => ZodTypeAny);
};

export const CribLangsInputField = ({ name, schema }: CribLangsInputProps): JSX.Element => {
  return (
    <div>
      {SUPPORTED_LOCALES.map((locale) => {
        return (
          <div key={locale}>
            <label>{locale}</label>
            <CribInputField name={`${name}.${locale}`} schema={schema} />
          </div>
        );
      })}
    </div>
  );
};
