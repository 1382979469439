export enum QuestChapterPeriod {
  day = 'd',
  week = 'w',
  month = 'm'
}

export const QuestChapterPeriodTitleEntries: [QuestChapterPeriod, string][] = [
  [QuestChapterPeriod.day, 'Daily'],
  [QuestChapterPeriod.week, 'Weekly'],
  [QuestChapterPeriod.month, 'Monthly'],
];
