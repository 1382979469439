import { WithdrawalSearchCard, WithdrawalSearchCardOpts } from 'app/withdrawal/search/card';
import { WithdrawalSearchDetailed } from 'app/withdrawal/search/detailed';
import { WithdrawalSearchFilters } from 'app/withdrawal/search/filters';
import { QuartzFooter } from 'packs/blocks/quartz';
import { SussProvider, useSuss } from 'packs/libs/Suss';
import { apiReq } from 'packs/libs/api';
import { useMemo } from 'react';

const request = (query, context) => {
  return apiReq({
    action: 'withdrawal.search',
    progress: true,
    body: { ...query, ...context },
  });
};

type WithdrawalSearchBlockProps = {
  playerId?: string;
};

export const WithdrawalSearchBlock = (props: WithdrawalSearchBlockProps): JSX.Element => {
  const query: Rsa = { sort: [['enabled', 'desc']] };
  if (props.playerId) query.playerId = props.playerId;

  const opts = {
    includePlayer: props.playerId === undefined,
  };

  return (
    <div className="flex flex-col gap-4s">
      <div className="flex flex-col gap-4s">
        <SussProvider query={query} request={request}>
          <WithdrawalSearchFilters />
          <div className="flex gap-4s">
            <div>
              <List cardOpts={opts} />
            </div>
            <WithdrawalSearchDetailed />
          </div>
          <div className="flex flex-col gap-4s w130s">
            <QuartzFooter />
          </div>
        </SussProvider>
      </div>
    </div>
  );
};

type ListProps = {
  cardOpts: WithdrawalSearchCardOpts;
};
const List = ({ cardOpts }: ListProps): JSX.Element => {
  const [{ data, stash }, op] = useSuss();

  const selected = stash.detailed;
  return useMemo(() => {
    const onSelect = (id: string) => {
      op.stash.updState({ detailed: id });
    };
    return (
      <div className="flex flex-col gap-2s">
        {data.listMapV((item) => {
          return (
            <WithdrawalSearchCard
              key={item.id}
              data={item}
              opts={cardOpts}
              selected={selected === item.id}
              onSelect={onSelect}
              op={op}
            />
          );
        })}
      </div>
    );
  }, [data, selected]);
};
