import './atom.colors.scss';
import './atom.flex.scss';
import './atom.position.scss';
import './atom.word.scss';
import './atom.etc.scss';
import './atom.typography.scss';

import spacing from './atom.spacing';
import grid from './atom.grid';
import { injectGlobal } from '@emotion/css';

injectGlobal(spacing, grid);
