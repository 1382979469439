import { useActor } from 'domain/actor';
import { useJustEffect } from 'fnd/bridge/react';
import { chronicle } from 'fnd/bridge/router';
import { yup } from 'packs/deps';
import { apiReq } from 'packs/libs/api';
import { useSussReload } from 'packs/libs/Suss';
import { fancyConfirmation } from 'packs/single/fancy-confirmation';
import { fancyInputDialog } from 'packs/single/fancy-input-dialog';
import { useSwissState } from 'packs/state/swiss-store';
import { StdKindButton } from 'packs/std';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

type UserData = {
  id: string
  email: string
  roleId: string
}

export function EmployeeAreaEdit() {
  const actor = useActor();
  const { employeeId } = useParams<{ employeeId: string | undefined }>();
  const reloadSuss = useSussReload();

  const [state, op] = useSwissState<UserData | null>(null);

  useJustEffect(async () => {
    op.setState(null);

    if (!employeeId) return;

    const user = await apiReq({
      action: 'employee.get',
      query: { id: employeeId },
    });
    op.setState(user);
  }, [employeeId]);

  return useMemo(() => {
    if (state === null) return null;

    const { id, email, roleId } = state;

    const canAdmin = actor.can('employee.admin');
    const canEdit = actor.can('employee.write');

    return (
      <div>
        <div className="std-paper p2sa">
          <div className="tg-caption p1sa">
            {id}
          </div>
          <div className="p2sa">
            {email}
          </div>
          {canEdit && (
            <div className="p2sa">
              <StdKindButton
                kind={'ah'}
                children={'change password'}
                onClick={() => {
                  fancyInputDialog({
                    type: 'password',
                    label: 'New Password',
                    validate: yup.string().min(8).max(256),
                    async submit(pwd: string) {
                      await apiReq({
                        action: 'employee.pwd.set',
                        body: { id, pwd },
                        success: true,
                      });
                    },
                  });
                }}
              />
            </div>
          )}
          <div className="p2sa">
            {canEdit && (
              <StdKindButton
                kind={'danger-h'}
                children={'delete'}
                onClick={() => {
                  fancyConfirmation({
                    async onOk() {
                      await apiReq({
                        action: 'employee.del',
                        body: { id },
                        success: true,
                      });
                      chronicle.replace('/employees');
                      reloadSuss();
                    },
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }, [state]);
}


