import { StdInput, StdInputProps } from 'packs/std';
import React from 'react';
import { useField } from 'formik';
import { cc, el } from 'fnd/bridge/react';

type InputProps = StdInputProps & {
  name: string;
  type?: string;
  label?: string
};

export function FmkInput({ name, className, ...props }: InputProps) {
  const [field, meta] = useField(name);
  const error = meta.touched && meta.error;

  return el(StdInput, {
    className: cc('w-full', className, { error }),
    ...props, ...field, name,
  });
}
