import { cc, useReverseMemo } from 'fnd/bridge/react';
import { QuartzQInput } from 'packs/blocks/quartz/QuartzQInput';
import { useSuss } from 'packs/libs/Suss';
import React, { ComponentType } from 'react';

type QuartzFilterCreatorProps<T, O = undefined> = React.ComponentType<{
  apply(data: T): void
  options: O
  data: T
}>

type QuartzFilterTagProps<T> = React.ComponentType<{
  data: T
}>

type QuartzFilter<T, O> = OrFactory<{
  creator: ComponentType<QuartzFilterCreatorProps<T, O>>
}>

type QuartzFilterZoneProps = {
  qInput?: string
  filters?: OrFactory<Record<string, QuartzFilter<unknown, unknown>>>
  className?: string
}

export function QuartzFilterZone({
  filters, qInput, className
}: QuartzFilterZoneProps) {
  const [{query}] = useSuss();
  // const [opened, setOpened] = useState(false);

  return useReverseMemo([
    query,
  ], () => {
    // const filtersRecord = execIfFn(filters ?? []);
    // const filterList = Object.entries(filtersRecord);
    //
    // const open = () => setOpened(true);
    // const close = () => setOpened(false);

    return (
      <div className={cc('std-paper p2sy p3sx flex', className)}>
        {qInput && (
          <QuartzQInput className={'w80s'} placeholder={qInput}/>
        )}
        {/*<SimplePopover*/}
        {/*  drop={<ListDrop/>}*/}
        {/*  opened={opened}*/}
        {/*  onClose={close}*/}
        {/*>*/}
        {/*  <div>open</div>*/}
        {/*</SimplePopover>*/}
      </div>
    );
  });
}

type ListDropProps = {}

function ListDrop({}: ListDropProps) {
  return (
    <div>

    </div>
  );
}

type ItemDropProps = {}

function ItemDrop({}: ItemDropProps) {


  return (
    <div>

    </div>
  );
}

