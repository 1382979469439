import { cx } from '@emotion/css';
import { ReactNode } from 'react';

type StdFieldBoxProps = {
  title: ReactNode;
  about?: ReactNode;
  children: ReactNode;
  className?: string;
  level?: number;
};

export const StdFieldBox = ({
  title,
  about,
  children,
  className,
  level,
}: StdFieldBoxProps): JSX.Element => {
  return (
    <div className={className}>
      <div className={cx(Levels.get(level), 'p1sb')}>{title}</div>
      {about && <div className="tg-caption1">{about}</div>}
      <div>{children}</div>
    </div>
  );
};

const Levels = new Map([
  [1, 'tg-title2'],
  [2, 'tg-subtitle2'],
]);
Levels.set(undefined, Levels.get(1));
