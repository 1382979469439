import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import cronstrue from 'cronstrue';
import { QuartzFooter } from 'packs/blocks/quartz';
import { Stable, StableSortHead } from 'packs/blocks/suss-table';
import { SussProvider } from 'packs/libs/Suss';
import { apiReq } from 'packs/libs/api';
import { StdKindButton, StdLink } from 'packs/std';
import { StdTime } from 'packs/std/time/format';
import { Link } from 'react-router-dom';

const request = (query, context) => {
  return apiReq({
    action: 'contest.template.search',
    body: { ...query, ...context },
  });
};

export const ContestTemplateSearchPage = (): JsxElement => {
  return (
    <div className="flex flex-col gap-4s">
      <div className="flex gap-4s">
        <Link to="/contests/templates/add">
          <StdKindButton size="lg" children="new template" />
        </Link>

        <StdKindButton
          kind="ah"
          size="lg"
          children="force run templates"
          onClick={async () => {
            await apiReq({
              action: 'contest.template.proceed',
              success: true,
            });
          }}
        />
      </div>
      <div className="w260s">
        <SussProvider
          request={request}
          query={{
            sort: [
              ['enabled', 'desc'],
              ['created', 'desc'],
            ],
          }}
        >
          <Stable Row={Row}>
            <th>Id</th>
            <th>Title</th>
            <th>Cron</th>
            <StableSortHead name="created">Created</StableSortHead>
            <StableSortHead name="enabled">Enabled</StableSortHead>
            <th></th>
          </Stable>
          <QuartzFooter />
        </SussProvider>
      </div>
    </div>
  );
};

type RowProps = {
  data;
};

const Row = ({ data }: RowProps): JsxElement => {
  return (
    <tr style={{ background: data.enabled ? '#d9ffc550' : '#f9f9f9' }}>
      <td>
        <StdLink to={`/contests/templates/${data.id}`}>{data.id}</StdLink>
      </td>
      <td>
        <div title={data.note} className="truncate">
          {data.title.en}
        </div>
      </td>
      <td>{cronstrue.toString(data.cron)}</td>
      <td>{StdTime.ms.datetime(data.created)}</td>
      <td>{String(data.enabled)}</td>
      <td>
        <StdLink
          title={'start creating contest using this template'}
          to={`/contests/add/from-template/${data.id}`}
        >
          <ContentPasteGoIcon />
        </StdLink>
      </td>
    </tr>
  );
};
