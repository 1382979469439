import { AuthMain } from 'app/public/auth/auth.main';
import { Redirect, Route, Switch } from 'react-router-dom';
import { OauthAnon } from 'app/public/oauth';

export function PublicApp() {
  return (
    <Switch>
      <Route path="/sign-in" component={AuthMain}/>
      <Route path="/oauth" component={OauthAnon}/>
      <Redirect to="/sign-in"/>
    </Switch>
  );
}
