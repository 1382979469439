import { stashGet } from 'support/memory/stash';

type Listener<T> = (value: T) => any;

export class SimpleEventTarget<T = void> extends Set<Listener<T>> {
  emit(value: T): void {
    for (const listener of this.values()) listener(value);
  }

  @stashGet
  get sub() {
    return (listener: Listener<T>) => {
      this.add(listener);
      return () => {
        this.delete(listener);
      };
    };
  }
}
