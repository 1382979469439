import MenuIcon from '@material-ui/icons/Menu';
import { ProgressLine } from 'app/layout/ProgressLine';
import { HeaderAccountBlock } from 'app/layout/header/HeaderAccountBlock';
import { HeaderClock } from 'app/layout/header/HeaderClock';
import { toggleMenu } from 'app/layout/menu/AdminMenu';
import { openPlayerSearchDialog } from 'domain/player/player-search';
import { voidMemo } from 'fnd/bridge/react';
import { chronicle } from 'fnd/bridge/router';
import { StdKindButton } from 'packs/std';

export const AdminHeader = voidMemo(function AdminHeader() {
  return (
    <div className="w-full flex justify-between items-center h8s relative p2sx">
      <ProgressLine />
      <div className="flex items-center gap-4s">
        <div className={'flex items-center gap-4s w60s'}>
          <MenuIcon className={'cp text-10'} onClick={toggleMenu} />
          <HeaderClock />
        </div>
        <StdKindButton
          size="xs"
          onClick={() => {
            openPlayerSearchDialog({
              submit: (data) => {
                chronicle.push(`/players/${data.id}`);
              },
            });
          }}
        >
          SEARCH PLAYER [ctrl+alt+p]
        </StdKindButton>
      </div>
      <HeaderAccountBlock />
    </div>
  );
});
