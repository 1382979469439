import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useCribField } from 'packs/crib/store';
import { CribErrorBox } from 'packs/crib/units/error-box';
import { ReactNode } from 'react';
import execIfFn from 'support/execIfFn';
import { ZodTypeAny } from 'zod';

type CribPrimitiveSelectProps = {
  name: string;
  schema?: OrFactory<ZodTypeAny>;
  options: any[];
  getOptionValue?(option: any): ReactNode;
  getOptionLabel?(option: any): ReactNode;
  initialValue?: any;
  className?: string;
};

const pipe = (v) => v;

export const CribPrimitiveSelect = (props: CribPrimitiveSelectProps): JSX.Element => {
  const [{ value, error }, op] = useCribField(() => {
    const schema = execIfFn(props.schema);
    return { name: props.name, schema, initialValue: props.initialValue };
  });

  const { getOptionValue = pipe, getOptionLabel = pipe } = props;
  // FIXME: Spread children are not supported in React.
  return (
    <div className={props.className}>
      <Select
        className="w-full"
        value={value}
        onChange={(e) => {
          op.setValue(e.target.value);
        }}
      >
        {props.options.map((option) => {
          const value = getOptionValue(option);
          return (
            <MenuItem key={value} value={value}>
              {getOptionLabel(option)}
            </MenuItem>
          );
        })}
      </Select>
      <CribErrorBox error={error} />
    </div>
  );
};
