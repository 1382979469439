import { PlayerRole } from 'domain/player/definitions';
import { PlayerImage } from 'domain/player/image';
import { enumOp } from 'fnd/support/enum-operator';
import { Link } from 'react-router-dom';

type PlayerSimpleCardProps = {
  className?: string;
  data: {
    id: string;
    name: string;
    image: string;
    role: string;
  };
};

export const PlayerSimpleCard = ({ data }: PlayerSimpleCardProps): JSX.Element => {
  return (
    <div className="flex w70s gap-2s">
      <Link to={`/players/${data.id}`} target="_blank">
        <div className="rounded-md">
          <PlayerImage className="h20s" code={data.image} />
        </div>
      </Link>
      <div>
        <div>{data.name}</div>
        <div>{enumOp(PlayerRole).valKeyMap.get(data.role as PlayerRole)}</div>
      </div>
    </div>
  );
};
