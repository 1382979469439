import {FmkForm, FmkInputGroup, FmkSubmitButton, FmkWrap, useFmkConfig} from 'packs/fmk';
import {SelectEmployeeRoleField} from 'domain/employee/role/SelectEmployeeRole';
import {StdPaper} from 'packs/std';
import styled from 'styled-components';
import {apiReq} from 'packs/libs/api';
import {chronicle} from 'fnd/bridge/router';
import {useSussReload} from 'packs/libs/Suss';

export function EmployeeAddArea() {
  const reloadSuss = useSussReload();
  const config = useFmkConfig(yup => {
    return {
      initialValues: {
        email: '',
        password: '',
        role: undefined,
      },
      validationSchema: yup.object({
        email: yup.string().email().required(),
        password: yup.string().required(),
      }),
      async simpleSubmit({role, ...data}: any) {
        const {id} = await apiReq({
          action: 'employee.add',
          body: {...data, roleId: role.id},
                  });
        chronicle.push(`/employees/${id}`);
        reloadSuss();
      },
    };
  });

  return (
    <StdPaper className='p4sa'>
      <TitleDiv>Add employee</TitleDiv>
      <FmkForm {...config}>
        <FmkWrap label={'Email'}>
          <FmkInputGroup name={'email'} type={'email'}/>
        </FmkWrap>
        <FmkWrap label={'Password'}>
          <FmkInputGroup type={'password'} name={'password'}/>
        </FmkWrap>
        <FmkWrap label={'Role'}>
          <SelectEmployeeRoleField name={'role'}/>
        </FmkWrap>
        <FmkSubmitButton>
          Submit
        </FmkSubmitButton>
      </FmkForm>
    </StdPaper>
  );
}

const TitleDiv = styled.div`

`;
