import { PlayerAmplitudeStats } from 'app/player/details/summary/amplitude/block';
import { PlayerDetailsSummaryBasicInfo } from 'app/player/details/summary/basic-info';
import { PlayerDetailsSummaryNoteBlock } from 'app/player/details/summary/note-block';

export const PlayerSummary = (): JSX.Element => {
  return (
    <div className="flex flex-wrap gap-4s">
      <div className="flex flex-col gap-4s">
        <PlayerDetailsSummaryBasicInfo />
        <PlayerDetailsSummaryNoteBlock />
      </div>
      <div>
        <PlayerAmplitudeStats />
      </div>
    </div>
  );
};
