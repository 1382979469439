import { stashGet, stash_method } from 'support/memory/stash';

export abstract class CloudFile {
  abstract readonly url: string;
  abstract readonly blob: Blob;
  abstract readonly type: string;

  static fromBlob(blob: Blob) {
    return new BlobCloudFile(blob);
  }

  static fromUrl(url: string) {
    return new UrlCloudFile(url);
  }

  static fromCode() {}

  getAspectRatio(): Promise<number> {
    const img = new Image();
    img.src = this.url;
    return new Promise((resolve) => {
      img.onload = () => {
        resolve(img.naturalWidth / img.naturalHeight);
      };
    });
  }

  async checkValid(): Promise<boolean> {
    try {
      const blob = await this.getBlob();
      return blob !== undefined;
    } catch (_) {
      return false;
    }
  }

  abstract getBlob(): Promise<Blob>;

  get [Symbol.toStringTag]() {
    return this.url;
  }
}

type Loaded = {
  response: Response;
  blob: Blob;
};
class UrlCloudFile extends CloudFile {
  private loaded?: Loaded;
  constructor(public readonly url: string) {
    super();
  }

  private getLoaded() {
    if (this.loaded === undefined) throw Error('url cloud file is not loaded');
    return this.loaded;
  }

  get blob() {
    return this.getLoaded().blob;
  }

  get type() {
    return this.getLoaded().response.headers.get('Content-Type');
  }

  async load() {
    if (this.loaded === undefined) {
      const response = await fetch(this.url);
      this.loaded = { response, blob: await response.blob() };
    }
    return this;
  }

  async getBlob(): Promise<Blob> {
    await this.load();
    return this.loaded.blob!;
  }
}

class BlobCloudFile extends CloudFile {
  constructor(public readonly blob: Blob) {
    super();
  }

  @stash_method
  getBlob() {
    return Promise.resolve(this.blob);
  }

  @stashGet
  get url() {
    return URL.createObjectURL(this.blob);
  }

  get type() {
    return this.blob.type;
  }
}
