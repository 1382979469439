import useForceRefresh from 'packs/state/useForceRefresh';
import {useRef} from 'react';

type Initializer<Inner, Outer> =
  (set: (state: Inner) => void, get: () => Inner) =>
    [Inner, (state: Inner) => Outer];

export function useUniStore<Inner, Outer>(init: Initializer<Inner, Outer>): Outer {
  const ref = useRef<Outer>(null);
  const refresh = useForceRefresh();

  if (ref.current === null) {
    const get = (): Inner => state;
    const set = (next: Inner) => {
      state = next;
      ref.current = factory(state);
      refresh();
    };

    let [state, factory] = init(set, get);
    ref.current = factory(state);
  }

  return ref.current;
}
