type Descriptor<T> = TypedPropertyDescriptor<T>

export default function memGet<T = unknown>(
  target: any, key: string, descriptor: Descriptor<T>
) {
  const {get} = descriptor;
  if (get === undefined) throw 'unexpected use of @memGet';
  descriptor.get = function () {
    const value = get.apply(this);
    Object.defineProperty(this, key, {value});
    return value;
  }
}
