import { createElement, ImgHTMLAttributes } from 'react';

type PlayerImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  size?: 'medium' | 'full';
  code?: string;
};

export const PlayerImage = (props: PlayerImageProps): JSX.Element => {
  return createElement('img', {
    ...props,
    src: props.code
      ? props.code.startsWith('h')
        ? props.code
        : `https://avatars.akamai.steamstatic.com/${props.code}${
            props.size ? `_${props.size}` : '_medium'
          }.jpg`
      : '/static/img/default-avatar.svg',
  });
};
