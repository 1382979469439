import { apiReq } from 'packs/libs/api';
import { DateTime } from 'luxon';
import { QuartzFilterZone, QuartzFooter } from 'packs/blocks/quartz';
import { Stable, StableHead } from 'packs/blocks/suss-table';
import { queUpd, useQue } from 'packs/libs/Que';
import { SussProvider, useSuss } from 'packs/libs/Suss';
import { Dump } from 'packs/single/Dump';
import { StdTime } from 'packs/std/time/format';
import { useCallback, useMemo } from 'react';

type PlayerUpgradePageProps = {
  playerId: string;
};

export const PlayerUpgradePage = ({ playerId }: PlayerUpgradePageProps): JSX.Element => {
  const request = useCallback(
    (query, context) =>
      apiReq({
        action: 'upgrade.history.search',
        body: { playerId, ...query, ...context },
              }),
    [playerId]
  );

  return (
    <SussProvider request={request} pk="_id">
      <div className="flex gap-4s">
        <Search />
        <Details />
      </div>
    </SussProvider>
  );
};

type UpgradeHistoryPageProps = {};

const Search = ({}: UpgradeHistoryPageProps): JSX.Element => {
  return (
    <div className="w200s">
      <QuartzFilterZone qInput={'id'} />
      <Stable Row={Item} className={'m2sy'}>
        <StableHead>at</StableHead>
        <StableHead>skins</StableHead>
        <StableHead>stake</StableHead>
        <StableHead>chance</StableHead>
        <StableHead>dropped</StableHead>
        <StableHead>won</StableHead>
      </Stable>
      <QuartzFooter />
    </div>
  );
};

type ItemProps = {
  data: any;
};

const Item = ({ data }: ItemProps): JSX.Element => {
  return (
    <tr className="cursor-pointer" onClick={() => queUpd({ upgradeId: data._id })}>
      <td>{StdTime.iso.datetime(data.at)}</td>
      <td>{data.stake.skins.length}</td>
      <td>{data.stake.sum}</td>
      <td>{data.chance}</td>
      <td>{data.dropped}</td>
      <td>{data.won?.sum}</td>
    </tr>
  );
};

const Details = (): JSX.Element => {
  const { upgradeId } = useQue();
  const [state] = useSuss();
  return useMemo(() => {
    if (!upgradeId) return null;
    const data = state.data.get(upgradeId);
    if (data === undefined) return <>not found</>;
    return (
      <div className="std-paper p4sa">
        <Dump data={data} />
      </div>
    );
  }, [upgradeId]);
};
