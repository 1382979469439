import { CaseSlotData } from 'domain/case';
import { SkinCard } from 'domain/skin/card';

type CaseSlotCardProps = {
  data: CaseSlotData;
};

export const CaseSlotCard = ({ data }: CaseSlotCardProps): JSX.Element => {
  const composeContent = () => {
    if (data.skin) {
      return <SkinCard data={data.skin} cost={data.cost} />;
    }
    return <div>Unexpected slot type</div>;
  };

  return <div>{composeContent()}</div>;
};
