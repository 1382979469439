import { ReactNode } from 'react';
import { useActor } from 'domain/actor/actor.store';

type AccessGuardProps = {
  access: string
  children?: ReactNode
  fallback?: ReactNode
}

export function AccessGuard({
  access, children, fallback,
}: AccessGuardProps) {
  const actor = useActor();

  if (actor.can(access)) return <>{children}</>;
  else return fallback ? <>{fallback}</> : null;
}