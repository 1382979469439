import { css, cx } from '@emotion/css';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import { WithdrawalStage } from 'domain/withdrawal';
import { juxProps } from 'fnd/juxtapose';
import { SussOperator } from 'packs/libs/Suss';
import { apiReq } from 'packs/libs/api';
import { StdKindButton, StdLink } from 'packs/std';
import { StdTime } from 'packs/std/time/format';
import { ReactNode, memo } from 'react';
import { round2DP } from 'support/etc/round-decimal-places';
import { stashCb } from 'support/memory/stash';

export type WithdrawalSearchCardOpts = {
  includePlayer: boolean;
};

type WithdrawalSearchCardProps = {
  opts: WithdrawalSearchCardOpts;
  data: any;
  onSelect(id: string): any;
  selected: boolean;
  op: SussOperator;
};

export const WithdrawalSearchCard = memo(function WithdrawalSearchCard({
  data,
  onSelect,
  selected,
  opts,
  op,
}: WithdrawalSearchCardProps): JSX.Element {
  const { id, player, stage, skin, sellingPrice, purchasePrice } = data;
  const { stageClass } = getStageMeta(stage)!;

  const composeActions = () => {
    if (stage === WithdrawalStage.pending_confirm) {
      return (
        <div className="flex gap-2s">
          <StdKindButton
            kind="ah"
            size="sm"
            children="Confirm"
            onClick={async () => {
              op.opItem(data.id).updState(
                await apiReq({ action: 'withdrawal.confirm', body: { id } })
              );
            }}
          />
          <StdKindButton
            kind="eh"
            size="sm"
            children="Reject"
            onClick={async () => {
              op.opItem(data.id).updState(
                await apiReq({ action: 'withdrawal.reject', body: { id } })
              );
            }}
          />
        </div>
      );
    }
  };

  return (
    <div
      className={cx('w130s p2sa std-paper flex flex-col gap-2s', selected && selectedClass)}
      onMouseOver={() => {
        onSelect(data.id);
      }}
    >
      <div className="flex gap-4s">
        <Attr label="created" className="w56s">
          {StdTime.iso.datetime(data.createdAt)}
        </Attr>
        <Attr label="updated" className="w56s">
          {StdTime.iso.datetime(data.updatedAt)}
        </Attr>
      </div>
      {opts.includePlayer && (
        <div className="w120s truncate">
          {player && (
            <StdLink className="truncate" to={`/players/${data.player.id}`} title={player.name}>
              {player.name}
            </StdLink>
          )}
        </div>
      )}
      <div className="w-full flex gap-2s justify-between">
        <div className="w70s truncate" title={skin.id}>
          {skin.id}
        </div>
        <div className="flex gap-2s">
          <div className="w20s truncate" title={`purchase price: ${purchasePrice}`}>
            <HomeIcon className="text-green" />
            {round2DP(purchasePrice)}$
          </div>
          <div className="w20s truncate" title={`selling price: ${sellingPrice}`}>
            <AccountBalanceIcon />
            {round2DP(sellingPrice)}$
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div>
          <span className={stageClass}>{stage}</span>
        </div>
        {composeActions()}
      </div>
    </div>
  );
},
juxProps(['data', 'selected']));

type AttrProps = {
  label: string;
  children: ReactNode;
  className?: string;
};

const Attr = ({ label, children, className }: AttrProps): JSX.Element => {
  return (
    <div className={cx('flex gap-2s items-center', className)}>
      <div className="tg-caption1 w14s">{label}:</div>
      <div>{children}</div>
    </div>
  );
};

const getStageMeta = stashCb(() => {
  const defaultColors = ['#d7d7d7', '#000'];
  const obj = {
    [WithdrawalStage.pending]: defaultColors,
    [WithdrawalStage.checked]: defaultColors,
    [WithdrawalStage.pending_confirm]: ['#2b51c5', '#fff'],
    [WithdrawalStage.confirmed]: ['#8bc88b', '#fff'],
    [WithdrawalStage.processing]: defaultColors,
    [WithdrawalStage.pending_player]: defaultColors,
    [WithdrawalStage.completed]: ['#3cc43c', '#fff'],
    [WithdrawalStage.failed]: ['#cc3c43', '#fff'],
  };

  const result = new Map();
  for (const [stage, [bg, fg]] of Object.entries(obj)) {
    result.set(stage, {
      stageClass: css`
        ${stageClass}
        background: ${bg};
        color: ${fg};
      `,
    });
  }

  return result.get.bind(result);
});

const selectedClass = css`
  background: #0000ff06 !important;
`;

const stageClass = css`
  padding: 4px;
  border-radius: 3px;
  font-weight: 600;
`;
