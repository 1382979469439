import { PlayerConfigPage } from 'app/player/config/page';
import { PlayerDetailsPage } from 'app/player/details/details-page';
import { PlayerSearchPage } from 'app/player/search/page';
import { StdJunction, StdTabJunction } from 'packs/std';

export const PlayerJunction = (): JSX.Element => {
  return (
    <StdTabJunction
      routes={[
        ['config', <PlayerConfigPage />],
        ['search', <PlayerSearchPage />],
        { path: ':id', elem: <PlayerDetailsPage />, hidden: true },
      ]}
    />
  );
};
