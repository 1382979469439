import { MenuItem } from '@mui/material';
import { el } from 'fnd/bridge/react';
import React, { ReactNode } from 'react';
import OriginSelect from 'react-select';

import rsa from './react-select-bridge';

export type SelectProps<T = any> = {
  options: T[];
  className?: string;
  value?: T;
  isClearable?: boolean;
  onChange(value: T): void;
  getOptionLabel?(option: T): string;
  getOptionValue?(option: T): string;
  formatOptionLabel?(option: T): ReactNode;
  placeholder?: string;
};

export function StdSelect(props: SelectProps) {
  return el(OriginSelect, { ...rsa, ...props });
}
