import { cx } from '@emotion/css';
import { config, edit } from 'ace-builds';

import mode_yaml_url from 'ace-builds/src-noconflict/mode-yaml?url';
import theme_github from 'ace-builds/src-noconflict/theme-github?url';
import { dump, load } from 'js-yaml';
import { useSingleton } from 'packs/state/use-singleton';
import { createRefEffect } from 'support/react/ref-effect';
config.setModuleUrl('ace/mode/yaml', mode_yaml_url);
config.setModuleUrl('ace/theme/github', theme_github);

export type YamlEditorOperator = {
  setData(data: any): void;
  getData(): any;
  setContent(content: string): void;
  getContent(): string;
  focus(): void;
};

type YamlEditorProps = {
  editorRef: (op: YamlEditorOperator) => (() => void) | void;
  className?: string;
};

export const YamlEditor = (props: YamlEditorProps): JSX.Element => {
  const ref = useSingleton(() => {
    return createRefEffect((el) => {
      const editor = edit(el, {
        mode: 'ace/mode/yaml',
        theme: 'ace/theme/github',
        selectionStyle: 'text',
        tabSize: 2,
      });

      editor.setOptions({
        maxLines: Infinity,
        fontSize: '16px',
      });

      const setContent = (content: string) => {
        editor.setValue(content, content.length);
      };

      const getContent = () => editor.getValue();

      const setData = (data: any) => {
        setContent(dump(data, { lineWidth: 200, noCompatMode: true }));
      };

      const getData = () => load(getContent());

      const focus = () => {
        editor.focus();
      };

      const callback = props.editorRef({
        setData,
        getData,
        setContent,
        getContent,
        focus,
      }) as any;

      return () => {
        callback?.();
        editor.destroy();
      };
    });
  });

  return <div ref={ref} className={cx('p4sa', props.className)} />;
};
