import { css } from '@emotion/css';
import { useCribContext } from 'packs/crib/store';
import { strGen } from 'support/etc/str-gen';

const STD_CHARS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

type CribStringGenProps = {
  len: number;
  chars?: string;
  name: string;
};

export const CribStringGen = (props: CribStringGenProps): JsxElement => {
  const crib = useCribContext();
  return (
    <div
      className={mainClass}
      onClick={() => {
        crib.data.mutState((draft) => {
          draft[props.name] = strGen(props.chars ?? STD_CHARS)(props.len);
        });
      }}
    >
      generate
    </div>
  );
};

const mainClass = css`
  font-size: 12px;
  font-weight: 300;
  color: var(--text-30);
  text-decoration: underline;
  cursor: pointer;
`;
