import Typography from "@material-ui/core/Typography";
import logger from "fnd/logger";
import genStr from "fnd/support/genStr";
import React from "react";
import styled from "styled-components";

export default class Bsod extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const token = genStr(32);
    logger.log(0, {
      code: 'bsod',
      layer: 'root',
      error: {
        name: error.name,
        message: error.message,
        stack: info.componentStack,
      },
      token,
    });
    this.setState({ token });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ContainerDiv>
          <WindowDiv>
            <Typography variant={"h3"}>
              Broken
            </Typography>
            <TokenTitleDiv>
              Token:
            </TokenTitleDiv>
            <TokenDiv>{this.state.token}</TokenDiv>
          </WindowDiv>
        </ContainerDiv>
      );
    }

    return this.props.children;
  }
}

const ContainerDiv = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

const WindowDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TokenTitleDiv = styled.div`
  padding: 8px;
`;

const TokenDiv = styled.div`
  background: var(--blue-300);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  padding: 6px;
`;
