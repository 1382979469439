import { useCribField } from 'packs/crib/store';
import { CribErrorBox } from 'packs/crib/units/error-box';
import { StdAutoTextArea, StdAutoTextAreaProps } from 'packs/std';
import { execIfFn } from 'support/etc/exec-if-fn';
import { ZodTypeAny } from 'zod';

type CribAutoTextAreaProps = StdAutoTextAreaProps & {
  name: string;
  schema?: ZodTypeAny | (() => ZodTypeAny);
  editable?: boolean;
};

export const CribAutoTextArea = (props: CribAutoTextAreaProps): JSX.Element => {
  const { name, schema, ...others } = props;
  const [{ value, error }, op] = useCribField(() => {
    return { name, schema: execIfFn(schema), initialValue: undefined };
  });
  return (
    <div>
      <StdAutoTextArea
        {...others}
        value={value}
        onChange={(e) => {
          op.setValue(e.target.value);
        }}
      />
      <CribErrorBox error={error} />
    </div>
  );
};
