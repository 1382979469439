import fastDeepEqual from 'fast-deep-equal';
import omitEmpty from 'fnd/support/omitEmpty';
import { Swiss, useBasicSwissStore } from 'packs/state/swiss-store';
import { ReactNode, createContext, useContext, useEffect } from 'react';
import { MutSpec } from 'support/immutable/mut';
import { IHashMap } from 'support/struct/i-hash-map';

type State<K = any, V = any, Q = Rsa> = {
  data: IHashMap<K, V>;
  meta: Rsa;
  loading: boolean;
  size: number;
  page: number;
  stash: Rsa;
  query: Q;
  hasMore: boolean;
};

export type SussRequest<T, Q> = (
  query: Q,
  context: { page: number; size: number }
) => undefined | Promise<{ meta: Rsa; data: T[] }>;

export type SussOperator<K = any, V = any, Q = Rsa> = {
  doQuery(): void;
  setSize(size: number): void;
  turn(page: number): void;
  loadMore(): void;
  updQuery(query: Partial<Q>): void;
  mutQuery(query: MutSpec<Q>): void;
  setRequest(request: SussRequest<V, Q>);
  getState(): State<K, V, Q>;
  opItem(key: K): Swiss<V>;
  query: Swiss<Q>;
  stash: Swiss<Rsa>;
};

const Context = createContext<[State, SussOperator]>(null);
if (__DEV__) Context.displayName = 'SussProvider';

export function useSuss<K = any, V = any, Q = Rsa>() {
  return useContext(Context) as [State<K, V, Q>, SussOperator<K, V, Q>];
}

export const useSussReload = () => useSuss()[1].doQuery;

export function useSussQueryProp<T>(prop: string): [T, (val: T) => void, SussOperator] {
  const suss = useSuss();

  return [
    suss[0].query[prop],
    (val) => {
      suss[1].updQuery({ [prop]: val });
    },
    suss[1],
  ];
}

export type SussProviderProps = {
  children: ReactNode;
  request: SussRequest<any, Rsa>;
  pk?: string | ((item: any) => any);
  query?: Rsa;
  size?: number;
};

export const SussProvider = ({
  children,
  pk,
  request, // be careful! it SHOULD NOT be new every render
  ...state
}: SussProviderProps) => {
  useEffect(() => {
    value[1].setRequest(request);
  }, [request]);

  const value = useBasicSwissStore<State, SussOperator>(() => [
    {
      loading: true,
      page: 1,
      data: IHashMap.empty(),
      meta: {},
      hasMore: true,
      size: 10,
      stash: {},
      query: {},
      ...omitEmpty(state),
    },

    (swiss) => {
      let request: SussRequest<any, any>;

      const { setState, getState, updState } = swiss;

      const getKey = resolvePrimaryKey(pk);

      const getData = () => getState().data;

      const getItem = (pk) => getState().data.get(pk);
      const setItem = (key, val) => {
        const data = getData();
        updState({
          data: data.set(key, val),
        });
      };

      const setLoading = (loading: boolean) => {
        updState({ loading });
      };

      const toData = (data) => IHashMap.fromEntries(data.map((i) => [getKey(i), i]));

      const doQuery = async () => {
        if (!request) return;
        setLoading(true);
        const { query, size } = getState();
        try {
          const response = request(query, { page: 1, size });
          if (response === undefined) return;
          const { data, meta } = await response;
          updState({
            meta,
            data: toData(data),
            page: 1,
            hasMore: data.length === size,
          });
        } finally {
          setLoading(false);
        }
      };

      const getQuery = () => getState().query;
      const queryOp = new Swiss((query) => {
        if (fastDeepEqual(query, getQuery())) return;
        updState({ query });
        doQuery();
      }, getQuery);

      return {
        doQuery,
        getState,

        setSize(size: number) {
          updState({ size });
          doQuery();
        },

        setRequest(req) {
          request = req;
          doQuery();
        },

        turn: async (page: number) => {
          if (!request) return;
          setLoading(true);

          const { size, query } = getState();
          try {
            const response = request(query, { page, size });
            if (response === undefined) return;
            const { data, meta } = await response;
            updState({
              meta,
              data: toData(data),
              page,
              hasMore: data.length === size,
            });
          } finally {
            setLoading(false);
          }
        },

        loadMore: async () => {
          if (!request) return;
          try {
            setLoading(true);
            const { query, page: prev, size } = getState();
            const page = prev + 1;
            const response = request(query, { page, size });
            if (response === undefined) return;
            const { data, meta } = await response;
            updState({
              data: getData().mut((map) => {
                for (const item of data) {
                  map.set(getKey(item), item);
                }
              }),
              page,
              meta,
              hasMore: data.length === size,
            });
          } finally {
            setLoading(false);
          }
        },

        opItem(key) {
          return new Swiss(
            (val) => setItem(key, val),
            () => getItem(key)
          );
        },

        query: queryOp,

        stash: swiss.prop('stash'),

        mutQuery: queryOp.mutState,
        updQuery: queryOp.updState,
      };
    },
  ]);

  // if (typeof children === 'function')
  //   // @ts-ignore
  //   children = el(children, value[0]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function resolvePrimaryKey(pk: string | ((item: any) => any) | undefined) {
  switch (typeof pk) {
    // @ts-ignore
    case 'undefined':
      pk = 'id';
    case 'string':
      // @ts-ignore
      return (item) => item[pk];
    case 'function':
      return pk;
    default:
      throw Error('pk is invalid');
  }
}
