import { createElement } from 'react';

type ShortSpanProps = React.HTMLAttributes<HTMLSpanElement> & {
  max: number;
  rl?: number; // length on the right side
  children: string;
};

export const ShortSpan = ({ max, children: v, rl, ...others }: ShortSpanProps): JsxElement => {
  if (!v) return createElement('span', others, v);
  rl ??= max / 2;
  const ll = max - rl;
  const short = v.length > max ? v.slice(0, ll) + '...' + v.slice(-rl) : v;
  return createElement('span', { title: v, ...others }, short);
};
