import { UpgradePage } from 'app/upgrade/upgrade';

export const UpgradeJunction = (): JSX.Element => {
  return <UpgradePage />;
  // useLinkBreadcrumb('Upgrade');
  // return (
  //   <StdTabJunction
  //     routes={[
  //       ['config', <UpgradeConfigBlock />],
  //       ['history', <UpgradeHistoryPage />],
  //     ]}
  //   />
  // );
};
