import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';

type SimpleEditTextFieldProps = {
  value: string;
  onChange(value: string);
  className?: string;
};

export const SimpleEditTextField = ({
  value: initialValue,
  onChange,
}: SimpleEditTextFieldProps): JSX.Element => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(initialValue);

  return (
    <div className="flex gap-4s">
      {edit ? (
        <>
          <input autoFocus value={value} onChange={(e) => setValue(e.target.value)} />
          <CheckIcon
            onClick={() => {
              onChange(value);
              setEdit(false);
            }}
          />
          <CloseIcon
            onClick={() => {
              setEdit(false);
            }}
          />
        </>
      ) : (
        <>
          <div>{value}</div>
          <EditIcon onClick={() => setEdit(true)} />
        </>
      )}
    </div>
  );
};
