import memGet from 'fnd/support/decor/memGet';
import parseJwt from 'domain/actor/parseJwt';
import {ActorRole} from 'domain/actor/actor.defs';
import {makeAclChecker} from 'domain/actor/AclChecker';

type Payload = {
  id: string
  email: string
  role: ActorRole
  acl: string[]
}

export default class ActorOperator {
  public readonly jwt: string;
  public readonly kickOut: () => void;
  public readonly login: (cred: string) => void;
  public readonly logout: () => void;

  get anon() {
    return !this.jwt;
  }

  get authed() {
    return !this.anon;
  }

  @memGet
  get payload(): Payload {
    if (!this.jwt) throw new Error('logic: you should check if actor is anon first.');
    return parseJwt(this.jwt);
  }

  @memGet
  get can() {
    return makeAclChecker(this.payload.acl);
  }

  get email() {
    return this.payload.email;
  }
}
