import { apiReq } from 'packs/libs/api';
import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSwissState } from 'support/react/swiss';
import { useLaunch } from 'support/react/use-launch';

const Context = createContext(null);
if (__DEV__) Context.displayName = 'PlayerDetailsContext';

export const usePlayerDetailsStore = () => useContext(Context);

export type PlayerDetailsState = Rsa & {
  id: string;
  note: string;
};

export const PlayerDetailsStoreProvider = ({ children }) => {
  const { id } = useParams<Rsa>();
  const store = useSwissState(null);
  const [data, op] = store;
  useLaunch(async () => {
    op.setState(
      await apiReq({
        action: 'player.get',
        query: { id },
      })
    );
  }, [id]);

  if (data === null) return null;

  return <Context.Provider value={store}>{children}</Context.Provider>;
};
