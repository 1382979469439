import { css, cx } from '@emotion/css';
import { infDispatch } from 'fnd/inf/Inf';
import { CloudFile } from 'packs/cloud/file';
import { useSingleton } from 'packs/state/use-singleton';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

export type CloudImageConfig = {
  accept?: string | string[];
};

type CloudImageProps = {
  value?: CloudFile;
  onChange(value?: CloudFile): void;
  config: CloudImageConfig;
  className?: string;
};

export const CloudImage = (props: CloudImageProps): JSX.Element => {
  const options = useSingleton(() => {
    const { accept = 'image/*' } = props.config ?? {};
    const options: DropzoneOptions = {
      maxFiles: 1,
      accept: accept,
      onDrop: ([file]) => {
        infDispatch(async () => {
          if (file === undefined) throw Error('unexpected input');

          props.onChange(CloudFile.fromBlob(file));
        });
      },
    };
    return options;
  });

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone(options);
  const { value, className } = props;

  return (
    <div className={cx(mainClass, className)} {...getRootProps()}>
      <input {...getInputProps()} />
      <div
        className={cx(boxClass, isDragActive && dragActiveClass, isDragReject && dragRejectClass)}
      >
        {value ? (
          <img src={value.url} className={className} />
        ) : (
          <div className={dragAreaClass}>drag or click</div>
        )}
      </div>
    </div>
  );
};

const mainClass = css``;

const boxClass = css`
  cursor: pointer;
  border-width: 2px;
  border-style: dashed;
  outline: none;
  transition: border 0.24s ease-in-out;
  border-color: #0044ff1d;
  width: fit-content;
`;

const dragAreaClass = css`
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// border-color: #00ff1542;
const dragActiveClass = css`
  border-color: #0044ff99;
`;

const dragRejectClass = css`
  border-color: #ff000099;
`;

// async function doCrop(image: HTMLImageElement, crop, pack): Promise<FilePack> {
//   const canvas = document.createElement('canvas');
//   const scaleX = image.naturalWidth / image.width;
//   const scaleY = image.naturalHeight / image.height;

//   const width = crop.width * scaleX;
//   const height = crop.height * scaleY;

//   canvas.width = width;
//   canvas.height = height;
//   const ctx = canvas.getContext('2d');

//   ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, width, height, 0, 0, width, height);

//   return new Promise((resolve, reject) => {
//     canvas.toBlob(
//       (blob) => {
//         // if (!blob) inform.error('Could not handle image');
//         resolve({
//           ...pack,
//           size: blob.size,
//           url: URL.createObjectURL(blob),
//         });
//       },
//       pack.type,
//       1
//     );
//   });
// }

// type ImageUploadProps = {
//   accept: string[];
//   crop: {
//     aspect?: number;
//     x?: number;
//     y?: number;
//     width?: number;
//     height?: number;
//     unit?: 'px' | '%' | string;
//   };
//   circularCrop?: boolean;
//   onSubmit(image: FilePack): void;
// };
// const ImageUploadAndEdit = ({
//   crop: initial,
//   accept,
//   circularCrop,
//   onSubmit,
// }: ImageUploadProps) => {
//   const [{ file, crop, img }, push] = useThunkSetState<any>({ crop: initial });
//   const t = useT();

//   return (
//     <div className="lc-image-upload-and-edit">
//       <div className="image-block">
//         {file ? (
//           <div className="crop-container">
//             <ReactCrop
//               circularCrop={circularCrop}
//               crop={crop}
//               onImageLoaded={(img) => {
//                 push({ img });
//               }}
//               onChange={(crop) => {
//                 push({ crop });
//               }}
//             >
//               <img src={img} />
//             </ReactCrop>
//           </div>
//         ) : (
//           <FileZone
//             accept={accept}
//             onAccept={(file) => {
//               push({ file });
//             }}
//           >
//             <div className="desc">
//               <div className="lvl1">
//                 Drag and Drop documents here for upload or Click to select files.
//               </div>
//               <div className="lvl2">
//                 (Acceptable file formats: jpg, png, webp. The file size is no more than: 5 Mb)
//               </div>
//             </div>
//           </FileZone>
//         )}
//       </div>
//     </div>
//   );
// };
