import { Dump } from 'packs/single/Dump';
import { Component } from 'react';

export class SimpleOutpost extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(_, info) {
    this.setState({ info });
    // splashEmergency({
    //   ...collectBrowserData(),
    //   error: formatError(error),
    //   stack: info.componentStack,
    //   code: 'bsod',
    // });
  }

  render() {
    if (this.state.error !== undefined) {
      return (
        <div className="overflow-auto">
          <div className="tg-title">Something went wrong.</div>
          <Dump data={formatError(this.state.error)} />
          {this.state.info && <Dump data={this.state.info.componentStack} />}
        </div>
      );
    }

    return this.props.children;
  }
}

function formatError(error: any) {
  if (error instanceof Error)
    return {
      ...{
        class: error.constructor.name,
        name: error.name,
        message: error.message,
        stack: error.stack,
        code: 'error',
      },
      ...error,
    };

  return error;
}
