import {memo} from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import styled from 'styled-components';
import {cc} from 'fnd/bridge/react';

type StablePaginationProps = {
  count: number
  page: number,
  total: number,
  size: number
  onChange(page: number): void
}
export const QuartzPagination = memo(
  function StablePagination({
    count, page, size, total, onChange,
  }: StablePaginationProps) {
    const pagesOnTable = Math.ceil(count / size);
    const firstRow = (size * (page - pagesOnTable)) + 1;
    let lastRow = firstRow + count - 1;
    let isNotTheEnd = true;
    if (lastRow >= total) {
      lastRow = total;
      isNotTheEnd = false;
    }

    return (
      <div className={'df aic fdrr'}>
        <ChevronDiv>
          <ChevronLeftIcon
            className={cc({active: page > 1})}
            onClick={() => page > 1 && onChange(page - 1)}
          />
          <ChevronRightIcon
            className={cc({active: isNotTheEnd})}
            onClick={() => isNotTheEnd && onChange(page + 1)}
          />
        </ChevronDiv>
        <TextDiv>
          {firstRow}-{lastRow} of {total}
        </TextDiv>
      </div>
    );
  },
);

const TextDiv = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: var(--text-30)
`;

const ChevronDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100px;
  padding-left: 30px;

  svg {
    fill: var(--text-40);
    &.active {
      fill: var(--text-30);
      cursor: pointer;
    }
  }
`;
