import { add, milliseconds } from 'date-fns';
import { ContestUpon } from 'domain/contest/definitions';
import { PlayerRole } from 'domain/player/definitions';
import { PlayerRequirementField } from 'domain/player/requirements/player-requirements-block';
import { chronicle } from 'fnd/bridge/router';
import { SUPPORTED_LOCALES } from 'fnd/l10n';
import { CloudFile } from 'packs/cloud';
import { CribRef, CribSimpleForm, CribSubmitButton, useCribRef, useCribWatch } from 'packs/crib';
import { CribCheckBoxListField } from 'packs/crib/fields/check-box-list';
import { CribCloudImageField } from 'packs/crib/fields/cloud-image';
import { CribCronField } from 'packs/crib/fields/cron';
import { CribCsvField } from 'packs/crib/fields/csv-field';
import { CribDateTimeField } from 'packs/crib/fields/date-field';
import { CribDurationField } from 'packs/crib/fields/duration-field';
import { CribInputField, CribNumberInputField } from 'packs/crib/fields/input';
import { CribPlayerField } from 'packs/crib/fields/player';
import { CribPresets } from 'packs/crib/fields/presets';
import { CribStringGen } from 'packs/crib/fields/string-gen';
import { apiReq } from 'packs/libs/api';
import { StdFieldBox } from 'packs/std';
import { StdCompoundCard, StdCompoundCardGroup } from 'packs/std/card/composite';
import stdLoadable from 'packs/std/loadable';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLaunch } from 'support/react/use-launch';
import { z } from 'zod';

type ContestModPageProps = {
  contest: any;
  submit(body: any, attach: any): void;
  op?: CribRef;
  template?: boolean;
};

export const ContestForm = ({
  op,
  contest: initialData,
  submit,
  template,
}: ContestModPageProps): JSX.Element => {
  const doSubmit = (data) => {
    const { image, ...rest } = data;
    const attach = image === initialData.image ? [] : [image];
    submit(rest, attach);
  };

  return (
    <CribSimpleForm op={op} initialData={initialData} submit={doSubmit}>
      <StdCompoundCardGroup>
        {/* <CribInputField name="title" schema={z.string().min(4)} /> */}
        <StdCompoundCard className="w80s">
          <div>
            <div className="tg-title2">Title</div>
            <div className="tg-label1">
              {SUPPORTED_LOCALES.map((lc) => {
                return (
                  <div key={lc}>
                    <div>{lc}</div>
                    <div className="p1sy">
                      <CribInputField name={`title.${lc}`} schema={z.string().min(3)} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </StdCompoundCard>

        <StdCompoundCard className="w80s">
          {template ? (
            <StdFieldBox title="Cron Rule">
              <CribCronField name="cron" schema={(s) => s.periodGte({ hours: 6 })} />
            </StdFieldBox>
          ) : (
            <StdFieldBox title="Start">
              <CribPresets
                options={[
                  ['30m15s', { minutes: 30, seconds: 15 }],
                  ['10m', { minutes: 10 }],
                  ['30s', { seconds: 30 }],
                  ['15s', { seconds: 15 }],
                ]}
                onSelect={(value, op) => {
                  op.data.prop('start').setState(add(new Date(), value));
                }}
              />
              <CribDateTimeField name="start" schema={() => z.date().min(new Date())} />
            </StdFieldBox>
          )}
          <StdFieldBox title="Duration">
            <CribPresets
              options={[
                ['15m', { minutes: 15 }],
                ['10m', { minutes: 10 }],
                ['5m', { minutes: 5 }],
                ['30s', { seconds: 30 }],
                ['15s', { seconds: 15 }],
              ]}
              onSelect={(value, op) => {
                op.data.prop('duration').setState(milliseconds(value));
              }}
            />
            <CribDurationField name="duration" required />
          </StdFieldBox>
        </StdCompoundCard>

        <StdCompoundCard className="w80s">
          <StdFieldBox title="Image">
            <CribCloudImageField required name="image" ratio={1} />
          </StdFieldBox>
        </StdCompoundCard>

        {!template && (
          <StdCompoundCard className="w70s">
            <StdFieldBox title="Blogger code">
              <CribStringGen len={6} name="code" />
              <CribInputField name="code" schema={z.string().min(3).optional()} />
            </StdFieldBox>

            <BloggerField />
          </StdCompoundCard>
        )}

        <StdCompoundCard className="w150s">
          <PlayerRequirementField name="requirements" />
        </StdCompoundCard>

        <StdCompoundCard className="w150s">
          <StdFieldBox title="Max Members">
            <CribNumberInputField name="cap" schema={z.number().int().min(1)} />
          </StdFieldBox>

          <StdFieldBox title="Entrance Fee" about="for free entrance - leave empty">
            <CribNumberInputField name="cost" schema={z.number().optional()} />
          </StdFieldBox>

          <StdFieldBox title="Initial Skin Count">
            <CribNumberInputField name="skins.count" schema={z.number().min(1)} />
          </StdFieldBox>

          <StdFieldBox title="Initial Skin Price (each)">
            <CribNumberInputField name="skins.price" schema={z.number().min(0)} />
          </StdFieldBox>

          <StdFieldBox title="Prize" about={'enumerate all winner positions using comma'}>
            <CribCsvField offset={1} name="prize" />
          </StdFieldBox>
        </StdCompoundCard>

        <StdCompoundCard className="w70s">
          <StdFieldBox title="Upon Modes">
            <CribCheckBoxListField
              name="upon"
              schema={z.array(z.nativeEnum(ContestUpon)).min(1, 'select at least one mode')}
              options={ContestUponOptions as any}
            />
          </StdFieldBox>
        </StdCompoundCard>
      </StdCompoundCardGroup>

      <div className="p4sy">
        <CribSubmitButton />
      </div>
    </CribSimpleForm>
  );
};

const Presets = stdLoadable(() =>
  import('app/contest/presets').then((x) => ({ default: x.ContestFormPresets }))
);

export const ContestAdd = (): JSX.Element => {
  const ref = useCribRef();

  return (
    <div className="flex flex-col gap-4s">
      {ENV_LIKE_DEVEL && (
        <Presets
          setData={(data) => {
            ref.data.updState(data);
          }}
        />
      )}
      <div>
        <ContestForm
          op={ref}
          contest={{}}
          submit={async (body, attach) => {
            const { id } = await apiReq({
              action: 'contest.add',
              success: true,
              body,
              attach,
              progress: true,
            });

            chronicle.push('/contest/' + id);
          }}
        />
      </div>
    </div>
  );
};

export const ContestAddFromTemplate = (): JsxElement => {
  const { templateId } = useParams<{ templateId: string }>();

  const [data, setData] = useState(null);

  useLaunch(async () => {
    const { id, image, cron, created, enabled, ...data } = await apiReq({
      action: 'contest.template.get',
      query: { id: templateId },
    });

    const file = CloudFile.fromUrl(`${ENV_CLOUD_MEDIA_URL}/contest-template/${id}`);

    setData({ image: file, ...data });
  }, []);

  if (data === null) return null;

  return (
    <ContestForm
      contest={data}
      submit={async (body, attach) => {
        const { id } = await apiReq({
          action: 'contest.add',
          success: true,
          body,
          attach,
          progress: true,
        });

        chronicle.push('/contest/' + id);
      }}
    />
  );
};

const BloggerField = (): JSX.Element => {
  const code = useCribWatch('code');
  if (!code) return null;
  return (
    <StdFieldBox title="Blogger">
      <CribPlayerField required query={{ roles: [PlayerRole.blogger] }} name={'blogger'} />
    </StdFieldBox>
  );
};

const ContestUponOptions = [
  [ContestUpon.crush, 'Crush'],
  [ContestUpon.upgrade, 'Upgrade'],
  [ContestUpon.caseBattle, 'Case Battle'],
] as const;
