import { CaseEdit } from 'app/cases/form';
import { chronicle } from 'fnd/bridge/router';
import { apiReq } from 'packs/libs/api';
import { StdKindButton } from 'packs/std';
import { useParams } from 'react-router-dom';

export const CaseDetails = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  return (
    <div className="flex flex-wrap gap-4s flex-col">
      <div>
        <StdKindButton
          kind="eh"
          children="Delete Case"
          onClick={async () => {
            await apiReq({ action: 'case.del', progress: true, body: id });
            chronicle.push('/cases');
          }}
        />
      </div>
      <CaseEdit id={id} />
    </div>
  );
};
