import { createElement, ImgHTMLAttributes } from 'react';

type CaseImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  code: string;
};

export const getCaseImageUrl = (code: string): string => `${ENV_CLOUD_MEDIA_URL}/case/${code}`;

export const CaseImage = ({ code, ...props }: CaseImageProps): JSX.Element => {
  return createElement('img', {
    ...props,
    src: getCaseImageUrl(code),
  });
};
