import { chronicle } from 'fnd/bridge/router';
import useForceRefresh from 'packs/state/useForceRefresh';
import qs from 'qs';
import { useEffect } from 'react';
import { Subscriber } from 'support/struct/subscriber';

const Sub = new Subscriber<Rsa>();

function isInt(value: string): boolean {
  return /^-{0,1}\d+$/.test(value);
}

const encode = (query: Rsa) => '?' + qs.stringify(query, {});
const decode = (query: string) =>
  qs.parse(query.slice(1), {
    decoder(val, dec, charset, type) {
      if (type === 'value' && typeof val === 'string') {
        if (isInt(val)) return parseInt(val, 10);
      }
      return dec(val);
    },
  });

let lastSearch = window.location.search;
let currentQue = decode(lastSearch);

export function getQue<Q extends Rsu = Rsa>(): Q {
  return currentQue as Q;
}

chronicle.listen((location) => {
  const search = location.search;
  console.log('search !== lastSearch', search !== lastSearch);
  if (search !== lastSearch) {
    Sub.emit((currentQue = decode(search)));
    lastSearch = search;
  }
});

export const queUpd = (query: Rsa) => {
  chronicle.push({
    ...chronicle.location,
    search: encode({ ...currentQue, ...query }),
  });
};

export function useQue<Q extends Rsu = Rsa>(): Q {
  const commit = useForceRefresh();
  useEffect(() => Sub.sub(commit), []);
  return currentQue as Q;
}

export function useQuePropReplace<T>(prop: string): [T | undefined, (value: T) => void] {
  const value = useQue<any>();

  return [
    value[prop],
    (value) => {
      chronicle.replace({
        ...chronicle.location,
        search: encode({ ...currentQue, [prop]: value }),
      });
    },
  ];
}
