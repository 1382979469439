import { css } from '@emotion/css';
import { ReactNode } from 'react';

type SimpleAttrTableProps = {
  data: [ReactNode, ReactNode][];
  className?: string;
};

export const SimpleAttrTable = ({ data, className }: SimpleAttrTableProps): JSX.Element => {
  return (
    <table className={className}>
      {data.map(([key, value]) => {
        return (
          <tr className={lineClass}>
            <td className={keyClass}>{key}</td>
            <td>{value}</td>
          </tr>
        );
      })}
    </table>
  );
};

const keyClass = css`
  padding: 4px 16px 4px 0;
`;

const lineClass = css`
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;
