import { debounce } from 'lodash';
import { useSingleton } from 'packs/state/use-singleton';
import { StdKindButton, StdKindButtonProps } from 'packs/std';
import { lensPath, set } from 'ramda';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import execIfFn from 'support/execIfFn';
import { MutSpec, mut } from 'support/immutable/mut';
import { stashGet } from 'support/memory/stash';
import { Swiss } from 'support/react/swiss';
import { useForceRefresh } from 'support/react/use-force-refresh';
import { SimpleEventTarget } from 'support/struct/simple-event-target';
import { ZodError, ZodTypeAny } from 'zod';

const Context = createContext(null);
if (__DEV__) Context.displayName = 'CribContext';

export function useCribContext<T = any>(): CribOperator<T> {
  return useContext(Context);
}

type CribSimpleFormProps = {
  submit(data: any): OrPromise<void>;
  initialData: any;
  className?: string;
  children: ReactNode;
  op?: CribRef;
};

type Observer<T = any> = (data: T) => void;
type Validator<T = any> = (data: T) => OrPromise<any>;
type Lens<T = any> = {
  get(): T;
  set(data: T): void;
  setDefault(data: T): void;
};

export type CribOperator<Data = any> = {
  data: Swiss<Data>;
  getData(): Data;
  setData(data: Data): void;
  getProblems(): Data;
  addProblem(problem: CribProblem): void;
  mutData(spec: MutSpec<Data>): void;
  submit(event): any;
  lens<T>(name?: string | undefined): Lens<T>;
  observers: {
    sub(observer: Observer<any>): () => void;
    add<T>(observer: Observer<T>): void;
    del(observer: Observer<any>): void;
  };
  validators: {
    add<T>(validator: Validator<T>): void;
    del(validator: Validator<any>): void;
  };
  onAfterValidation: SimpleEventTarget;
};

export const CribSimpleForm = (props: CribSimpleFormProps): JSX.Element => {
  type T = any;
  const value = useSingleton((): CribOperator<T> => {
    let currentData: T = props.initialData;
    let currentErrors: any[] = [];

    const observers = new SimpleEventTarget<T>();
    const validators = new Set<Validator<T>>();
    const onAfterValidation = new SimpleEventTarget();

    const validate = async () => {
      const promises: Promise<boolean>[] = [];
      for (const validate of validators) {
        promises.push(validate(currentData));
      }
      currentErrors = await Promise.all(promises).then((list) => list.filter((v) => !!v));
      onAfterValidation.emit();
    };

    const launchValidation = debounce(validate, 300);

    const setData = (data: T) => {
      currentData = data;
      observers.emit(data);
      launchValidation();
    };

    const lens_path = (path: (string | number)[]) => {
      // console.log('path', path);
      const set_over = set(lensPath(path));
      // console.log(set_over('lol', { title: 'kek' }));
      return {
        get: (): any | undefined => {
          let current: any = currentData;
          for (const prop of path) {
            if (current === undefined) break;
            current = current[prop];
          }
          return current;
        },
        set: (value: any) => setData(set_over(value, currentData)),
        setDefault: (value: any) => {
          currentData = set_over(value, currentData);
        },
      };
    };

    const lens = (name?: string | undefined) => {
      return lens_path(
        name
          ? name.split('.').map((prop) => {
              const int = parseInt(prop);
              return isNaN(int) ? prop : int;
            })
          : []
      );
    };

    const getData = () => currentData;
    const dataSwiss = new Swiss(setData, getData);

    const submit = (e) => {
      e.preventDefault();
      validate().then(() => {
        if (currentErrors[0] === undefined) {
          props.submit(currentData);
        }
      });
    };

    const addProblem = (problem: CribProblem) => {
      currentErrors.push(problem);
    };

    const operator = {
      data: dataSwiss,
      getData,
      setData,
      mutData: dataSwiss.mutState,
      getProblems: () => currentErrors,
      addProblem,
      onAfterValidation,
      // @ts-ignore
      lens,
      submit,
      observers: {
        // @ts-ignore
        add: (observer: Observer<any>) => {
          observers.add(observer);
        },
        del: (observer: Observer) => {
          observers.delete(observer);
        },
        sub: (observer: Observer) => {
          observers.add(observer);
          return () => {
            observers.delete(observer);
          };
        },
      },
      validators: {
        add: (validator: Validator) => {
          validators.add(validator);
          launchValidation();
        },
        del: (validator: Validator) => {
          validators.delete(validator);
        },
      },
    };

    if (props.op) props.op['_init'](operator);

    return operator;
  });

  return (
    <Context.Provider value={value}>
      <form className={props.className} onSubmit={value.submit}>
        {props.children}
      </form>
    </Context.Provider>
  );
};

// export const useCribWatch = (name: string) => {
//   const commit = useForceRefresh();
//   const context = useCribContext();
//   const access = useSingleton(() => {
//     return (name: Name) => {};
//   });
//   useEffect(() => {
//     context;
//   }, []);

//   return;
// };

export type CribFieldOpts = {
  name: string;
  schema?: ZodTypeAny;
  initialValue?: any;
};
type CribFieldResult<T> = [
  { value: T; error: undefined | CribProblem },
  {
    setValue(value: T): void;
    getValue(): T;
    mutValue(spec: MutSpec<T>): void;
    context: CribOperator;
  }
];

export function useCribWatch<T = any>(name: string): T {
  const commit = useForceRefresh();
  const context = useCribContext();
  const access = useSingleton(() => context.lens(name).get);
  useEffect(() => {
    context.observers.add(commit);
    return () => {
      context.observers.del(commit);
    };
  }, []);
  return access() as T;
}

export function useCribField<T = any>(
  options: CribFieldOpts | (() => CribFieldOpts)
): CribFieldResult<T> {
  const commit = useForceRefresh();
  const context = useCribContext();
  const store = useSingleton(() => {
    const opts = execIfFn(options);
    const { name, schema } = opts;

    const { get: getValue, set: setValue, setDefault } = context.lens(name);
    let currentValue = getValue();
    if (currentValue === undefined && opts.initialValue !== undefined) {
      setDefault((currentValue = opts.initialValue));
    }
    let validationSuccess = true;
    let currentError = undefined;

    const doCommit = () => {
      store.field = {
        value: currentValue,
        error: currentError,
      };
      commit();
    };

    const effect = () => {
      let validator;
      const observer = () => {
        const next = getValue();
        if (next === currentValue) return;
        currentValue = next;
        doCommit();
      };
      if (schema) {
        validator = async () => {
          const result = await schema.safeParseAsync(currentValue);
          // @ts-ignore
          const problem = result.success ? undefined : new CribProblem(result.error);
          if (result.success && validationSuccess) return problem;
          else if (result.success) {
            currentError = undefined;
            validationSuccess = true;
            doCommit();
          } else {
            validationSuccess = false;
            const nextError = problem.toString();
            if (currentError !== nextError) {
              currentError = nextError;
              doCommit();
            }
          }
          return problem;
        };
        context.validators.add(validator);
      }

      context.observers.add(observer);
      return () => {
        context.observers.del(observer);
        if (validator) context.validators.del(validator);
      };
    };

    const op = {
      setValue,
      getValue,
      mutValue: (spec: MutSpec<T>) => setValue(mut(currentValue, spec)),
      context,
    };

    const store = {
      op,
      field: {
        value: currentValue ?? opts.initialValue,
        error: currentError,
      },
      effect,
    };

    return store;
  });

  useEffect(store.effect, []);

  // @ts-ignore
  return [store.field, store.op];
}

type Path = (number | string)[];

export class CribProblem {
  constructor(private e: ZodError) {}

  static simple({ message, path = [] }: { message: string; path?: Path }) {
    return new CribProblem(ZodError.create([{ path, message, code: 'custom' }]));
  }

  toString(): string {
    return this.message;
  }

  @stashGet
  get message() {
    return this.e.issues[0].message;
  }

  on(path: Path): CribProblem | undefined {
    const issues = this.e.issues.filter((issue) => {
      const iterator = issue.path.values();
      for (const prop of path) {
        const it = iterator.next();
        if (it.done) return false;
        if (it.value !== prop) return false;
      }
      return iterator.next().done;
    });
    return issues[0] === undefined ? undefined : new CribProblem(ZodError.create(issues));
  }
}

export const CribSubmitButton = (props: StdKindButtonProps): JSX.Element => {
  const context = useCribContext();
  const [hasProblems, setHasProblems] = useState(true);
  console.log('crib submit button');
  useEffect(() => {
    const check = () => {
      setHasProblems(context.getProblems().length > 0);
    };
    context.onAfterValidation.add(check);
    return () => {
      context.onAfterValidation.delete(check);
    };
  }, []);

  return <StdKindButton children="Submit" {...props} type="submit" disabled={hasProblems} />;
};

export type CribRef<T = any> = CribOperator<T> & {
  initialized: boolean;
};

export const useCribRef = () => {
  return useSingleton(() => {
    const ref = {
      initialized: false,
      _init(op) {
        console.log('init crib ref');
        Object.assign(ref, op, { initialized: true });
      },
    } as any;

    return ref as CribRef;
  });
};
