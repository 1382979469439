import { useCribField } from 'packs/crib';
import { CribErrorBox } from 'packs/crib/units/error-box';
import { StdButtonBool } from 'packs/std/inputs/std.button-bool';
import { ReactNode } from 'react';
import { z } from 'zod';

type CribBoolButtonFieldProps = {
  name: string
  falsy?: ReactNode;
  truly?: ReactNode;
};

export const CribBoolButtonField = (props: CribBoolButtonFieldProps): JSX.Element => {
  const [{ value, error }, op] = useCribField<boolean>(() => {
    return {
      name: props.name,
      initialValue: false,
      schema: z.boolean().default(false),
    };
  });
  return (
    <div>
      <StdButtonBool falsy={props.falsy} truly={props.truly} onChange={op.setValue} value={value} />
      <CribErrorBox error={error} />
    </div>
  );
};
