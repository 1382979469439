import {
  StylesProvider as MuiStylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import * as React from 'react';
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';

import {getTheme} from 'packs/std/StdTheme';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

export default function StdProvider({children}: Props) {
  const theme = getTheme();

  return (
    <MuiStylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={(theme as unknown) as DefaultTheme}>
          {children}
        </StyledThemeProvider>
      </MuiThemeProvider>
    </MuiStylesProvider>
  );
};
