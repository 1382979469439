import { EmployeeRolesPage } from 'app/employees/roles/EmployeeRolesPage';
import { EmployeeMain } from 'app/employees/users/employee.main';
import { StdTabJunction } from 'packs/std';

export const EmployeesJunction = (): JSX.Element => {
  return (
    <StdTabJunction
      className="p4sl"
      routes={[
        ['users', <EmployeeMain />],
        ['roles', <EmployeeRolesPage />],
      ]}
    />
  );
};
