import { useEffect } from 'react';
import { chronicle } from 'fnd/bridge/router';
import { apiReq } from 'packs/libs/api';

let redirectUrl: URL | undefined;

export async function oauthOnAuthed() {
  if (redirectUrl === undefined) return;
  const query = redirectUrl.searchParams;

  const creds = await apiReq<Rsa>({
    action: 'legacy.sig',
      });
  for (let [name, value] of Object.entries(creds)) {
    query.set(name, value);
  }

  window.location.href = redirectUrl.toString();
}

function defineRedirectUrl() {
  const from = new URLSearchParams(chronicle.location.search).get('from');
  redirectUrl = new URL(from);
}

export function OauthAnon() {
  useEffect(() => {
    defineRedirectUrl();
    chronicle.push('/sign-in');
  }, []);

  return null;
}

export function OauthAuthed() {
  useEffect(() => {
    defineRedirectUrl();
    oauthOnAuthed();
  }, []);

  return null;
}