import { apiReq } from 'packs/libs/api';
import { createContext, useContext, useEffect } from 'react';
import { hashObject } from 'support/etc/hash-object';
import { useBasicStore } from 'support/react/basic-store';
import { Swiss } from 'support/react/swiss';
import { launch } from 'support/react/use-launch';

const Context = createContext<[Rsa, Swiss<Rsa>]>(null);

export function useConsoleConfigOnProp<T>(prop: string) {
  const [state, op] = useContext(Context);
  return [state[prop], op.prop(prop)] as [T, Swiss<T>];
}

export const ConsoleConfigProvider = (props: Rcp): JSX.Element => {
  const value = useBasicStore(() => [
    null,
    (setState, getState) => {
      let initialState;

      const sync = async (next) => {
        const hash = await hashObject(initialState);
        await apiReq({ action: 'console-config.set', body: { next, hash } });
        initialState = next;
      };

      const setStateSync = (state) => {
        setState(state);
        sync(state);
      };

      const _initialize = () => {
        launch(async () => {
          const data = await apiReq({ action: 'console-config.get' });
          initialState = data;
          setState(data);
        });
      };

      return Object.assign(new Swiss(setStateSync, getState), { _initialize });
    },
  ]);

  useEffect(value[1]._initialize, []);

  if (value[0] === null) return null;

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};
