import { EmployeeRoleListItem } from 'app/employees/roles/EmployeeRoleListItem';
import { useState } from 'react';
import { BlankDynInput } from 'packs/single/blank-dyn-input';
import { voidMemo } from 'fnd/bridge/react';
import { useEmployeeRolePageStore } from 'app/employees/roles/EmployeeRolesPage';
import { css } from '@emotion/css';

export function EmployeeRoleList() {
  const [{ roles, active }, { updRole, addRole, setActive }] = useEmployeeRolePageStore();

  return (
    <div className="std-paper">
      {roles.map(role => (
        <EmployeeRoleListItem
          key={role.id}
          role={role}
          active={active === role.id}
          update={updRole}
          setActive={setActive}
        />
      ))}
      <AddRole addRole={addRole}/>
    </div>
  );
}


type AddRoleProps = {
  addRole
}

const AddRole = voidMemo(
  function AddRole({
    addRole,
  }: AddRoleProps) {
    const [active, setActive] = useState<boolean>(false);
    return (
      <div>
        {active ? (
          <div onClick={() => setActive(true)}>+</div>
        ) : (
          <BlankDynInput
            className={addInputClass}
            placeholder={'new role'}
            onUpdate={(name) => {
              addRole(name);
              setActive(false);
            }}
            onCancel={() => {
              setActive(false);
            }}
          />
        )}
      </div>
    );
  },
);

const addInputClass = css`
  padding: 12px;  
`;