import { PlayerRequirementField } from 'domain/player/requirements/player-requirements-block';
import { SkinCostCribField } from 'domain/skin/field';
import { CribSimpleForm, CribSubmitButton } from 'packs/crib';
import { CribBoolButtonField } from 'packs/crib/fields/crib-bool-button';
import { CribCronField } from 'packs/crib/fields/cron';
import { apiReq } from 'packs/libs/api';
import { StdFieldBox } from 'packs/std';
import { useState } from 'react';
import { useLaunch } from 'support/react/use-launch';

type GiveawayModProps = {
  initialData: any;
  submit: (data: any) => void;
};

const GiveawayTemplateFrom = ({ initialData, submit }: GiveawayModProps): JSX.Element => {
  return (
    <CribSimpleForm submit={submit} initialData={initialData}>
      <div className="flex flex-col gap-4s std-paper p4sa w200s">
        <StdFieldBox title="Cron Rule">
          <CribCronField name="cron" />
        </StdFieldBox>
        {/* <StdFieldBox title="Duration">
          <CribDurationField name="duration" required />
        </StdFieldBox> */}
        <StdFieldBox title="Prize">
          <SkinCostCribField name="prize" required />
        </StdFieldBox>
        <PlayerRequirementField name="requirements" />
        <CribSubmitButton />
      </div>
    </CribSimpleForm>
  );
};

export const GiveawayTemplateAdd = (): JSX.Element => {
  return (
    <GiveawayTemplateFrom
      initialData={{}}
      submit={async (data) => {
        apiReq({
          action: 'giveaway.template.add',
          success: true,
          body: data,
        });
      }}
    />
  );
};

export const GiveawayTemplateEdit = ({ id }): JSX.Element => {
  const [state, setState] = useState(null);

  useLaunch(async () => {
    setState(
      await apiReq({
        action: 'giveaway.template.get',
        query: { id },
      })
    );
  }, []);

  if (state === null) return null;

  return (
    <GiveawayTemplateFrom
      initialData={state}
      submit={async (data) => {
        apiReq({
          action: 'giveaway.template.upd',
          success: true,
          body: data,
        });
      }}
    />
  );
};
