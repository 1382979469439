import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { ReactNode } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

type FullCalendarEvent = {
  title: ReactNode;
  start: Date;
  end: Date;
};

type FullCalendarProps = {
  events: FullCalendarEvent[];
};

export const FullCalendar = (props: FullCalendarProps): JsxElement => {
  return (
    <div className="h-full">
      <Calendar localizer={localizer} events={props.events} />
    </div>
  );
};
