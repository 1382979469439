import { WithdrawalSearchBlock } from 'app/withdrawal/search/block';

type PlayerDetailsWithdrawalsProps = {
  playerId: string;
};

export const PlayerDetailsWithdrawals = ({
  playerId,
}: PlayerDetailsWithdrawalsProps): JSX.Element => {
  return (
    <div className="flex">
      <WithdrawalSearchBlock playerId={playerId} />
    </div>
  );
};
