import { css } from '@emotion/css';
import { usePlayerAmplitudeStatsConfig } from 'app/player/details/summary/amplitude/config';
import { appSyringe } from 'app/syringe';
import { infException } from 'fnd/inf/Inf';
import { YamlEditor, YamlEditorOperator } from 'packs/single/yaml-editor';
import { useSingleton } from 'packs/state/use-singleton';
import { StdDialog, StdKindButton } from 'packs/std';
import { createRefEffect } from 'support/react/ref-effect';

export const openPlayerAmplitudeSettingsDialog = () => {
  appSyringe(function PlayerAmplitudeConfigDialog({ onClose }) {
    const [data, op] = usePlayerAmplitudeStatsConfig();

    const [submit, ref] = useSingleton(() => {
      let editor: YamlEditorOperator;
      const submit = () => {
        try {
          op.setState(editor.getData());
          onClose();
        } catch (e) {
          infException(e);
        }
      };
      const ref = createRefEffect((ref: YamlEditorOperator) => {
        editor = ref;
        editor.setData(data);
        editor.focus();
      });
      return [submit, ref] as const;
    });

    return (
      <StdDialog onClose={onClose} className="flex flex-col gap-2s p4sa">
        <StdKindButton children="Save" onClick={submit} />
        <div className={editorBoxClass}>
          <YamlEditor editorRef={ref} className="h-min-100s" />
        </div>
      </StdDialog>
    );
  });
};

const editorBoxClass = css`
  width: 600px;
  max-height: 80vh;
  overflow: auto;
`;
