import { useSuss } from 'packs/libs/Suss';
import React from 'react';
import { StdTabSwitch, StdTabType } from 'packs/std';

type QuartzTabFilterProps = {
  name: string;
  options: [any, React.ReactNode][];
  className?: string;
  type?: StdTabType;
};

export function QuartzTabFilter({ name, options, className, type }: QuartzTabFilterProps) {
  const sussOp = useSuss();
  const value = sussOp[0].query[name];

  return (
    <StdTabSwitch
      type={type}
      className={className}
      value={value}
      onChange={(value) => {
        if (value === undefined) {
          sussOp[1].mutQuery((draft) => {
            delete draft[name];
          });
        } else {
          sussOp[1].updQuery({
            [name]: value,
          });
        }
      }}
      tabs={options}
    />
  );
}
