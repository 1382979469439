import { css, cx } from '@emotion/css';
import { formatDuration, intervalToDuration } from 'date-fns';
import { juxProps } from 'fnd/juxtapose';
import { QuartzFooter } from 'packs/blocks/quartz';
import { Stable, StableSortHead } from 'packs/blocks/suss-table';
import { queUpd, useQue } from 'packs/libs/Que';
import { SussProvider, useSuss } from 'packs/libs/Suss';
import { apiReq } from 'packs/libs/api';
import { Dump } from 'packs/single/Dump';
import { StdTime } from 'packs/std/time/format';
import { createElement, memo } from 'react';
import { useSwissState } from 'support/react/swiss';
import { useLaunch } from 'support/react/use-launch';

const request = (query, context) => {
  return apiReq({
    action: 'contest.history.search',
    progress: true,
    body: { ...query, ...context },
  });
};

export const ContestHistorySearchPage = (): JsxElement => {
  const query: Rsa = { sort: [['start', 'desc']] };

  return (
    <div className="flex flex-col gap-4s w200s">
      <div className="flex flex-col gap-4s">
        <SussProvider query={query} request={request}>
          <div className="flex gap-4s">
            <Stable body={<List />}>
              <th>Id</th>
              <th>Title</th>
              <StableSortHead name="start">Started</StableSortHead>
              <th>Duration</th>
              <th>Joined</th>
              <th>Fund</th>
            </Stable>
          </div>
          <div className="flex flex-col gap-4s">
            <QuartzFooter />
          </div>
        </SussProvider>
      </div>
    </div>
  );
};

const List = (): JsxElement => {
  const [{ data, stash }, suss] = useSuss();
  const { contestId } = useQue();
  const selected = stash.selected;

  return (
    <tbody>
      {data.mapEntries((entry) =>
        createElement(Row, {
          key: entry[0],
          data: entry[1],
          suss,
          selected: contestId === entry[0],
        })
      )}
    </tbody>
  );
};

type RowProps = Rsa;

const Row = memo(function Row({ data, selected }: RowProps): JsxElement {
  return (
    <tr
      onClick={() => queUpd({ contestId: data.id })}
      className={cx('cursor-pointer', selected && selectedRowClass)}
    >
      <td>{data.id}</td>
      <td>{data.title.en}</td>
      <td>{StdTime.ms.datetime(data.start)}</td>
      <td>{ms_to_duration(data.duration)}</td>
      <td>{data.joined}</td>
      <td>{data.prize.reduce((acc, v) => acc + v, 0)}$</td>
    </tr>
  );
}, juxProps(['data', 'selected']));

const selectedRowClass = css`
  background: #ccccff20;
`;

export const InsightBlock = (): JsxElement => {
  const [suss] = useSuss();
  const [state, op] = useSwissState(null);

  useLaunch(() => {
    const contestId = suss.stash.selected;
    if (contestId === undefined) return;
  }, []);

  if (state === null) return null;

  const { ...data } = state;

  return (
    <div className="std-paper p4sa">
      <Dump data={data} />
    </div>
  );
};

const ms_to_duration = (ms: number): string => {
  if (!ms) return;
  return formatDuration(intervalToDuration({ start: 0, end: ms }), {
    format: ['years', 'months', 'days', 'hours', 'minutes', 'seconds'],
  });
};
