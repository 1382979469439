import { css, cx } from '@emotion/css';
import { juxPath } from 'fnd/juxtapose';
import { apiReq } from 'packs/libs/api';
import { memo, useEffect } from 'react';
import { useBasicSwissStore } from 'support/react/swiss';
import { launch } from 'support/react/use-launch';
import COUNTRIES from './countries.yml';

type PaymentCountryRegionBoundBlockProps = {
  regionId: number;
};

type State = {
  list: any[];
};

export const PaymentCountryRegionBoundBlock = ({
  regionId,
}: PaymentCountryRegionBoundBlockProps): JSX.Element => {
  const [state, op] = useBasicSwissStore(() => [
    null as State,
    ({ setState, asyncMutState }) => {
      const effect = () => {
        launch(async () => {
          const regionCountries = await apiReq({
            action: 'payment.region.country.list',
                        body: regionId,
          });

          const selected = new Set(regionCountries);
          const list = COUNTRIES.map(([code, name]) => {
            return { code, name, selected: selected.has(code) };
          });

          setState({ list });
        });
      };

      const toggle = (countryCode) => {
        asyncMutState(async (draft) => {
          const country = draft.list.find((country) => country.code === countryCode);
          const selected = !country.selected;
          const action = selected ? 'add' : 'del';
          await apiReq({
            action: 'payment.region.country.' + action,
            body: { regionId, countryCode },
          });

          country.selected = selected;
        });
      };

      return { effect, toggle };
    },
  ]);

  useEffect(op.effect, []);

  const composeContent = () => {
    if (state === null) return 'loading...';

    const { list } = state;

    return (
      <div className="flex flex-wrap p4sa gap-1s">
        {list.map((data) => {
          return <Item key={data.code} data={data} toggle={op.toggle} />;
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col flex-gap gap-4s">
      <div className="tg-title">Region Countries</div>
      <div className="std-paper p4sa w140s">{composeContent()}</div>
    </div>
  );
};

type ItemProps = {
  data: any;
  toggle(countryCode: string): void;
};

const Item = memo(function Item({ data, toggle }: ItemProps): JSX.Element {
  const { code, name, selected } = data;
  return (
    <div
      title={name}
      className={cx(itemClass, selected && selectedItemClass)}
      onClick={() => {
        toggle(code);
      }}
    >
      <div className={itemCodeClass}>{code}</div>
      <div className={itemNameClass}>{name}</div>
    </div>
  );
}, juxPath(['data', 'selected']));

const itemClass = css`
  width: 120px;
  display: flex;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 4px;
  gap: 4px;
  cursor: pointer;
`;

const selectedItemClass = css`
  background: #002fff23;
`;

const itemCodeClass = css`
  font-weight: 700;
`;

const itemNameClass = css`
  // collapse
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
