import { GiveawayTemplateDetails } from 'app/giveaway/template/details';
import { GiveawayTemplateAdd } from 'app/giveaway/template/form';
import { GiveawayTemplateSearchPage } from 'app/giveaway/template/search/page';
import { StdTabJunction } from 'packs/std';

export const GiveawayJunction = (): JSX.Element => {
  return (
    <StdTabJunction
      routes={[
        {path:'templates', elem: <GiveawayTemplateSearchPage />, exact: true},
        { path: 'templates/add', elem: <GiveawayTemplateAdd />, hidden: true },
        { path: 'templates/:id', elem: <GiveawayTemplateDetails />, hidden: true },
      ]}
    />
  );
};
