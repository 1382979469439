import {css} from '@emotion/css';

function compile() {
  const result: any[] = [css`
    .grid {
      display: grid;
    }
  `];

  for (let i = 1; i <= 12; i++) {
    result.push(css`
      .grid-cols-${i} {
        grid-template-columns: repeat(${i}, minmax(0, 1fr));
      }
    `);
  }

  const SPACE = 4;
  for (let i = 1; i <= 8; i++) {
    const val = SPACE * i;
    result.push(css`
      .gap-${i}s {
        gap: ${val}px;
      }

      .gap-x-${i}s {
        column-gap: ${val}px;
      }

      .gap-y-${i}s {
        row-gap: ${val}px;
      }
    `);
  }

  return result.flat();
}

export default compile();
