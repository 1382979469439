import { GiveawayTemplateEdit } from 'app/giveaway/template/form';
import { chronicle } from 'fnd/bridge/router';
import { apiReq } from 'packs/libs/api';
import { fancyConfirmation } from 'packs/single/fancy-confirmation';
import { StdKindButton } from 'packs/std';
import { useParams } from 'react-router-dom';

export const GiveawayTemplateDetails = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  return (
    <div className="flex flex-col gap-4s">
      <div>
        <StdKindButton
          kind="eh"
          children="Delete"
          onClick={() => {
            fancyConfirmation({
              about: 'This will delete the template and a giveaway created from it',
              onOk: async () => {
                await apiReq({
                  action: 'giveaway.template.del',
                  success: true,
                  body: id,
                });
                chronicle.replace('/giveaway/templates');
              },
            });
          }}
        />
      </div>
      <GiveawayTemplateEdit id={id} />
    </div>
  );
};
