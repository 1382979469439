import { cx } from '@emotion/css';
import { CheckBox } from 'packs/iconic/CheckBox';

type StdCheckboxProps = {
  checked?: boolean;
  className?: string;
  onChange(checked: boolean): void;
};

export const StdCheckbox = ({ checked, className, onChange }: StdCheckboxProps): JSX.Element => {
  return (
    <CheckBox
      className={cx(className, onChange && 'cursor-pointer')}
      checked={checked}
      onClick={() => {
        onChange?.(!checked);
      }}
    />
  );
};
