import { CaseData } from 'domain/case';
import { CaseImage } from 'domain/case/image';
import { QuartzFilterZone, QuartzFooter } from 'packs/blocks/quartz';
import { Stable } from 'packs/blocks/suss-table';
import { apiReq } from 'packs/libs/api';
import { SussProvider } from 'packs/libs/Suss';
import { CopyIcon } from 'packs/single/copy-icon';
import { StdLink } from 'packs/std';
import { memo } from 'react';

const request = (query, context) =>
  apiReq({ action: 'case.search', body: { ...query, ...context } });

export const CaseSearchPage = (): JSX.Element => {
  return (
    <div className="w200s flex flex-col gap-4s">
      <SussProvider request={request}>
        <QuartzFilterZone qInput="id or name" />
        <Stable Row={Item}>
          <th></th>
          <th>id</th>
          <th>name</th>
          <th>price</th>
          <th>slots</th>
        </Stable>
        <QuartzFooter />
      </SussProvider>
    </div>
  );
};

type ItemProps = {
  data: CaseData;
};

const Item = memo(function Item({ data }: ItemProps): JSX.Element {
  const { id, name, config, price } = data;
  return (
    <tr>
      <td className="relative p-0 w13s">
        <CaseImage className="absolute h13s p1sa" code={id} />
      </td>
      <td>
        <CopyIcon copy={id} />
        <StdLink to={`/cases/${id}`}>{id}</StdLink>
      </td>
      <td>{name}</td>
      <td>${price}</td>
      <td>{config.slots.length}</td>
    </tr>
  );
});