import { el, makeNullContext } from 'fnd/bridge/react';
import { useUniStore } from 'packs/state/UniStore';
import ActorOperator from 'domain/actor/actor.operator';
import { kvRepo } from 'fnd/db';
import { chronicle } from 'fnd/bridge/router';
import { infError } from 'fnd/inf/Inf';
import { useContext, useEffect } from 'react';
import { oauthOnAuthed } from 'app/public/oauth';

const Context = makeNullContext('Actor');
export const useActor = () => useContext<ActorOperator>(Context as any);
let globalOperator: ActorOperator;
export const getActor = () => globalOperator;
window['getActor'] = getActor;
type State = {
  resolved: boolean;
  jwt?: string;
};

export function ActorProvider({ children }: Rcp) {
  const [init, value] = useUniStore<State, [() => void, ActorOperator]>((set, get) => {
    const [kvFind, kvSet, kvDel] = kvRepo<string>('auth');
    const setJwt = (jwt: string) => set({ ...get(), jwt });

    function init() {
      (async () => {
        const jwt = await kvFind();
        set({ jwt, resolved: true });
      })();
    }

    function logout() {
      (async () => {
        await kvDel();
        setJwt(undefined);
        chronicle.push('/');
      })();
    }

    const actions = {
      kickOut: () => {
        logout();
        infError('unauthorized');
      },
      async login(jwt: any) {
        setJwt(jwt);
        await kvSet(jwt);
        await oauthOnAuthed();
        chronicle.replace('/');
      },
      logout,
    };

    return [
      { resolved: false },
      ({ resolved, jwt }) => [
        init,
        resolved &&
          (globalOperator = Object.assign(new ActorOperator(), {
            jwt,
            ...actions,
          })),
      ],
    ];
  });

  useEffect(init, []);

  if (!value) return null;

  return el(Context.Provider, { children, value });
}
