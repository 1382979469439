import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";
import styled from "styled-components";

const StyledToastContainer = styled(ToastContainer)`
  min-width: 320px;
  width: auto;

  .Toastify__toast {
    border-radius: 3px;
    min-height: unset;
    padding: 0;

    .Toastify__progress-bar {
      height: 2px;
    }

    .Toastify__close-button {
      display: none;
    }
  }
`;

export default StyledToastContainer;
