import { CONTEST_APPEARANCE_LEAD_UP, CONTEST_EXPOSITION_LEAD_UP } from 'app/contest/definitions';
import { appSyringe } from 'app/syringe';
import { formatDuration } from 'date-fns';
import { ContestData } from 'domain/contest/definitions';
import { shotSuccessToast } from 'fnd/inf/Inf';
import { apiReq } from 'packs/libs/api';
import { CopyIcon } from 'packs/single/copy-icon';
import { fancyConfirmation } from 'packs/single/fancy-confirmation';
import { FullCalendar } from 'packs/single/full-calendar';
import { SimpleAttrTable } from 'packs/single/simple-attr-table';
import { StdKindButton, StdLink, StdOuterLink } from 'packs/std';
import { StdPopover } from 'packs/std/layouts/popover';
import { StdTime } from 'packs/std/time/format';
import { ReactNode, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Link } from 'react-router-dom';
import { useLaunch } from 'support/react/use-launch';

export const ContestCurrentCalendarPage = (): JsxElement => {
  const [state, setState] = useState();

  useLaunch(async () => {
    const data = await apiReq({
      action: 'contest.current.list',
    });

    setState(
      data.map((contest) => {
        return {
          title: <Card contest={contest} />,
          start: new Date(contest.start),
          end: new Date(contest.start + contest.duration),
        };
      })
    );
  }, []);

  return (
    <div className="flex flex-col gap-4s">
      <div className="flex gap-4s">
        <Link to="/contests/add">
          <StdKindButton size="lg" children="new contest" />
        </Link>
        {ENV_SANDBOX && (
          <StdKindButton
            kind="eh"
            children="cancel all"
            onClick={async () => {
              const contests = await apiReq({ action: 'contest.current.list' });
              for (const contest of contests) {
                await apiReq({
                  action: 'contest.cancel',
                  body: contest.id,
                  progress: true,
                  success: true,
                });
              }
              window.location.reload();
            }}
          />
        )}
        <div className="tg-cation1">
          <div>exposition lead-up: {formatDuration(CONTEST_EXPOSITION_LEAD_UP)}</div>
          <div>appearance lead-up: {formatDuration(CONTEST_APPEARANCE_LEAD_UP)}</div>
        </div>
      </div>
      <div className="std-paper p4sa h200s">
        <FullCalendar events={state} />
      </div>
    </div>
  );
};

type CardProps = {
  contest: ContestData;
};

const Card = ({ contest }: CardProps): JsxElement => {
  return <div onClick={(e) => openDetailsDialog(contest, e)}>{contest.title.en}</div>;
};

const openDetailsDialog = (contest: ContestData, e) => {
  appSyringe(function ContestDetailsDialog({ onClose }) {
    const attrs: [string, ReactNode][] = [];

    attrs.push(['id', contest.id]);
    if (contest.templateId) {
      attrs.push([
        'template',
        <StdLink
          to={`/contests/templates/${contest.templateId}`}
          children={contest.templateId}
          onClick={onClose}
        />,
      ]);
    }

    attrs.push(['status', contest.status]);
    attrs.push(['start', StdTime.ms.datetime(contest.start)]);
    attrs.push(['duration', StdTime.ms.duration(contest.duration)]);

    return (
      <StdPopover
        open
        onClose={onClose}
        anchorEl={e.target}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="std-paper p4sa flex flex-col gap-2s w80s overflow-hidden">
          <div className="tg-title">{contest.title.en}</div>
          <div>
            <PwaLink path={`contests/${contest.id}`} children={'site link'} copy />
            {contest.code && (
              <PwaLink path={`contests/b/${contest.code}`} children={'blogger invite link'} copy />
            )}
          </div>
          <SimpleAttrTable className="w-full" data={attrs} />
          <StdKindButton
            children="cancel"
            kind="eh"
            onClick={() => {
              fancyConfirmation({
                onOk: async () => {
                  await apiReq({ action: 'contest.cancel', body: contest.id });
                  shotSuccessToast('Запрос на отмену турнира отправлен');
                },
              });
            }}
          />
        </div>
      </StdPopover>
    );
  });
};

type PwaLinkProps = {
  path: string;
  children?: ReactNode;
  copy?: boolean;
};

const PwaLink = ({ path, copy, children }: PwaLinkProps): JsxElement => {
  const link = `${ENV_PWA_URL}/${path}`;
  return (
    <div className="flex gap-2s items-center">
      <StdOuterLink className="truncate" href={link}>
        {children ?? link}
      </StdOuterLink>
      {copy && <CopyIcon copy={link} />}
    </div>
  );
};
