import { chronicle } from 'fnd/bridge/router';
import { QuartzFooter } from 'packs/blocks/quartz';
import { Stable } from 'packs/blocks/suss-table';
import { apiReq } from 'packs/libs/api';
import { SussProvider } from 'packs/libs/Suss';
import { StdKindButton } from 'packs/std';
import { Link } from 'react-router-dom';

const request = (query, context) =>
  apiReq({
    action: 'payment.region.search',
    body: { ...query, ...context },
        progress: true,
  });

export const PaymentRegionSearchPage = (): JSX.Element => {
  return (
    <div className="flex flex-col gap-4s w100s">
      <Link to="region/add">
        <StdKindButton children="Add Region" />
      </Link>
      <SussProvider size={5} request={request}>
        <Stable Row={Row}>
          <th>name</th>
          <th>enabled</th>
          <th>currency</th>
        </Stable>
        <QuartzFooter />
      </SussProvider>
    </div>
  );
};

type RowProps = {
  data;
};

const Row = ({ data }: RowProps): JSX.Element => {
  return (
    <tr
      className="cursor-pointer"
      onClick={() => {
        chronicle.push(`/payment/region/${data.id}`);
      }}
    >
      <td>{data.name.en}</td>
      <td>{data.enabled ? 'enabled' : 'disabled'}</td>
      <td>{data.currency}</td>
    </tr>
  );
};
