import { css } from '@emotion/css';
import { toString } from 'cronstrue';
import { sub } from 'date-fns';
import { useCribField } from 'packs/crib/store';
import { CribErrorBox } from 'packs/crib/units/error-box';
import { ExpandIcon } from 'packs/iconic/expand';
import { StdKindButton } from 'packs/std';
import { useState } from 'react';
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import { CronRule } from 'support/etc/cron-rule';
import { stashGet } from 'support/memory/stash';
import { RefinementCtx, ZodIssueCode, ZodTypeAny, z } from 'zod';

type CronRefinery = (val: CronRule, ctx: RefinementCtx) => void;

class CronSchemaBuilder {
  private schema: ZodTypeAny = z.string().min(1);

  optional(): CronSchemaBuilder {
    this.schema = this.schema.optional();
    return this;
  }

  build(): z.ZodType<string> {
    return this.schema;
  }

  periodGte(duration: Duration) {
    return this.addCronRefine((val, ctx) => {
      const [a, b] = val.iterate();
      if (sub(b, duration) < a)
        ctx.addIssue({ code: ZodIssueCode.custom, message: 'period too short' });
    });
  }

  @stashGet
  private get addCronRefine() {
    const refineries = [];

    this.schema = this.schema.superRefine((val, ctx) => {
      if (val === undefined) return;
      const rule = new CronRule(val);
      for (const refinery of refineries) {
        refinery(rule, ctx);
      }
    });

    return (cb: CronRefinery) => {
      refineries.push(cb);
    };
  }
}

type CribCronFieldProps = {
  name: string;
  schema?: (builder: CronSchemaBuilder) => CronSchemaBuilder | void;
};

export function CribCronField(props: CribCronFieldProps): JSX.Element {
  const [{ value, error }, op] = useCribField(() => {
    const schema = new CronSchemaBuilder();
    props.schema?.(schema);
    return { name: props.name, schema: schema.build() };
  });

  const [expanded, setExpanded] = useState(() => value === undefined);

  return (
    <div>
      <div className="flex gap-2s aic p2sy">
        <ExpandIcon value={expanded} onChange={setExpanded} />
        <div className={valueClass}>{value ? toString(value) : 'NOT SET'}</div>
      </div>
      {expanded && <CronBox value={value} onChange={op.setValue} />}

      <CribErrorBox error={error} />
    </div>
  );
}

type CronBoxProps = {
  value: string;
  onChange(value: string): void;
};

const CronBox = (props: CronBoxProps): JSX.Element => {
  const [value, setValue] = useState(() => props.value ?? '* * * * *');

  return (
    <div className={boxClass}>
      <Cron value={value} setValue={setValue} />
      <div className="flex gap-2s">
        <StdKindButton children="apply" onClick={() => props.onChange(value)} />
        <StdKindButton kind="ah" children="cancel" onClick={() => setValue(props.value)} />
      </div>
    </div>
  );
};

const valueClass = css`
  color: var(--c-blue);
`;

const boxClass = css`
  padding: 16px;
  background: var(--grey-900);
  border: 1px solid var(--grey-600);
`;
