import Popover from '@material-ui/core/Popover';
import { appSyringe } from 'app/syringe';
import { juxProp } from 'fnd/juxtapose';
import { memo } from 'react';
import styled from 'styled-components';

const DEFAULT_SIZES = [10, 20, 50, 100, 200];

type QuartzSizerProps = {
  value: number;
  onChange(value: number): void;
  options?: number[];
};
export const QuartzPageSizer = memo(function StableSizer({
  value,
  onChange,
  options = DEFAULT_SIZES,
}: QuartzSizerProps) {
  return (
    <div
      className="flex items-center cursor-pointer"
      onClick={(e) => {
        appSyringe(function PageSizeSelect({ onClose }) {
          return (
            <Popover open anchorEl={e.target as any} onClose={onClose}>
              {options.map((item) => {
                return (
                  <ItemDiv
                    key={item}
                    onClick={() => {
                      onChange(item);
                      onClose();
                    }}
                  >
                    {item}
                  </ItemDiv>
                );
              })}
            </Popover>
          );
        });
      }}
    >
      <div className="tg-caption p2sr">Page size:</div>
      <div className={'flex items-center cursor-pointer'}>
        <div className="tg-caption p1sr">{value}</div>
        <div>{arrow}</div>
      </div>
    </div>
  );
},
juxProp('value'));

const arrow = (
  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 5.5L0 0.5H8L4 5.5Z" fill="#64748B" />
  </svg>
);

const ItemDiv = styled.div`
  padding: 8px;
  cursor: pointer;
  :hover {
    background: var(--grey-900);
  }
`;
