import { useUniStore } from 'packs/state/UniStore';
import { makeAclChecker } from 'domain/actor/AclChecker';
import { memo } from 'react';
import { juxProps } from 'fnd/juxtapose';
import { StdKindButton } from 'packs/std';
import { useEmployeeRolePageStore } from 'app/employees/roles/EmployeeRolesPage';
import { CheckBox } from 'packs/iconic/CheckBox';
import { css, cx } from '@emotion/css';
import { apiReq } from 'packs/libs/api';
import { fancyConfirmation } from 'packs/single/fancy-confirmation';

type ItemData = {
  code: string,
  selected: boolean,
  auto: boolean
  onClick
}

export function EmployeeRoleAclEdit() {
  const [{ roles, active, acl }, op] = useEmployeeRolePageStore();

  const [list, submit] = useUniStore<ItemData[], [ItemData[], () => void]>((setState) => {
    const role = roles.get(active);

    const selections = new Set<string>(role.acl);

    function getList() {
      const check = makeAclChecker(selections);
      return acl.map(code => {
        const selected = selections.has(code);
        return {
          code, selected,
          auto: selected || check(code),
          onClick: select,
        };
      });
    }

    function select(access: string, select: boolean) {
      select ? selections.add(access) : selections.delete(access);
      setState(getList());
    }

    function submit() {
      op.updRole(role.id, { acl: [...selections] });
    }

    return [getList(), s => [s, submit]];
  });

  if (!active) return null;

  return (
    <div className="std-paper p4sa flex flex-col gap-4s">
      <div className="tg-caption">{active}</div>
      <div className={gridClass}>
        {list.map(props => (
          <Item
            key={props.code}
            {...props}
          />
        ))}
      </div>
      <div className="flex justify-between">
        <StdKindButton
          children={'Submit'}
          onClick={submit}
        />
        <StdKindButton
          children={'Delete'}
          kind="danger-h"
          onClick={() => {
            fancyConfirmation({
              async onOk() {
                await apiReq({
                  action: 'employee.role.del',
                  body: { id: active },
                  success: true,
                });
                op.iupState({
                  roles: roles => roles.del(active),
                  active: { $set: op.getState().roles[0]?.id },
                });
              },
            });
          }}
        />
      </div>
    </div>
  );
}

type ItemProps = ItemData & {
  onClick(access: string, select: boolean): void
}
const Item = memo(function Item({
  code, selected, auto, onClick,
}: ItemProps) {
  return (
    <div
      className={cx(itemClass, auto && activeClass)}
      onClick={() => {
        onClick(code, !selected);
      }}
    >
      <CheckBox checked={selected}/>
      {code}
    </div>
  );
}, juxProps(['selected', 'auto']));

const gridClass = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 4px;
`;

const itemClass = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;

  &:hover {
    background: rgba(0, 0, 0, .05);
  }
`;

const activeClass = css`
  background: rgba(0, 0, 0, .1);
`;
