import { ReactNode, createContext, useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

// type Route = {
//   elem: ReactNode;
//   path: string;
// } | [string, ReactNode];
type RouteOpts = [string, ReactNode, boolean?];

const Context = createContext('/');
if (__DEV__) Context.displayName = 'JunctionContext';

type StdSwitchProps = {
  routes: RouteOpts[];
};

const prefix = (pathOrUrl: string) => (pathOrUrl === '/' ? pathOrUrl : `${pathOrUrl}/`);

export const StdJunction = ({ routes }: StdSwitchProps): JSX.Element => {
  const parent = useRouteMatch();

  return useMemo(() => {
    return (
      <Switch>
        {routes.map(([path, elem, exact]) => {
          return (
            <Route key={path} exact={exact} path={prefix(parent.path) + path}>
              {elem}
            </Route>
          );
        })}
        <Route exact>
          <Redirect to={prefix(parent.url) + routes[0][0]} />
        </Route>
      </Switch>
    );
  }, [parent.url, routes]);

  // return useMemo(() => {
  //   const compose = (route: Route) => (
  //     <Context.Provider value={`${parent}${route[0]}/`}>{routes[1]}</Context.Provider>
  //   );
  //   console.log({ locationPath, parent, routes });
  //   for (const route of routes) {
  //     console.log(route[0], matchPath(locationPath, route[0]));
  //     if (matchPath(locationPath, route[0])) return compose(route);
  //   }
  //   return compose(routes[0]);
  // }, [locationPath, parent]);
};

type StdPrePathProps = {
  path: string;
  children: ReactNode;
};

export const StdPrePath = ({ path, children }: StdPrePathProps): JSX.Element => {
  const parent = useRouteMatch();
  return useMemo(() => {
    return (
      <Switch>
        <Route path={prefix(parent.path) + path}>{children}</Route>
        <Route>
          <Redirect to={prefix(parent.url) + path} />
        </Route>
      </Switch>
    );
  }, []);
};
