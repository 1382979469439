import { findIndex, update } from "ramda";

export default function findOneAndUpdate<T>(
  search: (a: T) => boolean,
  upd: (a: T) => T,
  target: T[]
): T[] {
  const index = findIndex(search, target);
  if (index === -1) throw "key not found";
  return update(index, upd(target[index]), target);
}
