import { useCribField } from 'packs/crib/store';
import { CribErrorBox } from 'packs/crib/units/error-box';
import { StdDateTimeInput } from 'packs/std/inputs/date';
import execIfFn from 'support/execIfFn';
import { ZodDate } from 'zod';

type CribDateTimeFieldProps = {
  name: string;
  schema?: OrFactory<ZodDate>;
};

export const CribDateTimeField = (props: CribDateTimeFieldProps): JSX.Element => {
  const [{ value, error }, op] = useCribField(() => {
    return { name: props.name, schema: execIfFn(props.schema) };
  });

  return (
    <div>
      <StdDateTimeInput value={value} onChange={op.setValue} />
      <CribErrorBox error={error} />
    </div>
  );
};
