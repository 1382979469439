import { QuestTemplate, QuestTemplateData } from 'app/quests/template/QuestTemplate';
import { AuditIcon } from 'domain/etc/audit/icon';
import { QuestChapterPeriod } from 'domain/quest/quest.defs';
import { IupSpec } from 'fnd/support/iup';
import iup from 'immutability-helper';
import { debounce } from 'packs/deps';
import { apiReq } from 'packs/libs/api';
import { StdNumberInput, StdPaper } from 'packs/std';
import { useEffect, useMemo, useState } from 'react';
import { useBasicStore } from 'support/react/basic-store';

type QuestConfig = {
  code: string;
  schema: Rsa;
};

type QuestChapterTemplateProps = {
  period: QuestChapterPeriod;
  quests?: QuestConfig[];
};

type ChapterTemplateData = {
  quests: Record<string, QuestTemplateData>;
  reward: number;
};

type Operator = {
  load(): void;
  reset(): void;
  setQuest(code: string, data: QuestTemplateData);
  upd(spec: IupSpec<ChapterTemplateData>): void;
};

export function QuestChapterTemplate({ period, quests: questsTpl }: QuestChapterTemplateProps) {
  const [data, { load, reset, setQuest, upd }] = useBasicStore<
    ChapterTemplateData | null,
    Operator
  >(() => [
    null,
    (set, get) => {
      async function load() {
        set(
          await apiReq({
            action: 'quest.template.chapter.get',
            query: { period },
            progress: true,
          })
        );
      }

      const upd = (spec: IupSpec<ChapterTemplateData>) => {
        // @ts-ignore
        const data = iup(get(), spec);
        set(data);
        apiReq({
          action: 'quest.template.chapter.set',
          body: { period, data },
        }).catch(load);
      };

      function reset() {}

      return {
        upd,
        load,
        reset,
        setQuest(code: string, data) {
          upd({
            quests: {
              $merge: { [code]: data },
            },
          });
        },
      };
    },
  ]);

  useEffect(load, []);

  if (!questsTpl || !data) return null;

  return (
    <div>
      {/*<StdButton*/}
      {/*  children={'reset'}*/}
      {/*  onClick={reset}*/}
      {/*/>*/}
      <div>
        <RewardBlock reward={data.reward} onChange={(reward) => upd({ $merge: { reward } })} />
        <AuditIcon stack={['quest', 'chapter', period]} />
      </div>
      <div className="flex flex-col gap-2s p4st">
        {questsTpl.map(({ code, schema }) => {
          return (
            <QuestTemplate
              key={code}
              code={code}
              schema={schema}
              template={data.quests[code]}
              update={setQuest}
            />
          );
        })}
      </div>
    </div>
  );
}

type RewardBlockProps = {
  reward: number;
  onChange(prize: number): void;
};

function RewardBlock({ reward, onChange }: RewardBlockProps) {
  const [value, setValue] = useState(reward);
  const update = useMemo(() => {
    const sync = debounce(onChange, 200);
    return (value) => {
      setValue(value);
      sync(value);
    };
  }, []);

  return (
    <StdPaper className={'p4sa w80s'}>
      <div className={'tg-subheader p2sb'}>Reward:</div>
      <StdNumberInput value={value} onChange={update} />
    </StdPaper>
  );
}
