import { useQue } from 'packs/libs/Que';
import { apiReq } from 'packs/libs/api';
import { Dump } from 'packs/single/Dump';
import { useState } from 'react';
import { useLaunch } from 'support/react/use-launch';

export const ContestHistoryInsightPage = (): JsxElement => {
  const { contestId } = useQue<{ contestId: string | undefined }>();
  const [data, setData] = useState(null);

  useLaunch(async () => {
    if (contestId === undefined) return;

    setData(
      await apiReq({
        action: 'contest.history.get',
        body: { id: contestId },
      })
    );
  }, [contestId]);

  if (data === null) return null;

  const { ...basic } = data;

  return (
    <div className="p4sa std-paper">
      <Dump data={basic} />
    </div>
  );
};
