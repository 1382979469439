import { QuartzFooter } from 'packs/blocks/quartz';
import { Stable, StableHead } from 'packs/blocks/suss-table';
import { queUpd, useQue } from 'packs/libs/Que';
import { SussProvider, useSuss } from 'packs/libs/Suss';
import { apiReq } from 'packs/libs/api';
import { Dump } from 'packs/single/Dump';
import { useMemo } from 'react';

export const LeaderHistoryPage = ({}: LeaderboardHistoryPageProps): JSX.Element => {
  return (
    <SussProvider request={request}>
      <div className="flex gap-4s">
        <Search />
        <Details />
      </div>
    </SussProvider>
  );
};

const request = (query, context) =>
  apiReq({
    action: 'leaderboard.history.search',
    body: { ...query, ...context },
  });

type LeaderboardHistoryPageProps = {};

const Search = ({}: LeaderboardHistoryPageProps): JSX.Element => {
  return (
    <div className="w200s">
      {/* <QuartzFilterZone qInput={'id'} /> */}
      <Stable Row={Item} className={'m2sy'}>
        <StableHead>start</StableHead>
        <StableHead>players</StableHead>
        <StableHead>total games</StableHead>
        <StableHead>total profit</StableHead>
        <StableHead></StableHead>
      </Stable>
      <QuartzFooter />
    </div>
  );
};

type ItemProps = {
  data: any;
};

const Item = ({ data }: ItemProps): JSX.Element => {
  return (
    <tr>
      <td>{data.start}</td>
      <td>{data.players}</td>
      <td>{data.totalGames}</td>
      <td>{data.totalProfit.toFixed(2)}</td>
      <td className="cursor-pointer" onClick={() => queUpd({ topId: data.id })}>
        go
      </td>
    </tr>
  );
};

const Details = (): JSX.Element => {
  const { topId } = useQue();
  const [state] = useSuss();
  return useMemo(() => {
    if (!topId) return null;
    const data = state.data.find(topId);
    if (data === undefined) return <>not found</>;
    const { winners, ...others } = data;
    return (
      <div>
        <Dump data={others} />
        <div className="tg-subhead">Winners</div>
        <Dump data={winners} />
      </div>
    );
  }, [topId]);
};
