import TextArea from 'react-textarea-autosize';
import React, { createElement, forwardRef } from 'react';
import { css, cx } from '@emotion/css';

export type StdAutoTextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const StdAutoTextArea = forwardRef<HTMLTextAreaElement, StdAutoTextAreaProps>(
  function StdAutoTextArea(props, ref) {
    // @ts-ignore
    return createElement(TextArea, {
      ...props,
      ref,
      cacheMeasurements: true,
      className: cx(mainClass, props.className),
    });
  }
);

const mainClass = css`
  background: transparent;
  font-size: 14px;
  border-radius: 5px;
  color: var(--dark-blue-500);
  outline: none;
  padding: 11px 15px 10px;
  width: 100%;
  display: block;
  border: 1px solid var(--c-grey);
  transition: all ease-in-out 0.2s;
  resize: none;
  overflow: hidden;

  &:focus {
    outline: none;
    border-color: var(--c-blue);
  }

  &.error {
    border-color: var(--c-red);
  }
`;
