import { Api } from 'packs/libs/api';

type Logger = {
  log(lvl: number, data: any): void;
};

class LoggerImpl implements Logger {
  log(lvl: number, data: any) {
    Api.fetch({
      action: 'log',
      method: 'post',
      body: JSON.stringify([lvl, data]),
    });
  }
}

export default new LoggerImpl();
