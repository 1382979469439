import { UpgradeConfigBlock } from 'app/upgrade/upgrade.config-block';
import { UpgradeSandboxConfig } from 'app/upgrade/upgrade.sandbox-config';
import { apiReq } from 'packs/libs/api';
import { fancyConfirmation } from 'packs/single/fancy-confirmation';
import { StdKindButton } from 'packs/std';

export const UpgradePage = () => {
  return (
    <div className="flex flex-col gap-4s">
      <StdKindButton
        kind="ah"
        className="w60s"
        children="Clear Live Bids"
        onClick={() =>
          fancyConfirmation({
            onOk: () => apiReq({ action: 'upgrade.clear_live_bids' }),
          })
        }
      />
      <UpgradeConfigBlock />
      {ENV_SANDBOX && <UpgradeSandboxConfig />}
    </div>
  );
};
