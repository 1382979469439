import { StdInput, StdInputProps } from 'packs/std/inputs/StdInput';
import { el } from 'fnd/bridge/react';
import { ChangeEvent, useEffect, useRef } from 'react';

type StdNumberInputProps = Omit<StdInputProps, 'type' | 'onChange'> & {
  onChange(value: number | undefined, e: ChangeEvent<HTMLInputElement>);
};

export function StdNumberInput({ value, ...props }: StdNumberInputProps) {
  const ref = useRef<HTMLInputElement>();
  const lastValue = useRef<string>();

  useEffect(() => {
    if (lastValue.current !== value) {
      ref.current.value = value === undefined ? '' : String(value);
    }
  }, [value]);

  return el(StdInput, {
    ...props,
    ref,
    onChange(e) {
      const val = (lastValue.current = e.target.value);
      const num = val.length && val !== '-' ? Number(val) : undefined;
      props.onChange?.(num, e);
    },
    onKeyPress(e) {
      if (isNaN(Number(e.key))) {
        if (e.key === '.') {
          if (lastValue.current.includes('.')) {
            e.preventDefault();
          }
        } else if (e.key === '-') {
          if (ref.current!.selectionStart !== 0) {
            e.preventDefault();
          }
          const first = ref.current!.value[0];
          if (first === '-') {
            e.preventDefault();
          }
        } else {
          e.preventDefault();
        }
      }
      props.onKeyPress?.(e);
    },
  });
}
