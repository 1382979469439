import { lensPath, view } from "ramda";

type Rsu = Record<string, unknown>;

type Comparator<T> = (prev: T, next: T) => boolean;
type UCmp = Comparator<unknown>;
type Path = (number | string)[];

export const juxEq = <T>(prev: T, next: T) => prev === next;
export const juxVoid: {
  <T>(prev: T, next: T): boolean
} = () => true;

export function juxProp(prop: string, cmp: UCmp = juxEq) {
  return function propertyComparator<T extends Rsu>(prev: T, next: T): boolean {
    return cmp(prev[prop], next[prop]);
  };
}

export function juxProps(props: string[], cmp: UCmp = juxEq) {
  return function propertiesComparator<T extends Rsu>(prev: T, next: T): boolean {
    for (const prop of props)
      if (!cmp(prev[prop], next[prop]))
        return false;
    return true;
  };
}

export function juxPath(path: Path, cmp: UCmp = juxEq) {
  const get = view(lensPath(path));

  return function pathComparator<T extends Rsu>(prev: T, next: T): boolean {
    return cmp(get(prev), get(next));
  };
}

export function juxOr<T>(...cmps: Comparator<T>[]) {
  return function orComparator(prev: T, next: T): boolean {
    for (const cmp of cmps) if (cmp(prev, next)) return true;
    return false;
  };
}

export function juxAnd<T>(...cmps: Comparator<T>[]) {
  return function andComparator(prev: T, next: T): boolean {
    for (const cmp of cmps) if (!cmp(prev, next)) return false;
    return true;
  };
}

// export function juxEntries(map: [string, UCmp][]) {
//   return juxAnd<Rsu>(map.map(([prop, cmp]) => juxProp(prop, cmp)));
// }

export function juxShallow(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);
  const len = aKeys.length;

  if (bKeys.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i++) {
    const key = aKeys[i];

    if (objA[key] !== objB[key] || !Object.prototype.hasOwnProperty.call(objB, key)) {
      return false;
    }
  }

  return true;
}
