import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import React, { createElement } from 'react';

type ExpandArrowIconProps = React.SVGProps<SVGElement> & {
  expanded?: boolean;
};

export function ExpandArrowIcon({ expanded, ...props }: ExpandArrowIconProps) {
  return createElement(expanded ? ExpandLessIcon : ExpandMoreIcon, props);
}
