import { EmployeeRoleAclEdit } from 'app/employees/roles/EmployeeRoleAclEdit';
import { EmployeeRoleList } from 'app/employees/roles/EmployeeRoleList';
import { EmployeeRole } from 'domain/employee/role/employee-role.defs';
import { makeNullContext, useJustEffect } from 'fnd/bridge/react';
import IMap from 'fnd/support/IMap';
import { IupSpec } from 'fnd/support/iup';
import { apiReq } from 'packs/libs/api';
import { useBasicSwissStore } from 'packs/state/swiss-store';
import { StdPage } from 'packs/std';
import { useContext } from 'react';

type State = {
  roles: IMap<string, EmployeeRole>;
  acl?: string[];
  active?: string;
};

type Operator = {
  getState(): State;
  iupState(state: IupSpec<State>): void;
  updRole(id: string, data: Partial<Omit<EmployeeRole, 'id'>>): void;
  addRole(title: string): void;
  setActive(id?: string): void;
};

const Context = makeNullContext<[State, Operator]>();

export const useEmployeeRolePageStore = () => useContext(Context);

export function EmployeeRolesPage() {
  const value = useBasicSwissStore<State, Operator>(() => [
    { roles: IMap.make() },
    ({ iupState, getState }) => {
      const updRole = async (id: string, data: Partial<Omit<EmployeeRole, 'id'>>) => {
        await apiReq({
          action: 'employee.role.upd',
          body: { id, ...data },
          success: true,
        });
        iupState({
          roles: (roles) => roles.upd(id, (prev) => ({ ...prev, ...data })),
        });
      };

      const addRole = async (name: string) => {
        const { id } = await apiReq({
          action: 'employee.role.add',
          body: { name, acl: [] },
          success: true,
        });
        iupState({
          // @ts-ignore
          roles: (roles) => roles.set(id, { id, name, acl: [] }),
          active: { $set: id },
        });
        setActive(id);
      };

      const setActive = (id?: string) => {
        iupState({ active: { $set: id } });
      };

      return {
        iupState,
        getState,
        updRole,
        addRole,
        setActive,
      };
    },
  ]);

  useJustEffect(async () => {
    const { acl, roles } = await apiReq({
      action: 'employee.role.all',
    });

    value[1].iupState({
      $set: {
        acl,
        roles: IMap.make(roles.map((role) => [role.id, role])),
      },
    });
  }, []);

  const { active } = value[0];

  return (
    <div className="flex items-start gap-6s">
      <Context.Provider value={value}>
        <EmployeeRoleList />
        {active && <EmployeeRoleAclEdit key={active} />}
      </Context.Provider>
    </div>
  );
}
