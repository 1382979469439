import { css, cx } from '@emotion/css';
import { juxProp } from 'fnd/juxtapose';
import { QuartzFooter } from 'packs/blocks/quartz';
import { ExpandIcon } from 'packs/iconic/expand';
import { SussProvider, useSuss } from 'packs/libs/Suss';
import { apiReq } from 'packs/libs/api';
import { Dump } from 'packs/single/Dump';
import { StdTime } from 'packs/std/time/format';
import { memo, useCallback, useMemo, useState } from 'react';

type Operation = {
  to_player: boolean;
  code?: string;
};

type SkinOperation = Operation & {
  type: 'skins';
  skins: { id: string; cost: number }[];
};

type BalanceOperation = Operation & {
  type: 'balance';
  amount: number;
};

export type InternalTransferOperationData = SkinOperation | BalanceOperation;

type InternalTransferData = {
  _id: string;
  playerId: string;
  code: string;
  at: string;
  payload?: Rsa;
  operations: InternalTransferOperationData[];
};

type PlayerInternalTransfersProps = {
  playerId: string;
};

export const PlayerTransfers = ({ playerId }: PlayerInternalTransfersProps): JSX.Element => {
  const request = useCallback(
    (query, context) => {
      return apiReq({
        action: 'transfer.search',
        body: { ...query, ...context, playerId },
      });
    },
    [playerId]
  );
  return (
    <SussProvider pk="_id" request={request} size={100}>
      <QuartzFooter />
      <List />
      <QuartzFooter />
    </SussProvider>
  );
};

const List = (): JSX.Element => {
  const [{ data }] = useSuss();
  return useMemo(() => {
    return (
      <div className="flex flex-col gap-2s">
        {data.listMapV((data) => {
          return <TransactionRow key={data._id} data={data} />;
        })}
      </div>
    );
  }, [data]);
};

type TransactionRowProps = {
  data: InternalTransferData;
};

const TransactionRow = memo(function TransactionRow({ data }: TransactionRowProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const { at, code, payload, operations } = data;
  // const composePayload = () => {
  //   if (!payload) return null;
  //   return <div>{dump(payload)}</div>;
  // };
  const [pos, neg, total] = calcTotal(data);

  return (
    <div className="std-paper p2sa gap-1s">
      <div>
        <div className="flex gap-4s">
          <div>
            <ExpandIcon className="cursor-pointer" value={open} onChange={setOpen} />
          </div>
          <div className="w45s">{StdTime.iso.datetime(at)}</div>
          <div title={code} className="w45s truncate">
            {code}
          </div>
          <div className="flex gap-2s">
            <span title={neg} className={negativeCashClass}>
              {neg}$
            </span>
            <span title={pos} className={positiveCashClass}>
              {pos}$
            </span>
            <span title={total} className={cashClass}>
              {total}$
            </span>
          </div>
        </div>
      </div>
      {open && (
        <div>
          {payload && (
            <div>
              <Dump data={payload} />
            </div>
          )}
          <div className="p2sl flex flex-col gap-1s">
            {operations.map((data, i) => {
              return <OperationRow key={i} data={data} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
}, juxProp('data'));

const type_total_map = new Map<any, any>([
  ['balance', (op: BalanceOperation) => op.amount],
  ['skins', (op: SkinOperation) => op.skins.reduce((prev, skin) => prev + skin.cost, 0)],
]);

const calcTotal = (data: InternalTransferData) => {
  let pos = 0;
  let neg = 0;
  let total = 0;
  for (const op of data.operations) {
    const add = type_total_map.get(op.type)!(op);
    if (op.to_player) {
      pos += add;
      total += add;
    } else {
      neg += add;
      total -= add;
    }
  }
  return [pos.toFixed(2), neg.toFixed(2), total.toFixed(2)];
};

type OperationRowProps = {
  data: InternalTransferOperationData;
};

const OperationRow = ({ data }: OperationRowProps): JSX.Element => {
  return (
    <div className={cx('p1sa', totalClass(data.to_player))}>
      {data.code && <div>{data.code}</div>}
      <div>{composeContent(data)}</div>
    </div>
  );
};

const composeContent = (data: InternalTransferOperationData) => {
  switch (data.type) {
    case 'balance':
      return <div>{data.amount.toFixed(2)}$</div>;
    case 'skins':
      return (
        <div className="flex flex-col gap-2s">
          {data.skins.map(({ id, cost }, i) => {
            return (
              <div key={i}>
                {id} ({cost}$)
              </div>
            );
          })}
        </div>
      );
    default:
      return 'UNKNOWN TYPE';
  }
};

const cashClass = css`
  padding: 0.2rem 0.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 8rem;
`;

const positiveClass = css`
  background-color: #00ff0030;
`;

const negativeClass = css`
  background-color: #ff000030;
`;

const positiveCashClass = css`
  ${cashClass}
  ${positiveClass}
`;

const negativeCashClass = css`
  ${cashClass}
  ${negativeClass}
`;

const totalClass = (total: number | boolean) =>
  // @ts-ignore
  total > 0 ? positiveClass : total === 0 ? cashClass : negativeClass;
