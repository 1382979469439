import { CaseBattleConfigPage } from 'app/cases/battle/config-page';
import { CaseBattleDetailsPage } from 'app/cases/battle/history/details/page';
import { CaseBattleHistorySearchPage } from 'app/cases/battle/history/search-page';
import { CaseDashboard } from 'app/cases/dashboard';
import { CaseDetails } from 'app/cases/details';
import { CaseAdd } from 'app/cases/form';
import { useActor } from 'domain/actor';
import { StdTabJunction } from 'packs/std';

export const CaseJunction = (): JSX.Element => {
  const actor = useActor();
  return (
    <StdTabJunction
      routes={[
        ['dashboard', <CaseDashboard />],
        { path: 'battles/config', title: 'Battle Config', elem: <CaseBattleConfigPage /> },
        { path: 'battles/history', title: 'Battle History', elem: <CaseBattleHistorySearchPage /> },
        {
          path: 'battles/:id',
          elem: <CaseBattleDetailsPage />,
          hidden: true,
          access: actor.can('case.entity.write'),
        },
        { path: 'add', elem: <CaseAdd />, hidden: true, access: actor.can('case.entity.write') },
        { path: ':id', elem: <CaseDetails />, hidden: true },
      ]}
    />
  );
};
